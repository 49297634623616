import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import {SUMMARY_REPORT_PRACTITIONER, ORDER_MAX_MIN_PRICE,SELECTABLE_PRACTITIONER} from "../../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            filterHtml: '',
            options: {
                name: this.$t('practitioner_summary_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberFormatter: (value) => {
                    return numberWithCurrencySymbol(value);
                },
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Practitioner'),
                        type: 'custom-html',
                        key: 'created_by',
                            modifier: (value) => {
                                return value.full_name;
                        }
                    },
                    {
                        title: this.$t('Total # Patients'),
                        type: 'custom-html',
                        key: 'all_customers_count',
                            modifier: (value) => {
                                return value;
                        }
                    },
                    {
                        title: this.$t('Total # Orders'),
                        type: 'custom-html',
                        key: 'order_count',
                            modifier: (value) => {
                                return value;
                        }
                    },
                    {
                        title: this.$t('Total Completed'),
                        type: 'custom-html',
                        key: 'order_completed_count',
                            modifier: (value) => {
                                return value;
                        }
                    },
                    {
                        title: this.$t('Order Value'),
                        type: 'object',
                        key: 'order_value',
                        accountAble: true,
                        modifier: (value) => {
                            return numberWithCurrencySymbol(value);
                        }
                    },
                    {
                        title: this.$t('Payment Received'),
                        type: 'object',
                        key: 'payment_received',
                        accountAble: true,
                        modifier: (value) => {
                            return numberWithCurrencySymbol(value);
                        }
                    },
                    {
                        title: this.$t('Payment Due'),
                        type: 'object',
                        key: 'payment_due',
                        accountAble: true,
                        modifier: (value) => {
                            return numberWithCurrencySymbol(value);
                        }
                    },
                    {
                        title: this.$t('Expand'),
                        type: 'expandable-column',
                        key: 'branch_or_warehouse',
                        className: 'link text-primary text-decoration-none p-0',
                        componentName: 'app-branch-report',
                        showTitle: 'Show',
                        hideTitle: 'Hide', 
                        showIcon: 'eye',
                        hideIcon: 'eye-off',
                        modifier: (value, row) => {
                            return {
                                expandable: true,
                                button: true,
                                visible: true,
                            }
                        }
                    },
                ],
                actionType: "dropdown",
                actions: [],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(SUMMARY_REPORT_PRACTITIONER, this.updateMinMaxPriceUrl);
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });

        this.$hub.$on('filter-change', (newFilterValues) => {
            let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
            this.filterHtml = '';
            if(filterData?.date?.start) {
                this.filterHtml = '<span class="d-block">Summary Report from '+formatDateToLocal(filterData?.date.start,false,null,0,'DD MMM YYYY')+' to '+formatDateToLocal(filterData?.date.end,false,null,0,'DD MMM YYYY')+'</span>';
            }
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(SUMMARY_REPORT_PRACTITIONER, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
    }
}