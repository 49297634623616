<template>
    <modal id="camp-modal" size="large" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('Custom Orthoses Customization')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOM_ORTHOSES_CUSTOMIZATION' @submit.prevent="submitData">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="row font-weight-bold">
                            <div class="col-md-4 mb-4"></div>
                            <div class="col-md-4 mb-4">Selection</div>
                            <div class="col-md-4 mb-4">Quantity</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="Base Material">Base Material :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.base_material.id">
                                    <option value="">Select</option>
                                    <option v-for="option in materialProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="text" id="quantity" class="form-control" v-model="formData.base_material.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Inner Material">Inner Material :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.inner_material.id">
                                    <option value="">Select</option>
                                    <option v-for="option in materialProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="text" id="quantity" class="form-control" v-model="formData.inner_material.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Padding">Padding :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.padding.id">
                                    <option value="">Select</option>
                                    <option v-for="option in paddingProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="text" id="quantity" class="form-control" v-model="formData.padding.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Outsole">Outsole :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.outsole.id">
                                    <option value="">Select</option>
                                    <option v-for="option in outsoleProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="text" id="quantity" class="form-control" v-model="formData.outsole.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Joints">Joints :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.joints.id">
                                    <option value="">Select</option>
                                    <option v-for="option in jointsProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="text" id="quantity" class="form-control" v-model="formData.joints.qty"
                                    placeholder="Quantity" />
                            </div>
                        </div>
                    </div>
                    <!-- Additional materials -->
                    <div class=" mb-3">
                        <div class="col-md-12 mb-4">
                            <h4>Additional Materials</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="font-weight-bold mb-4">Selection</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="font-weight-bold mb-4">Quantity</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="Material1">Material 1</label>
                                    <select class="form-control" v-model="formData.material1.id">
                                        <option value="">Select</option>
                                        <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material1Qty">Material quantity</label>
                                    <input type="number" id="quantity" class="form-control"
                                        v-model="formData.material1.qty" placeholder="Quantity" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material2">Material 2</label>
                                    <select class="form-control" v-model="formData.material2.id">
                                        <option value="">Select</option>
                                        <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material2Qty">Material quantity</label>
                                    <input type="number" id="quantity" class="form-control"
                                        v-model="formData.material2.qty" placeholder="Quantity" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material3">Material 3</label>
                                    <select class="form-control" v-model="formData.material3.id">
                                        <option value="">Select</option>
                                        <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material3Qty">Material quantity</label>
                                    <input type="number" id="quantity" class="form-control"
                                        v-model="formData.material3.qty" placeholder="Quantity" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material4">Material 4</label>
                                    <select class="form-control" v-model="formData.material4.id">
                                        <option value="">Select</option>
                                        <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material4Qty">Material quantity</label>
                                    <input type="number" id="quantity" class="form-control"
                                        v-model="formData.material4.qty" placeholder="Quantity" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material5">Material 5</label>
                                    <select class="form-control" v-model="formData.material5.id">
                                        <option value="">Select</option>
                                        <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="Material5Qty">Material quantity</label>
                                    <input type="number" id="quantity" class="form-control"
                                        v-model="formData.material5.qty" placeholder="Quantity" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="Mould type">Mould type :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-12" v-model="formData.mould_type">
                                    <option v-for="option in Mould" :key="option.value" :value="option.value">{{
                                        option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="modification_notes" class="form-label">Modification Notes:</label>
                        <textarea rows="4" id="modification_notes" class="form-control" v-model="formData.modification_notes"
                            placeholder="Modification Notes"></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="Other Notes" class="form-label">Other Notes :</label>
                        <textarea rows="4" id="other_notes" class="form-control" v-model="formData.other_notes"
                            placeholder="Other Notes"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import { CUSTOM_ORTHOSES_CUSTOMIZATION } from "../../../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../../../common/Helper/AxiosHelper";
import store from "../../../../../../store/Index";

export default {
    name: 'CampCreateEditModal',
    props: {
        orderId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        orderProductId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },

    },
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            CUSTOM_ORTHOSES_CUSTOMIZATION,
            camps: [],
            modification_notes: "",
            other_notes: "",
            formData: {
                id: '',
                mould_type: '3D scan',
                base_material:{
                    id:"",
                    qty:"",
                },
                inner_material:{
                    id:"",
                    qty:"",
                },
                padding:{
                    id:"",
                    qty:"",
                },
                joints:{
                    id:"",
                    qty:"",
                },
                outsole:{
                    id:"",
                    qty:"",
                },
                material1:{
                    id:"",
                    qty:"",
                },
                material2:{
                    id:"",
                    qty:"",
                },
                material3:{
                    id:"",
                    qty:"",
                },
                material4:{
                    id:"",
                    qty:"",
                },
                material5:{
                    id:"",
                    qty:"",
                },
            },
            materialProducts: [],
            paddingProducts: [],
            jointsProducts: [],
            otherProducts: [],
            outsoleProducts: [],
            dropdownOptions: [
                { label: "Demo", value: "Demo" },
                { label: "Demo 1", value: "Demo 1" },
                { label: "Demo 2", value: "Demo 2" },
                { label: "Demo 3", value: "Demo 3" },
                { label: "Demo 4", value: "Demo 4" },
                { label: "Demo 5", value: "Demo 5" },
            ],
            selectedType: "Demo",
            Mould: [
                { label: '3D scan', value: '3D scan' },
                { label: 'POP cast', value: 'POP cast' },
            ],
            selectedMould: "3D scan"
        };
    },
    created() {
        this.customFootwearOptions();
        this.fetchOrthoticsData();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        customFootwearOptions() {
            axiosGet(`app/selectable/custom-orthotics-products?branch_or_warehouse_id=${this.selectedBranchId}`).then(response => {
                const data = response.data.data;
                for (const items of data) {
                    const option = {
                        id: items.variant.id,
                        value: items.variant.name,
                        quantity: items.available_qty,
                        stockId: items.id,
                        branchId: items.branch_or_warehouse_id,
                        variantId: items.variant_id,
                    };
                    if (items.variant.product.sub_category_id === 1) {
                        this.materialProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 2) {
                        this.paddingProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 3) {
                        this.jointsProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 4) {
                        this.otherProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 18) {
                        this.outsoleProducts.push(option);
                    } 
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchOrthoticsData() {
            try {
                axiosGet(`${CUSTOM_ORTHOSES_CUSTOMIZATION}/show?order_product_id=${this.orderProductId}`).then(response => {
                    let resp = response.data[0];
                    if(resp.id > 0) {
                        this.formData.id = resp.id;
                        this.formData.mould_type = resp.mould_type;
                        this.formData.modification_notes = resp.modification_notes;
                        this.formData.other_notes = resp.other_notes;
                        
                        let materialJson = JSON.parse(resp.material_json);
                        let materialKeys = Object.keys(materialJson);
                        if(materialKeys.length > 0) {
                            for (const keys of materialKeys) {
                                this.formData[keys] = materialJson[keys];
                            }
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            this.formData.orderid = this.orderId;
            this.formData.order_product_id = this.orderProductId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#camp-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'camp-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>