<template>
    <modal id="app-active-queue-report-sort"
           v-model="showModal"
           :loading="loading"
           :size="'extra-large'"
           :preloader="preloader" :title="'Change Sorting'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='ACTIVE_QUEUE_SORT_UPDATE' @submit.prevent="submitData">

            <div class="filters-wrapper">
                <div class="single-filter column-filter">
                    <div class="dropdown">
                        <div class="dropdown-menu show" style="width: 100%; box-shadow: none;">
                            <div class="dropdown-item pt-0">
                                <draggable v-model="items" :options="{ animation: 200 }" @end="handleDrop($event)">
                                    <div class="item row py-2" v-for="(item, index) in items" :key="item.id">
                                        <div class="col-12 d-flex justify-content-between">
                                            <div class="content-type">
                                                <app-icon name="menu" />
                                                {{ item.order.invoice_number }} | {{ item.order.branch_or_warehouse.name }} | {{ item.order.customer.full_name }} | {{ getWorkflowName(item) }} | {{ formatDateToLocal(item.order.created_at) }} | {{ item.orderproduct.variant?.name }} | ({{ 'Eh - ' + item.estimated_hours+'hr' }}) | ({{ item.item_number }})
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import draggable from 'vuedraggable';
import { ACTIVE_QUEUE_REPORT_SORT,ACTIVE_QUEUE_SORT_UPDATE } from "../../../Config/ApiUrl-CP";
import { axiosGet,urlGenerator } from "../../../../common/Helper/AxiosHelper";
import { formatDateToLocal } from "../../../Helper/Helper";

export default {
    name: "ActiveQueueReportSort",
    mixins: [FormHelperMixins, ModalMixin],
    components: {
        draggable
    },
    props: {},
    data() {
        return {
            ACTIVE_QUEUE_SORT_UPDATE,
            formatDateToLocal,
            loading: false,
            items: [],
            formData:{
                orderdata : []
            }
        }
    },
    created() {
        this.getQueueData();
    },
    methods: {
        getWorkflowName(item) {
            var name = ''
            if(item.user_type == 0) {
                name = item.technician.technician_name;
            } else {
                name = item.technicianuser.full_name;
            }
            return name
        },
        getQueueData() {
            try {
                axiosGet(`${ACTIVE_QUEUE_REPORT_SORT}`).then(response => {
                    this.items = response.data;
                    
                });
            } catch (error) {
                console.error(error);
            }
        },
        handleDrop(e) {
            
        },
        submitData() {
            this.formData.orderdata = this.items.map((item, index) => ({
                id: item.id,
                sort_order: index + 1, // Update sort_order starting from 1
            }));
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#app-active-queue-report-sort').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
}
</script>