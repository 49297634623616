import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import {PAYMENT_REPORT, ORDER_MAX_MIN_PRICE, SELECTABLE_PRACTITIONER, GENERATE_INVOICE,GENERATE_PERFOMA_INVOICE, GENERATE_PERFOMA_INVOICE_VIEW, GENERATE_INVOICE_VIEW} from "../../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            filterHtml: '',
            options: {
                name: this.$t('payment_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Sr No.'),
                        type: 'object',
                        key: 'sr_no',
                        modifier: (value) => value 
                    },
                    {
                        title: this.$t('Order No.'),
                        type: 'custom-html',
                        key: 'orderid',
                        sortable: true,
                        modifier: (order,row) => row?.order?.invoice_number ? `<a href="${urlGenerator('order/details/'+row?.order.id)}" class="redirectTo">${row?.order.invoice_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Branch'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: value => value ? `<span>${value.branch_or_warehouse.name}</span>` : ''
                    },
                    {
                        title: this.$t('customer'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (row) => row.customer.full_name ? `<a href="${urlGenerator('customer/view/'+row.customer.id)}" class="redirectTo">${row.customer.full_name}</a>` :'-',
                    },
                    {
                        title: this.$t('Proforma No.'),
                        type: 'custom-html',
                        key: 'perfoma_gen_number',
                        sortable: true,
                        modifier: (perfoma_gen_number,row) => row?.order.perfoma_gen_number ? `<a href="${urlGenerator(GENERATE_PERFOMA_INVOICE_VIEW+row?.order.id)}" class="redirectTo">${row?.order.perfoma_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Invoice No.'),
                        type: 'custom-html',
                        key: 'invoice_gen_number',
                        sortable: true,
                        modifier: (invoice_gen_number,row) => row?.order.invoice_gen_number ? `<a href="${urlGenerator(GENERATE_INVOICE_VIEW+row?.order.id)}" class="redirectTo">${row?.order.invoice_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Order Value'),
                        type: 'custom-html',
                        key: 'order',
                        titleAlignment: 'right',
                        modifier: value => `<p class="text-right mb-0">${numberWithCurrencySymbol(value.grand_total)}</p>`
                    },
                    {
                        title: this.$t('Order Status'),
                        type: 'custom-html',
                        key: 'order',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.status.class}">${value.status.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Payment Date'),
                        type: 'custom-html',
                        key: 'created_at',
                        isVisible: true,
                        sortable: true,
                        modifier: (value) => {
                            return `<span>${formatDateToLocal(value)}</span>`;
                        }
                    },
                    {
                        title: this.$t('Amount'),
                        type: 'custom-html',
                        key: 'paid_amount',
                        isVisible: true,
                        modifier: value => `<p class="mb-0">${numberWithCurrencySymbol(value)}</p>`
                    },
                    {
                        title: this.$t('Mode'),
                        type: 'custom-html',
                        key: 'payment_mode',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span>${value?.item_label}</span>`;
                        }
                    },
                    {
                        title: this.$t('Note'),
                        type: 'custom-html',
                        key: 'payment_note',
                        isVisible: true,
                        modifier: (value) => {
                            return value ? `<span>${value}</span>` : '';
                        }
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'object',
                        key: 'order',
                        modifier: (row) => (row?.created_by?.full_name)
                    },
                    {
                        title: this.$t('Verified'),
                        type: 'object',
                        key: 'verified',
                        modifier: (value) => (value == 1)?'Yes':'No'
                    },
                    
                ],
                actionType: "dropdown",
                actions: [],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    {
                        title: "Practitioner",
                        type: "drop-down-filter",
                        key: "practitioner",
                        option: [],
                    },
                    {
                        title: this.$t('Verified'),
                        type: 'checkbox',
                        key: 'verified',
                        option: [
                            { id: '1', value: this.$t('Yes') },
                            { id: '0', value: this.$t('No') },
                        ],
                    },
                    // {
                    //     title: "Sort By",
                    //     type: "drop-down-filter",
                    //     key: "sort_by",
                    //     option: [
                    //         { id: 'created_at_asc', value: this.$t('Payment Date (ASC)') },
                    //         { id: 'created_at_desc', value: this.$t('Payment Date (DESC)') },
                    //         { id: 'invoice_no_asc', value: this.$t('Invoice No. (ASC)') },
                    //         { id: 'invoice_no_desc', value: this.$t('Invoice No. (DESC)') }, 
                    //         { id: 'proforma_invoice_no_asc', value: this.$t('Proforma Invoice No. (ASC)') },
                    //         { id: 'proforma_invoice_no_desc', value: this.$t('Proforma Invoice No. (DESC)') },
                    //     ],
                    // },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        practitionerList() {
            axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
                let name = this.options.filters.find(element => element.key === 'practitioner');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.full_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(PAYMENT_REPORT, this.updateMinMaxPriceUrl);
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });

        this.$hub.$on('filter-change', (newFilterValues) => {
            let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
            this.filterHtml = '';
            if(filterData?.date?.start) {
                this.filterHtml = '<span class="d-block">Payment Report from '+formatDateToLocal(filterData?.date.start,false,null,0,'DD MMM YYYY')+' to '+formatDateToLocal(filterData?.date.end,false,null,0,'DD MMM YYYY')+'</span>';
            }
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(PAYMENT_REPORT, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
        this.practitionerList();
    }
}