import {axiosGet} from "../../common/Helper/AxiosHelper";
import {CAMPS, CUSTOMERS, CUSTOMER_DATA, DOCTORS} from "../../tenant/Config/ApiUrl-CPB";

const state = {
    customer: {}
}

const getters = {
    getCustomersByBranchOrWarehouse(state) {
        return state.stocks;
    },

    getCampsByBranchOrWarehouse(state) {
        return state.stocks;
    },

    getDoctorsByBranchOrWarehouse(state) {
        return state.stocks;
    }
};

const actions = {
    getCustomer({commit},customerId) {
        commit('SET_LOADER', true);
        axiosGet(`${CUSTOMER_DATA}/${customerId}`).then(({data}) => {
            commit('CUSTOMER_DATA', data)
        }).finally(() => {
            commit('SET_LOADER', false);
        });
    },

    async setCustomersByBranchOrWarehouseId({commit, state}, payload) {
        const {data} = await axiosGet(CUSTOMERS,{params: {'branch_or_warehouse_id': payload}})
        commit('SET_CUSTOMERS_BY_BRANCH_OR_WAREHOUSE_ID', data);
    },

    async setCampsByBranchOrWarehouseId({commit, state}, payload) {
        const {data} = await axiosGet(CAMPS,{params: {'branch_or_warehouse_id': payload}})
        commit('SET_CAMPS_BY_BRANCH_OR_WAREHOUSE_ID', data);
    },

    async setChiefComplaintByBranchOrWarehouseId({commit, state}, payload) {
        const {data} = await axiosGet(CHIEF_COMPLAINT,{params: {'branch_or_warehouse_id': payload}})
        commit('SET_CHIEF_COMPLAINT_BY_BRANCH_OR_WAREHOUSE_ID', data);
    },

    async setDoctorsByBranchOrWarehouseId({commit, state}, payload) {
        const {data} = await axiosGet(DOCTORS,{params: {'branch_or_warehouse_id': payload}})
        commit('SET_DOCTORS_BY_BRANCH_OR_WAREHOUSE_ID', data);
    },
}

const mutations = {
    CUSTOMER_DATA(state, data) {
        state.customer = data;
    },

    SET_CUSTOMERS_BY_BRANCH_OR_WAREHOUSE_ID(state, payload) {
        state.stocks = payload;
    },

    SET_CAMPS_BY_BRANCH_OR_WAREHOUSE_ID(state, payload) {
        state.stocks = payload;
    },
    

    SET_CHIEF_COMPLAINT_BY_BRANCH_OR_WAREHOUSE_ID(state, payload) {
        state.stocks = payload;
    },
    
    SET_DOCTORS_BY_BRANCH_OR_WAREHOUSE_ID(state, payload) {
        state.stocks = payload;
    }
}

export default {
    state,
    actions,
    mutations
}
