<template>
    <section id="cp-item-wise-report-section" class="content-wrapper">
        <app-page-top-section :title="'CP Item Wise Report'" icon="briefcase">
            <app-default-button
                @click="exportSalesSummary"
                :title="$fieldTitle('export', '', true)"/>
        </app-page-top-section>
        <app-table :id="table_id" :options="options" v-if="options.url" @action=""></app-table>

    </section>
</template>

<script>
import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import CpItemWiseReportMixins from "./CpItemWiseReportMixins";
import {CP_ITEM_WISE_REPORT_EXPORT} from "../../../Config/ApiUrl-CP";
import {numberWithCurrencySymbol} from "../../../Helper/Helper";
import {mapGetters} from "vuex";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name: 'CpItemWiseReport',
    mixins: [DatatableHelperMixin, CpItemWiseReportMixins],
    data() {
        return {
            table_id: 'cp-item-wise-report-table',
            numberWithCurrencySymbol,
            exportLoading: false,
            branch_or_warehouse_id: this.getBranchOrWarehouseId,
        };
    },
    methods: {
        exportSalesSummary() {
            window.open(urlGenerator(`${CP_ITEM_WISE_REPORT_EXPORT}/?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.branch_or_warehouse_id = new_id
        },
    }
};
</script>
<style>
.tblScroll .table-responsive.custom-scrollbar table { width: 2220px; }
</style>
