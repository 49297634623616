<template>
    <div class="content-wrapper" v-click-outside="wantToLeave">
        <app-page-top-section :title="productId ? $t('edit_product') : $addLabel('product')" :hide-button="true"
            icon="menu" />

        <app-overlay-loader v-if="editDataLoading" />
        <template>
            <div class="card border-0 card-with-shadow" v-show="!editDataLoading">
                <div class="card-body">
                    <!-- <div class="mb-primary">
                        <div class="note note-warning p-4">
                            <div class="note-title d-flex">
                                <app-icon :name="'book-open'"/>
                                <h6 class="card-title pl-2"> {{ $t('how_to_manage_product') }}</h6>
                            </div>
                            <div class="ml-4">
                                <p class="m-1">{{ $t('product_instruction_1') }}</p>
                                <p class="m-1">{{ $t('product_instruction_2') }}</p>
                                <p class="m-1">{{ $t('product_instruction_3') }} <a :href="goToStockPage">{{ $t('stock') }}</a>
                                    {{ $t('product_instruction_4') }}</p>
                                <p class="m-1"> {{ $t('product_instruction_5') }} </p>
                            </div>
                        </div>
                    </div> -->

                    <div class="min-height-400" :class="{ 'loading-opacity': !isActive.renderComponent }">
                        <fieldset class="form-group mb-5">
                            <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                {{ $t('product_information') }}
                            </legend>

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('name') }}*
                                </label>
                                <div class="col-lg-7">
                                    <app-input v-model="formData.name" @input="prepareVariant()" :required="true"
                                        :error-message="$errorMessage(errors, 'name')"
                                        :placeholder="$placeholder('name')" />
                                </div>
                            </div>

                            <!-- <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('Usage') }}*
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="select" :list="selectableList.usage" list-value-field="name"
                                        v-model="formData.usage" :required="true"
                                        :error-message="$errorMessage(errors, 'usage')"
                                        :placeholder="$placeholder('Usage')" />
                                </div>
                            </div> -->

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('HSN/SAC') }}
                                </label>
                                <div class="col-lg-7">
                                    <app-input v-model="formData.hsnsac" :required="false"
                                        :error-message="$errorMessage(errors, 'hsnsac')"
                                        :placeholder="$placeholder('HSN/SAC')" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('description') }}
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="textarea" :text-area-rows="5" v-model="formData.description"
                                        :error-message="$errorMessage(errors, 'description')"
                                        :placeholder="$placeholder('description')" />
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('image') }}
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="custom-file-upload" @change="handleProductThumbnailChange"
                                        v-model="formData.product_thumbnail" :generate-file-url="false"
                                        :label="$t('upload_file')" :required="false"
                                        :error-message="$errorMessage(errors, 'product_thumbnail')" />
                                    <div class="margin-top-10">
                                        <small class="text-muted font-italic text-size-12">
                                            {{ $t('recommended_type_jpg') }}
                                            {{ $t('max_filesize_5mb') }}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('product_gallery') }}
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="dropzone" class="dropzone-product-gallery" :key="dropzoneKey"
                                        v-model="formData.product_gallery"
                                        :error-message="$errorMessage(errors, 'product_gallery')"
                                        @file-removed="handleDropzoneFileRemove" />

                                    <div class="margin-top-10">
                                        <small class="text-muted font-italic text-size-12">
                                            {{ $t('recommended_type_jpg') }}
                                            {{ $t('max_filesize_5mb') }}
                                        </small>
                                    </div>

                                    <dropzone-img-remove-alert-message
                                        v-if="Boolean(productCreateEditSelectedUrl) && Boolean(formData.product_gallery.length)" />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="form-group mb-5">

                            <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                {{ $t('specifications') }}
                            </legend>

                            <!-- <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('group') }}
                                </label>
                                <div class="col-lg-7">
                                    <div class="row no-gutters">
                                        <div class="col-10 col-md-11">
                                            <app-input class="margin-right-8" type="search-and-select" :key="ssKey"
                                                :placeholder="$t('search_and_select', { name: $t('group').toLowerCase() })"
                                                :options="groupOptions" v-model="formData.group_id"
                                                :error-message="$errorMessage(errors, 'group')" />
                                        </div>
                                        <div class="col-2 col-md-1">
                                            <button type="button" data-toggle="tooltip" :title="$addNew('group')"
                                                data-placement="top" class="btn default-base-color btn-block h-100 p-1"
                                                @click="openModal('group')">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('Main category') }}*
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="select" :list="selectableList.mainCategory" list-value-field="name"
                                        v-model="formData.main_category" :required="true"
                                        :error-message="$errorMessage(errors, 'main_category')"
                                        :placeholder="$placeholder('Main Category')" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('category') }}
                                </label>
                                <div class="col-lg-7">
                                    <div class="row no-gutters">
                                        <div class="col-10 col-md-11">
                                            <app-input class="margin-right-8" type="search-and-select"
                                                :placeholder="$t('search_and_select', { name: $t('category').toLowerCase() })"
                                                :options="categoryOptions" :key="ssKey" @input="handleCategoryChange"
                                                v-model="formData.category_id"
                                                :error-message="$errorMessage(errors, 'category_id')" />
                                        </div>
                                        <div class="col-2 col-md-1">
                                            <button type="button" data-toggle="tooltip" :title="$addNew('category')"
                                                data-placement="top" class="btn default-base-color btn-block h-100 p-1"
                                                @click="openModal('category')">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-center" v-if="formData.category_id">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('sub_category') }}
                                </label>
                                <div class="col-lg-7">
                                    <div class="row no-gutters">
                                        <div class="col-10 col-md-11">
                                            <app-input class="margin-right-8" type="search-and-select"
                                                :placeholder="$t('search_and_select', { name: $t('sub_category').toLowerCase() })"
                                                :options="subCategoryOptions"
                                                :key="componentKeys.subcategorySearchAndSelect"
                                                v-model="formData.sub_category_id"
                                                :error-message="$errorMessage(errors, 'sub_category')" />
                                        </div>
                                        <div class="col-2 col-md-1">
                                            <button type="button" data-toggle="tooltip" :title="$addNew('sub_category')"
                                                data-placement="top" class="btn default-base-color btn-block h-100 p-1"
                                                @click="openModal('subCategory')">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('brand') }}
                                </label>
                                <div class="col-lg-7">
                                    <div class="row no-gutters">
                                        <div class="col-10 col-md-11">
                                            <app-input class="margin-right-8" type="search-and-select" :key="ssKey"
                                                :placeholder="$t('search_and_select', { name: $t('brand').toLowerCase() })"
                                                :options="brandOptions" v-model="formData.brand_id"
                                                :error-message="$errorMessage(errors, 'brand')" />
                                        </div>
                                        <div class="col-2 col-md-1">
                                            <button type="button" data-toggle="tooltip" :title="$addNew('brand')"
                                                data-placement="top" class="btn default-base-color btn-block h-100 p-1"
                                                @click="openModal('brand')">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('unit') }}
                                </label>
                                <div class="col-lg-7">
                                    <div class="row no-gutters">
                                        <div class="col-10 col-md-11">
                                            <app-input class="margin-right-8" :required="true" type="search-and-select" :key="ssKey"
                                                :placeholder="$t('search_and_select', { name: $t('unit').toLowerCase() })"
                                                :options="unitOptions" v-model="formData.unit_id"
                                                :error-message="$errorMessage(errors, 'unit')" />
                                        </div>
                                        <div class="col-2 col-md-1">
                                            <button type="button" data-toggle="tooltip" :title="$addNew('unit')"
                                                data-placement="top" class="btn default-base-color btn-block h-100 p-1"
                                                @click="openModal('unit')">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('Gender') }}*
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="select" :list="selectableList.genderList" list-value-field="value"
                                        v-model="formData.gender" :required="false"
                                        :error-message="$errorMessage(errors, 'gender')"
                                        :placeholder="$placeholder('Gender')" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="mb-lg-0 col-lg-3">
                                    {{ $t('Age Group') }}*
                                </label>
                                <div class="col-lg-7">
                                    <app-input type="select" :list="selectableList.ageGroupList" list-value-field="value"
                                        v-model="formData.age_group" :required="false"
                                        :error-message="$errorMessage(errors, 'age_group')"
                                        :placeholder="$placeholder('Age Group')" />
                                </div>
                            </div>


                            <div :class="`form-group row align-items-center ${this.productId && 'pe-none opacity-50'}`">
                                    <label class="mb-lg-0 col-lg-3">
                                        {{ $t('product_type') }}*
                                    </label>
                                    <div class="col-lg-7">
                                        <app-input type="radio"
                                            @input="parseInt($event) === 1 ? initializeTooltip(1000) : false"
                                            :disabled="Boolean(productId)" :required="true" v-model="formData.product_type"
                                            :list="selectableList.productTypes"
                                            :error-message="$errorMessage(errors, 'product_type')" />
                                    </div>
                                </div>
                        </fieldset>

                        <app-overlay-loader v-if="!isActive.renderComponent" />

                        <template>
                            <template v-if="formData.product_type === 'single'">
                                
                                <fieldset class="form-group mb-5">
                                    <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                        {{ $t('item_details') }}
                                    </legend>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('SKU') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <div class="row no-gutters">
                                                <div class="col-10 col-md-11">
                                                    <app-overlay-loader v-if="showUpcLoader" />
                                                    <app-input :class="`margin-right-8 ${showUpcLoader && 'temp-disable'}`"
                                                        :placeholder="$placeholder('SKC')" :required="true"
                                                        @keyup="handleSingleProductUpcChange" v-model="formData.upc"
                                                        :error-message="$errorMessage(errors, 'upc')" />
                                                    <small class="text-danger text-xs" v-if="!upcIsUnique">{{
                                                        $t('the_upc_you_entered_is_not_unique')
                                                    }}</small>
                                                </div>
                                                <div class="col-2 col-md-1">
                                                    <button type="button" data-toggle="tooltip" :title="$t('get_a_new_upc')"
                                                        data-placement="top" :key="'refresh-cw'"
                                                        :class="`btn default-base-color btn-block h-100 p-1 ${showUpcLoader && 'temp-disable'}`"
                                                        @click="generateProductUpc">
                                                        <app-icon class="size-19 primary-text-color" name="refresh-cw" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('warranty_duration') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <div class="row no-gutters">
                                                <div class="col-9 col-md-10">
                                                    <app-input class="margin-right-8" type="number"
                                                        :placeholder="$placeholder('warranty_duration')"
                                                        v-model="formData.warranty_duration"
                                                        :error-message="$errorMessage(errors, 'warranty_duration')" />
                                                </div>
                                                <div class="col-3 col-md-2 pl-0">
                                                    <app-input type="select" :list="selectableList.warranty_duration_type"
                                                        v-model="formData.duration_id" list-value-field="type"
                                                        :error-message="$errorMessage(errors, 'duration_id')" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- single product tax -->
                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('tax') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input type="search-and-select" :key="ssKey"
                                                :placeholder="$placeholder('tax')" :options="taxOptions"
                                                v-model="formData.tax_id"
                                                :error-message="$errorMessage(errors, 'tax_id')" />
                                        </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('note') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input type="textarea" :text-area-rows="5"
                                                :placeholder="$placeholder('note')" v-model="formData.note"
                                                :error-message="$errorMessage(errors, 'note')" />
                                        </div>
                                    </div>
                                    <div v-if="formData.main_category == 3" class="form-group row">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('Estimated Hours') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input :required="false" type="number" v-model="formData.estimated_hours" :placeholder="$placeholder('Estimated Hours')" :label="$t('Estimated Hours')" :error-message="$errorMessage(errors, 'Estimated Hours')" />
                                        </div>
                                    </div>

                                </fieldset>
                                <fieldset class="form-group mb-5">
                                    <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                        {{ $t('product_pricing') }}
                                    </legend>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            Base Price *
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input :required="true" :disabled="false" type="number"
                                                v-model="formData.base_price"
                                                :placeholder="$placeholder('Base Price')" :label="$t('Base Price')"
                                                :error-message="$errorMessage(errors, 'base_price')" />
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            Tax Amount
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input :required="false" type="number"
                                                v-model="formData.tax_amount"
                                                :placeholder="$placeholder('Tax Amount')" :label="$t('Tax Amount')"
                                                :error-message="$errorMessage(errors, 'tax_amount')" />
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('selling_price') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input :required="false" type="number"
                                                v-model="formData.selling_price"
                                                :placeholder="$placeholder('selling_price')" :label="$t('selling_price')"
                                                :error-message="$errorMessage(errors, 'selling_price')" />
                                        </div>
                                    </div>
                                </fieldset>
                            </template>

                            <template v-else>
                                <fieldset class="form-group mb-5">
                                    <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                        {{ $t('item_details') }}
                                    </legend>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('warranty_duration') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <div class="row no-gutters">
                                                <div class="col-9 col-md-10">
                                                    <app-input class="margin-right-8" type="number"
                                                        :placeholder="$placeholder('warranty_duration')"
                                                        v-model="formData.warranty_duration"
                                                        :error-message="$errorMessage(errors, 'warranty_duration')" />
                                                </div>
                                                <div class="col-3 col-md-2 pl-0">
                                                    <app-input type="select" :list="selectableList.warranty_duration_type"
                                                        v-model="formData.duration_id" list-value-field="type"
                                                        :error-message="$errorMessage(errors, 'duration_id')" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- variant product tax -->
                                    <div v-if="!productId" class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('tax') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input type="search-and-select" :key="ssKey" :options="taxOptions"
                                                :placeholder="$placeholder('tax')" v-model="formData.tax_id"
                                                :error-message="$errorMessage(errors, 'tax')" />
                                        </div>
                                    </div>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('Low threshold') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input v-model="formData.low_threshold" :required="threshold_required"
                                                :error-message="$errorMessage(errors, 'low_threshold')"
                                                :placeholder="$placeholder('Low threshold')" />
                                        </div>
                                    </div>

                                    <div class="form-group row align-items-center">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('High threshold') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <app-input v-model="formData.high_threshold" :required="threshold_required"
                                                :error-message="$errorMessage(errors, 'high_threshold')"
                                                :placeholder="$placeholder('High threshold')" />
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset class="form-group mb-5">
                                    <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                        {{ $t('attribute_definition') }}
                                    </legend>

                                    <div class="form-group row align-items-center" v-if="Boolean(!productId)"
                                        :key="attributeListInputKey">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ $t('variant_attribute') }}
                                        </label>
                                        <div class="col-lg-7">
                                            <div class="row no-gutters">
                                                <div class="col-10 col-md-11">
                                                    <app-input class="margin-right-8" type="select"
                                                        :list="!productId ? selectableList.attributes : filteredVariantAttributes"
                                                        list-value-field="name" @change="changeAttribute" :required="true"
                                                        v-model="formData.attribute_id"
                                                        :error-message="$errorMessage(errors, 'variant_attribute')" />
                                                </div>
                                                <div class="col-2 col-md-1">
                                                    <button type="button" data-toggle="tooltip"
                                                        :title="$addNew('variant_attribute')" data-placement="top"
                                                        :key="'plus'" class="btn default-base-color btn-block h-100 p-1"
                                                        @click="openModal('attribute')">
                                                        <app-icon class="size-19 primary-text-color" name="plus" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="item in attributeCombination" class="form-group row">
                                        <label class="mb-lg-0 col-lg-3">
                                            {{ capitalizeFirst(item.name) }}
                                        </label>
                                        <div class="col-lg-7">
                                            <div class="row no-gutters">
                                                <div class="col-10 col-md-11">
                                                    <app-input class="margin-right-8" type="multi-create"
                                                        @storeData="attributeVariationStore($event, item)"
                                                        :list="item.attribute_variations" @input="changeChips($event, item)"
                                                        list-value-field="name" :required="true"
                                                        :unremovables="productId ? Object.values(existingVariationChips).flat() : []"
                                                        v-model="formData.variationChips[item.name.toLowerCase()]" />
                                                </div>
                                                <div class="col-2 col-md-1">
                                                    <button type="button" v-if="!Boolean(productId)" data-toggle="tooltip"
                                                        :title="$t('remove_variant_attribute')" data-placement="top"
                                                        class="btn default-base-color btn-block p-1 py-2"
                                                        @click="changeAttribute('delete', item)">
                                                        <app-icon class="size-19 primary-text-color" name="trash-2" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </fieldset>
                                <fieldset class="form-group mb-5" v-if="isActive.attribute_definition">
                                    <legend class="text-muted text-size-18 border-bottom pb-2 mb-3">
                                        {{ $t('item_details_of_variant') }}
                                    </legend>

                                    <div class="form-group row d-none d-lg-flex">
                                        <div class="col-lg-10">
                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <label class="mb-lg-0">
                                                        {{ $t('variants') }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label class="mb-lg-0">
                                                        {{ $t('Base Price') }}*
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label class="mb-lg-0">
                                                        {{ $t('Tax Amount') }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label class="mb-lg-0">
                                                        {{ $t('selling_price') }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-2" v-if="formData.main_category == 3">
                                                    <label class="mb-lg-0">
                                                        {{ $t('Estimated Hours') }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <label class="mb-lg-0">
                                                        {{ $t('SKU') }}*
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 text-right">
                                            {{ $t('status') }}
                                        </div>
                                        <!-- <div class="col-lg-1 text-right">
                                            {{ $t('custom') }}
                                        </div> -->
                                        <div class="col-lg-1 text-right">
                                            {{ $t('actions') }}
                                        </div>
                                    </div>
                                    <div :class="`form-group row mb-5 mb-lg-2
                                        ${item.upcData.variantUpcIsUnique && formData.variants.some(variant => !variant.upcData.variantUpcIsUnique) ? 'temp-disable' : ''}`"
                                        v-for="(item, index) in formData.variants">
                                        <div class="col-lg-10">
                                            <div class="row">
                                                <div class="col-lg-2 mb-2 mb-lg-0">
                                                    <app-input :disabled="!item.is_checked" v-model="item.name" />
                                                </div>
                                                <div class="col-lg-2 mb-2 mb-lg-0">
                                                    <app-input :disabled="!item.is_checked" type="number" :required="true"
                                                        v-model="item.base_price"
                                                        :placeholder="$placeholder('Base Price')"
                                                        @keyup="updateVariantPrice(item, index)"
                                                        :label="$t('Base Price')"
                                                        :error-message="$errorMessage(errors, 'base_price')" />
                                                </div>
                                                <div class="col-lg-2 mb-2 mb-lg-0">
                                                    <app-input :disabled="false" type="number" :required="false"
                                                        v-model="item.tax_amount"
                                                        :placeholder="$placeholder('Tax Amount')"
                                                        :label="$t('Tax Amount')"
                                                        :error-message="$errorMessage(errors, 'tax_amount')" />
                                                </div>
                                                <div class="col-lg-2 mb-2 mb-lg-0">
                                                    <app-input :disabled="false" type="number" :required="false"
                                                        v-model="item.selling_price"
                                                        :placeholder="$placeholder('selling_price')"
                                                        :label="$t('selling_price')"
                                                        :error-message="$errorMessage(errors, 'selling_price')" />
                                                </div>
                                                <div v-if="formData.main_category == 3" class="col-lg-2 mb-2 mb-lg-0">
                                                    <app-input :required="false" type="number" v-model="item.estimated_hours" :placeholder="$placeholder('Estimated Hours')" :label="$t('Estimated Hours')" :error-message="$errorMessage(errors, 'Estimated Hours')" />
                                                </div>
                                                <div class="col-lg-2 mb-2 mb-lg-0">
                                                    <div class="row no-gutters">
                                                        <div class="col-11 col-lg-10">
                                                            <app-overlay-loader v-if="item.upcData.showVariantUpcLoader" />
                                                            <app-input :disabled="!item.is_checked"
                                                                :class="`margin-right-8 ${item.upcData.showVariantUpcLoader && 'temp-disable'}`"
                                                                v-model="item.upc"
                                                                @keyup="handleVariantProductUpcChange(item, index)"
                                                                :required="true" :placeholder="$placeholder('SKU')" />
                                                            <small class="text-danger text-xs"
                                                                v-if="!item.upcData.variantUpcIsUnique">{{
                                                                    $t('the_upc_you_entered_is_not_unique')
                                                                }}</small>
                                                        </div>
                                                        <div class="col-1 col-lg-2">
                                                            <button type="button" :disabled="!item.is_checked"
                                                                data-toggle="tooltip" :title="$t('get_a_new_upc')"
                                                                data-placement="top"
                                                                :class="`btn default-base-color btn-block h-100 p-1 ${showUpcLoader && 'temp-disable'}`"
                                                                @click="handleGenerateUpcBtnClick(index)">
                                                                <app-icon class="size-19 primary-text-color"
                                                                    name="refresh-cw" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-1">
                                            <div class="text-right h-100 mt-3 mt-lg-0">
                                                <button type="button" class="btn default-base-color h-100 p-1 padding-x-10"
                                                    data-toggle="tooltip" :title="$t('change_item_status')"
                                                    :key="item.is_checked ? 'check-square' : 'square'"
                                                    @click="checkedVariants(item, index)">
                                                    <app-icon class="size-19 primary-text-color"
                                                        :name="item.is_checked ? 'check-square' : 'square'" />

                                                </button>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-1">
                                            <button type="button" class="btn default-base-color h-100 p-1 padding-x-10"
                                                data-toggle="tooltip" :title="$t('Custom Insoles Customization')"
                                                :key="item.is_custom_insoles ? 'check-square' : 'square'"
                                                @click="checkedCustomInsoles(item, index)">
                                                <app-icon class="size-19 primary-text-color"
                                                    :name="item.is_custom_insoles ? 'check-square' : 'square'" />

                                            </button>
                                        </div> -->
                                        <div class="col-lg-1 text-right">
                                            <button type="button" :disabled="!item.is_checked" data-toggle="tooltip"
                                                :title="$addNew('variant_details')" data-placement="top"
                                                class="btn default-base-color h-100 p-1 padding-x-10 margin-left-5"
                                                @click="openModal('variant', value = item, index)">
                                                <app-icon class="size-19 primary-text-color" name="plus" />
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <!-- </div> -->
                                </fieldset>

                                <div class="form-group row">
                                    <label class="mb-lg-0 col-lg-3">
                                        {{ $t('note') }}
                                    </label>
                                    <div class="col-lg-7">
                                        <app-input type="textarea" :text-area-rows="5" :placeholder="$placeholder('note')"
                                            v-model="formData.note" :error-message="$errorMessage(errors, 'note')" />
                                    </div>
                                </div>

                            </template>

                        </template>

                    </div>

                    <div class="row">
                        <div class="col-12 mt-5">
                            <app-cancel-button btn-class="btn-secondary" @click="handleCancelBtnClick" />
                            <app-submit-button
                                :btn-class="`ml-2 ${showUpcLoader || !upcIsUnique || formData.variants.some(variant => !variant.upcData.variantUpcIsUnique) ? 'temp-disable' : ''}`"
                                :loading="loading" @click="submitData" />
                        </div>
                    </div>
                </div>
            </div>

            <app-group-modal v-if="isActive.group" v-model="isActive.group" :selected-url="selectedUrl"
                @close="isActive.group = false" @update-values="getSelectables({ groups: SELECTABLE_GROUPS })" />

            <app-category-modal v-if="isActive.category" v-model="isActive.category" :selected-url="selectedUrl"
                @close="isActive.category = false" @update-values="getSelectables({ categories: SELECTABLE_CATEGORIES })" />

            <app-sub-category-modal v-if="isActive.subCategory" v-model="isActive.subCategory" :selected-url="selectedUrl"
                :category-id="formData.category_id" @close="isActive.subCategory = false" @input="handleSubcategoryInput"
                @update-values="getSelectables({ subcategories: SELECTABLE_SUB_CATEGORIES })" />

            <app-brand-modal v-if="isActive.brand" v-model="isActive.brand" :selected-url="selectedUrl"
                @close="isActive.brand = false" @update-values="getSelectables({ brands: SELECTABLE_BRANDS })" />

            <app-unit-create-edit-modal v-if="isActive.unit" v-model="isActive.unit" :selected-url="selectedUrl"
                @close="isActive.unit = false" @update-values="getSelectables({ units: SELECTABLE_UNITS })" />


            <app-attribute-modal v-if="isActive.attribute" v-model="isActive.attribute" :selected-url="selectedUrl"
                @close="isActive.attribute = false" @update-values="handleAttributeValueUpdate" />

            <app-variant-create-edit-modal v-if="isActive.variant" v-model="isActive.variant" :selected-url="selectedUrl"
                :product-id="productId + ''" :variant="variantRow" :variantGalleryBackup="variantGalleryBackup"
                @close="isActive.variant = false" @variant-data-update="handleVariantDataUpdate"
                @variant-thumbnail-change="handleVariantThumbnailChange" />
        </template>
    </div>
</template>

<script>
import Note from "../../../../../common/Components/Helper/Note";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ProductCreateMixin from "./ProductCreateMixin";
import { axiosDelete, axiosGet, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import StatusQueryMixin from "../../../../../common/Mixin/Global/StatusQueryMixin";
import ProductEditMixin from "./ProductEditMixin";
import DeleteMixin from "../../../../../common/Mixin/Global/DeleteMixin";
import ProductUpcValidateMixin from "./ProductUpcValidateMixin";
import {
    PRODUCT_IMAGE_DELETE, PRODUCT_LIST, SELECTABLE_BRANDS, SELECTABLE_CATEGORIES,
    SELECTABLE_GROUPS, STOCK,
    SELECTABLE_TAX, SELECTABLE_UNITS,
    SELECTABLE_VARIANT_ATTIRBUTES, INVENTORY, SELECTABLE_DROPDOWN
} from "../../../../Config/ApiUrl-CP";
import DropzoneImgRemoveAlertMessage from "./DropzoneImgRemoveAlertMessage";


export default {
    name: "ProductCreateEdit",
    components: {
        Note,
        'dropzone-img-remove-alert-message': DropzoneImgRemoveAlertMessage
    },
    mixins: [FormHelperMixins, ProductCreateMixin, StatusQueryMixin, ProductEditMixin, ProductUpcValidateMixin, DeleteMixin],
    props: ['productId'],
    data() {
        return {
            attributeListInputKey: 0,
            dropZoneBoot: false,
            // these will be updated from the getSelectables method to rerender the components
            specificationModalKeys: {
                groups: 0,
                brands: 0,
                categories: 0,
                attributes: 0,
                units: 0,
            },
            formData: {
                hsnsac: 0,
                low_threshold: 0,
                high_threshold: 0,
                selling_price: 0,
                name: '',
                gender: '',
                age_group: '',
                tax_amount: 0,
                base_price: 0,
                estimated_hours: 0,
            },
            isLoaded: false,
            threshold_required: false,
            existingVariationChips: {},
            confirmationModalActive: false,
            renderCategorySelectComponent: true,

            // search and select field options
            taxOptions: {
                url: urlGenerator(SELECTABLE_TAX),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId)
            },
            variantAttributeOptions: {
                url: urlGenerator(SELECTABLE_VARIANT_ATTIRBUTES),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId)
            },
            // specifications input inputs
            groupOptions: {
                url: urlGenerator(SELECTABLE_GROUPS),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId),
            },
            categoryOptions: {
                url: urlGenerator(SELECTABLE_CATEGORIES),
                query_name: "search_by_name",
                params: {
                    status_id: '',
                },
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId)
            },
            subCategoryOptions: {
                query_name: "search_by_name",
                params: {
                    status_id: '',
                },
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId)
            },
            brandOptions: {
                url: urlGenerator(SELECTABLE_BRANDS),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                prefetch: !Boolean(this.productId),
                modifire: ({ id, name: value }) => ({ id, value }),
            },
            unitOptions: {
                url: urlGenerator(SELECTABLE_UNITS),
                query_name: "search_by_name",
                params: {
                    status_id: '',
                },
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: !Boolean(this.productId)
            },
            componentKeys: {
                subcategorySearchAndSelect: 0,
            },
            taxLists: [],

        }
    },
    watch: {
        ssKey() {
            this.componentKeys.subcategorySearchAndSelect++;
        },
        'formData.main_category'(cateid) {
            this.threshold_required = false;
            // if(cateid == 3) {
            //     console.log("ok");
            //     // this.threshold_required = true;
            // }
        },
        'formData.tax_id'() {
            this.updateSellingPrice();
        },
        'formData.base_price'() {
            this.updateSellingPrice();
        }
    },
    methods: {
        updateSellingPrice(){
            let basePrice = this.formData.base_price || 0;
            let taxAmt = this.formData.tax_amount || 0;
            if(this.formData.tax_id > 0 && this.taxLists.length > 0) {
                taxAmt = ((basePrice*this.taxLists[this.formData.tax_id])/100)
            }
            this.formData.tax_amount = taxAmt;
            this.formData.selling_price = (parseFloat(basePrice) + parseFloat(taxAmt));
        },
        handleAttributeValueUpdate() {
            this.getSelectables({ attributes: this.SELECTABLE_ATTRIBUTES });
            this.attributeListInputKey++;
        },
        handleVariantThumbnailChange(variantIndex) {
            this.formData.variants = this.formData.variants.map((v, i) => i === variantIndex ? {
                ...v,
                variantThumbnailChanged: true
            } : v);
        },
        updateVariantPrice(item,index){
            let taxId = this.formData.variants[index].tax_id || this.formData.tax_id;
            let basePrice = this.formData.variants[index].base_price || 0;
            let taxAmt = this.formData.tax_amount || 0;
            if(taxId > 0 && this.taxLists.length > 0) {
                taxAmt = ((basePrice*this.taxLists[taxId])/100)
            }
            this.formData.variants[index].tax_amount = taxAmt;
            this.formData.variants[index].selling_price = (parseFloat(basePrice) + parseFloat(taxAmt));
        },
        handleProductThumbnailChange() {
            this.productThumbnailChanged = true;
        },
        handleDropzoneFileRemove(file) {
            if (this.formDataBeingSubmitted) return;
            if (!this.productId) return;
            const fileToRemove = this.formData.photos.find(photo => photo.path === file.dataURL);
            if (!fileToRemove) return;
            const { id: fileToRemoveId } = fileToRemove;
            axiosDelete(PRODUCT_IMAGE_DELETE + fileToRemoveId)
                .then(res => this.$toastr.s('', res.data.message))
                .catch(err => this.$toastr.e('', err.message))
        },
        handleCancelBtnClick() {
            this.$emit('cancel');
            window.location.replace(urlGenerator(PRODUCT_LIST));
        },
        handleSubcategoryInput() {
            this.componentKeys.subcategorySearchAndSelect += 1;
        },
        handleGenerateUpcBtnClick(variantProductIndex) {
            axiosGet("app/product/get_upc")
                .then(({ data: upc }) => {
                    this.variantUpcData.upcs[variantProductIndex] = upc;
                    this.formData.variants = this.formData.variants.map((v, i) => i !== variantProductIndex ? v : {
                        ...v,
                        upc
                    });
                    this.handleVariantUpcBlur();
                    if (this.formData.variants[variantProductIndex].upcData) this.formData.variants[variantProductIndex].upcData.variantUpcIsUnique = true;
                });
        },
        handleCategoryChange() {
            this.sub_category_id = '';
        },
        getGenderList() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=12`).then(response => {
                    let i = 0;
                    for (let item of response.data.data) {
                        if(i == 0) {
                            this.selectableList.genderList.push({
                                id: '',
                                value: 'Select Gender',
                            });    
                        }
                        this.selectableList.genderList.push({
                            id: item.id,
                            value: item.item_label,
                        });
                        i++;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        getAgeGroupList() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=13`).then(response => {
                    let i = 0;
                    for (let item of response.data.data) {
                        if(i == 0) {
                            this.selectableList.ageGroupList.push({
                                id: '',
                                value: 'Select Age Group',
                            });    
                        }
                        this.selectableList.ageGroupList.push({
                            id: item.id,
                            value: item.item_label,
                        });
                        i++;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        getTaxList() {
            try {
                axiosGet(`${SELECTABLE_TAX}`).then(response => {
                    for (let item of response.data.data) {
                        this.taxLists[item.id] = item.percentage;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        setTimeout(() => this.dropZoneBoot = true)
        // setting the category_id to be the on load,
        // this will be changed later on a new subcategory add to force reload the subcategory search and select input (to get the newly added sub category)
        this.componentKeys.subcategorySearchAndSelect = +this.formData.category_id;
        this.getGenderList();
        this.getAgeGroupList();
        this.getTaxList();
    },
    computed: {
        goToStockPage() {
            return urlGenerator(INVENTORY);
        },
        filteredVariantAttributes() {
            return this.selectableList.attributes.filter(attr => {
                const activeVariantAttrs = Object.keys(this.formData.variationChips);
                return activeVariantAttrs.includes(attr.name.toLowerCase());
            });
        },
    },
}
</script>

<style scoped>
.pe-none {
    pointer-events: none;
}

.temp-disable {
    opacity: 0.5;
    pointer-events: none;
}
</style>

