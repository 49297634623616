import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import { LISTS } from "../../../Config/ApiUrl-CPB";

export default {
    mixins: [DatatableHelperMixin],
    data() {

        return {
            options: {
                name: this.$t('tenant_groups'),
                url: LISTS,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('List Name'),
                        type: 'text',
                        key: 'list_name',
                    },
                    {
                        title: this.$t('Dropdown Items'),
                        type: 'expandable-column',
                        key: 'list_items',
                        className: 'secondary',
                        componentName: 'list-items-details',
                        showTitle: this.$t('show'),
                        hideTitle: this.$t('hide'),
                        showIcon: 'eye',
                        hideIcon: 'eye-off',
                        modifier: (value) => {
                            const listItemsLength = value.length;
                            return {
                                prefixData: `${listItemsLength === 0 ? '-' : listItemsLength}`,
                                title: this.$t('show'),
                                expandable: listItemsLength >= 1,
                                button: listItemsLength >= 1,
                                visible: listItemsLength >= 1,
                            };
                        }
                    },
                    {
                        title: this.$t('Module Name'),
                        type: 'custom-html',
                        key: 'module',
                        modifier: (value) => value?.module_name ? `<p>${value.module_name}</p>` : '-',
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "dropdown",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('update_customer_groups')
                    },
                    {
                        title: this.$t('Add List Items'),
                        icon: 'edit',
                        type: 'modal',
                        name: 'add_list_items',
                        component: 'app-list-items-modal',
                        modalId: 'dropdown-list-modal',
                        modifier: (value) => this.$can('update_customer_groups'),
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        icon: 'trash-2',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: LISTS,
                        name: 'delete',
                        modifier: () => this.$can('delete_customer_groups')

                    }
                ],
                filters: [
                    {
                        title: this.$t('created_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear", "lastYear"]
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    }
}
