import { UPDATE_STATUS } from "../../../../../Config/ApiUrl-CP";

export default {
    data() {
        return {
            options: {
                url: `${UPDATE_STATUS}/${this.orderId}`,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Delivery'),
                        type: 'component',
                        componentName: 'app-delivery-status',
                        key: 'delivery_status_id',
                        modifier: (deliveryStatus) => deliveryStatus && deliveryStatus
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'component',
                        componentName: 'app-payment-status',
                        key: 'payment_status_id',
                        modifier: (paymentStatus) => paymentStatus && paymentStatus
                    },
                    {
                        title: this.$t('Order status'),
                        type: 'component',
                        componentName: 'app-orders-status',
                        key: 'statusId',
                        modifier: (status) => status && status
                    },
                ],
                filters: [

                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "dropdown",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'edit',
                        component: 'branch-create-edit-modal',
                        modalId: 'branch-create-edit-modal',
                        modifier: row => {
                            return this.$can('update_branch')
                        },
                    },
                    {
                        title: this.$t('deactivate'),
                        type: 'status_change',
                        name: 'deactivate',
                        modifier: (value) => value.status.translated_name === 'Active',
                    },
                    {
                        title: this.$t('activate'),
                        type: 'status_change',
                        name: 'activate',
                        modifier: (value) => value.status.translated_name !== 'Active',
                    },
                    {
                        title: this.$t('manage_users'),
                        type: 'modal',
                        name: 'view_users',
                    }
                ],
            },
        }
    }
}
