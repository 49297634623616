<template>
  <modal id="user-invite" size="large" v-model="showModal" :title="$fieldTitle('add', 'user', true)" @submit="submit"
    :btn-label="$t('Add User')" :scrollable="false" :loading="loading" :preloader="preloader">
    <form ref="form" :data-url='submitURL' enctype="multipart/form-data">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <app-form-group type="email" :label="$t('email')" :placeholder="$placeholder('email')" v-model="formData.email"
            :required="true" :error-message="$errorMessage(errors, 'email')" />
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <app-form-group type="password" :label="$t('password')" :placeholder="$placeholder('password')"
            v-model="formData.password" :required="true" :error-message="$errorMessage(errors, 'password')" />
        </div>
        <div class="col-12 col-sm-2 col-md-2">
          <app-form-group type="select" :list="Prefix" list-value-field="name" :page="'modal'" :label="$t('Prefix')"
            :required="true" :error-message="$errorMessage(errors, 'prefix')" :placeholder="$placeholder('Prefix')"
            v-model="formData.prefix" />
        </div>
        <div class="col-12 col-sm-5 col-md-5">
          <app-form-group :label="$fieldTitle('first', 'name')" :placeholder="$placeholder('first', 'name')"
            v-model="formData.first_name" :required="true" :error-message="$errorMessage(errors, 'first_name')" />
        </div>
        <div class="col-12 col-sm-5 col-md-5">
          <app-form-group :label="$fieldTitle('last', 'name')" :placeholder="$placeholder('last', 'name')"
            v-model="formData.last_name" :required="true" :error-message="$errorMessage(errors, 'last_name')" />
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <app-form-group :label="$t('choose_a_role')" class="margin-right-8" type="search-and-select"
            :placeholder="$placeholder('choose_a_role')" :options="roleLists" v-model="role"
            :error-message="$errorMessage(errors, 'roles')" />
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <label>{{ $t('branch_or_warehouse') }}</label>
          <app-input type="multi-select" class="margin-right-8" :placeholder="$t('Select branch_or_warehouse')"
            :inputclearable="false" :list="branchesOrWarehousesOptions" v-model="formData.branch_or_warehouse_id"
            :error-message="$errorMessage(errors, 'branch_or_warehouse_id')" />
          <!-- 
          <app-form-group :label="$t('branch_or_warehouse')" class="margin-right-8" type="search-and-select"
            :placeholder="$placeholder('branch_or_warehouse')" :options="branchesOrWarehousesOptions"
            v-model="formData.branch_or_warehouse_id" :error-message="$errorMessage(errors, 'branch_or_warehouse_id')" /> -->
        </div>
        <div class="col-12 col-sm-12 col-md-12">
          <app-form-group type="number" :page="'modal'" :label="$t('Phone number')" :required="true"
            :error-message="$errorMessage(errors, 'phone_number')" :placeholder="$placeholder('Phone number')"
            v-model="formData.phone_number" />
        </div>

        <div class="col-12 col-sm-12 col-md-12">
          <label for="user-signature-input">{{ $t('signature') }}</label>
          <app-input v-if="imageUploaderBoot" id="user-signature-input" type="custom-file-upload" v-model="user_signature"
            :generate-file-url="false" :label="$t('change user signature')" :required="false"
            :error-message="$errorMessage(errors, 'user_signature')" />
          <!-- <app-form-group type="file" :page="'modal'" :label="$t('signature')" :required="true"
            :error-message="$errorMessage(errors, 'signature')" :placeholder="$placeholder('signature')"
            v-model="formData.signature" /> -->
        </div>

        <div class="col-12 col-sm-12 col-md-12">
          <app-form-group type="textarea" :text-area-rows="5" :page="'modal'" :label="$t('Designation')" :required="true"
            :error-message="$errorMessage(errors, 'designation')" :placeholder="$placeholder('Designation')"
            v-model="formData.designation" />
        </div>

        <div class="col-12 col-sm-12 col-md-12">
          <app-form-group :page="'modal'" :label="$t('Medical license number')" :required="true"
            :error-message="$errorMessage(errors, 'medical_license_number')"
            :placeholder="$placeholder('Medical license number')" v-model="formData.medical_license_number" />
        </div>

      </div>

    </form>
  </modal>
</template>

<script>
import FormHelperMixins from "../../../Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../Mixin/Global/ModalMixin";
import {
  MAIL_CHECK_URL,
  TENANT_USER_INVITE,
  DELIVERY_SETTINGS_FRONT_END,
  TENANT_MAIL_CHECK_URL,
  TENANT_DELIVERY_SETTINGS_FRONT_END,
  TENANT_ROLES,
} from
  "../../../Config/apiUrl";

import { axiosGet, urlGenerator } from "../../../Helper/AxiosHelper";
import { SELECTABLE_BRANCHES_OR_WAREHOUSES } from "../../../../tenant/Config/ApiUrl-CP";
import { formDataAssigner } from "../../../../common/Helper/Support/FormHelper";

export default {
  name: "UserInviteModal",
  mixins: [FormHelperMixins, ModalMixin],
  props: ['roles', 'alias'],
  data() {
    return {
      imageUploaderBoot: true,
      user_signature: null,
      userSignatureChanged: false,
      userSignaturePath: '',
      formData: {
        roles: [],
        branch_or_warehouse_id: [],
      },
      Prefix: [
        { id: '', name: this.$t('Select Prefix') },
        { id: 'Mr.', name: this.$t('Mr.') },
        { id: 'Ms.', name: this.$t('Ms.') },
        { id: 'Mrs.', name: this.$t('Mrs.') },
        { id: 'Dr.', name: this.$t('Dr.') },
      ],
      role: '',
      isMailSettingExist: false,
      roleLists: {
        url: urlGenerator(TENANT_ROLES),
        query_name: "search",
        per_page: 10,
        loader: "app-pre-loader",
        modifire: ({ id, name: value }) => ({ id, value }),
        prefetch: false,
      },
      branchesOrWarehousesOptions: [],
      settingsLocation: urlGenerator(this.alias === 'tenant' ? TENANT_DELIVERY_SETTINGS_FRONT_END : DELIVERY_SETTINGS_FRONT_END)
    }
  },
  watch: {
    user_signature(newSignature, oldSignature) {
      // if (!this.selectedUrl) return;
      this.userSignatureChanged = !this.userSignatureChanged;
      this.formData.user_signature = newSignature;
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.formData.roles = [this.role];

      // Convert branch_or_warehouse_id to an array if it's not already an array
      if (!Array.isArray(this.formData.branch_or_warehouse_id)) {
        this.formData.branch_or_warehouse_id = [this.formData.branch_or_warehouse_id];
      }

      const formData = new FormData();

      // Helper function to append a field to formData with a check for empty value
      function appendIfNotEmpty(fieldName, value) {
        if (value || value === 0) {
          formData.append(fieldName, value);
        } else {
          formData.append(fieldName, '');
        }
      }
      // Use forEach to handle arrays
      this.formData.branch_or_warehouse_id.forEach((item) => {
        formData.append("branch_or_warehouse_id[]", item);
      });
      // Use appendIfNotEmpty for each field in formData
      appendIfNotEmpty("prefix", this.formData.prefix);
      appendIfNotEmpty("first_name", this.formData.first_name);
      appendIfNotEmpty("last_name", this.formData.last_name);
      appendIfNotEmpty("phone_number", this.formData.phone_number);
      appendIfNotEmpty("designation", this.formData.designation);
      appendIfNotEmpty("medical_license_number", this.formData.medical_license_number);
      appendIfNotEmpty("email", this.formData.email);
      appendIfNotEmpty("password", this.formData.password);
      appendIfNotEmpty("roles", this.formData.roles);

      if (this.formData.user_signature) {
        formData.append("user_signature", this.formData.user_signature);
      }
      this.save(formData);
    },

    fetchBranchesOrWarehousesOptions() {
      axiosGet(SELECTABLE_BRANCHES_OR_WAREHOUSES).then(response => {
        console.log('response:', response.data.data);

        for (const item of response.data.data) {
          this.branchesOrWarehousesOptions.push({
            id: item.id,
            value: item.name,
          });
        }
      }).catch(error => {
        console.error(error);
      });
    },

    afterSuccess({ data }) {
      this.loading = false;
      this.toastAndReload(data.message, 'user-table');
      $("#user-invite").modal('hide')
    },

    afterSuccessFromGetEditData({ data }) {
      this.formData = data;
    },

    checkMailSettings() {
      this.preloader = true;
      const url = this.alias === 'tenant' ? TENANT_MAIL_CHECK_URL : MAIL_CHECK_URL
      axiosGet(url).then(response => {
        this.isMailSettingExist = !!response.data;
      }).finally(() => {
        this.preloader = false;
      });
    },
  },
  computed: {
    submitURL() {
      return this.alias === 'tenant' ? TENANT_USER_INVITE : `admin/auth/users/invite-user`;
    }
  },

  created() {
    this.checkMailSettings();
    this.fetchBranchesOrWarehousesOptions();
  }


}
</script>

