import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { CHIEF_COMPLAINT } from "../../Config/ApiUrl-CPB";
import {mapActions, mapGetters} from "vuex";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Chief Complaint Name'),
                        type: 'text',
                        key: 'chief_complaint_name',
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('update_chief_complaint'),
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        icon: 'trash-2',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: CHIEF_COMPLAINT,
                        name: 'delete',
                        modifier: () => this.$can('delete_chief_complaint')

                    }
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    methods: {
        ...mapActions(['setChiefComplaintByBranchOrWarehouseId']),
    },
    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(CHIEF_COMPLAINT);
    },
    watch: {
        getBranchOrWarehouseId: {
            immediate: false,
            handler() {
                this.updateUrl(CHIEF_COMPLAINT);
            }
        }
    },
}
