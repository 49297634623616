<template>
    <modal id="stock-location-create-edit-modal"
           modal-id="stock-location-create-edit-modal"
           v-model="showModal"
           :loading="loading"
           :scrollable="false"
           :preloader="preloader"
           size="large"
           :title="generateModalTitle('Location')"
           @close-modal="closeModal"
           @submit="submit">

        <form
            ref="form"
            class="row"
            :data-url='selectedUrl ? selectedUrl : ""'
            enctype="multipart/form-data">

            <!-- branch and/or warehouse input -->
            <div :class="`form-element col-12 col-md-6 mb-4 ${selectedUrl ? 'custom-disable' : ''}`">
                <label>{{ $t('branch_or_warehouse') }}</label>
                <app-input
                    class="margin-right-8"
                    type="search-and-select"
                    :placeholder="$placeholder('branch_or_warehouse')"
                    :inputclearable="false"
                    :options="branchesOrWarehousesOptions"
                    v-model="formData.branch_or_warehouse_id"
                    :error-message="$errorMessage(errors, 'branch_or_warehouse_id')"
                />
            </div>
            <hr>

            <div class="form-element col-12 col-md-6 mb-4 no-gutters">
                <label>{{ $t('Location') }}</label>
                <app-input
                    class=""
                    type="text"
                    :placeholder="$placeholder('Location')"
                    v-model="formData.location"
                    :error-message="$errorMessage(errors, 'location')"
                />
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {
    SELECTABLE_BRANCH, SELECTABLE_BRANCHES_OR_WAREHOUSES,
    STOCK_LOCATIONS
} from "../../../../Config/ApiUrl-CP";
import {formDataAssigner} from "../../../../Helper/Helper";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import {mapGetters} from "vuex";

export default {
    name: "StockLocationCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        itemToAdjust: {
            default: null,
        }
    },
    mounted() {
        this.formData.branch_or_warehouse_id = this.getBranchOrWarehouseId;
    },
    data() {
        return {
            branchInputKey: 0,
            formData: {
                branch_or_warehouse_id: '',
            },
            SELECTABLE_BRANCH,
            branchesOrWarehousesOptions: {
                url: urlGenerator(SELECTABLE_BRANCHES_OR_WAREHOUSES),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({id, name: value}) => ({id, value}),
            },
        }
    },
    watch: {
        'formData.branch_or_warehouse_id': {
            immediate: true,
            handler: function (newID) {
                if (this.selectedUrl) return;
                if (!newID) return;
            }
        }
    },
    computed: {
        ...mapGetters(["getBranchOrWarehouseId"]),
    },
    methods: {
        async submit() {
            // if (this.formData.branch_or_warehouse_id && !Boolean(this.formData.adjustmentVariants.length)) return this.$toastr.e('', this.$t('please_select_products_for_adjustments_first') + '!');
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.formData = {
                ...this.formData,
            };

            let formData = {...this.formData};
            const keysToIgnore = [];
            for (const key in formData) !formData[key] ? keysToIgnore.push(key) : '';


            formData = formDataAssigner(new FormData, {...this.formData, created_by: window.user.id}, keysToIgnore);
            if (this.selectedUrl) formData.append('_method', 'patch');

            this.submitFromFixin(
                'post',
                this.selectedUrl ? this.selectedUrl : STOCK_LOCATIONS,
                formData
            );
        },
        afterSuccess({data}) {
            // console.log("okk");
            // return false;
            this.formData = {};
            $('#stock-location-create-edit-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'stock-location-table');
        },

        // async afterSuccessFromGetEditData({data}) {
        //     this.preloader = false;
        //     this.formData.expense_date = data.expense_date ? new Date(data.expense_date) : '';
        //     this.formData = data;
        //     const adjustmentVariants = data.adjustment_variants;
        //     delete this.formData.adjustment_variants;
        //     this.formData.adjustmentVariants = adjustmentVariants;
        // },
        closeModal() {
            this.$emit('modal-close');
        }
    },
}
</script>

<style lang="sass" scoped>
.custom-disable
    opacity: 0.5
    pointer-events: none

</style>
