<template>
    <modal id="queue-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Update Work Status'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='UPDATE_QUEUE_STATUS' @submit.prevent="submitData">

            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <label for="formData_queueStatus" class="form-label">Work Status:</label>
                                <app-input type="select" :list="queueOptions" list-value-field="value"
                                        v-model="formData.workStatus" :required="true" :placeholder="$placeholder('Queue Status')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { UPDATE_QUEUE_STATUS } from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "QueueStatusModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        itemdata: {
            require: true
        },
    },
    data() {
        return {
            numberWithCurrencySymbol,
            UPDATE_QUEUE_STATUS,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                isEdit: false,
                queueid: this.itemdata.id,
                workStatus: this.itemdata.work_status_id
            },
            queueOptions: [],
        }
    },
    created() {
        this.queueStatusListOptions();
    },
    methods: {
        queueStatusListOptions() {
            axiosGet('app/selectable-statuses?type=workStatus').then(response => {
                for (const item of response.data.data) {
                    this.queueOptions.push({
                        id: item.id,
                        value: item.translated_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#queue-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.msg);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
}
</script>
