<template>
    <div>
        <span v-for="(item, index) in rowData.dropdown_lists" class="text-capitalize badge badge-pill badge-secondary ml-2">
            {{ item.list_name }} <span v-if="item.list_name.length > 1 && index !== rowData.dropdown_lists.length - 1">
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: "ListsValue",
    props: ['rowData', 'tableId', 'value'],
    data() {
        return {
            dropdown_lists: {}
        }
    }
}
</script>