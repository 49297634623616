<template>
   <div id="dropdown-items-details" class="w-90 mx-auto my-3">
      <div class="table-header d-flex align-items-center mb-4">
         <app-icon class="size-32 header-icon d-block mr-2" name="columns" />
         <p class="header-text pb-0 mb-0">
            {{ data.list_name }} {{ $t('has') }}
            {{ data.list_items.length }} {{ $t(`${data.list_items.length > 1 ? 'List Items' : 'List Item'}`) }}
         </p>
      </div>

      <div class="dropdown-items-display row">
         <app-list-items v-for="(listItem, i) in data.list_items" :key="i" :listItem="listItem" :tableId="tableId" />
      </div>
   </div>
</template>

<script>
export default {
   name: 'list-items-details',
   props: ['data', 'tableId'],
};
</script>

<style>
.w-90 {
   width: 90%;
}
</style>
