import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { LOG_REPORT } from "../../Config/ApiUrl-CPB";
import { urlGenerator} from "../../../common/Helper/AxiosHelper";
import { mapGetters } from "vuex";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                customerGroup: null,
                columns: [
                    {
                        title: this.$t('name'),
                        type: 'object',
                        key: 'customer',
                        modifier: value => value ? value.full_name : ''
                    },
                    {
                        title: "Data",
                        type: 'custom-html',
                        key: 'jsondata',
                        modifier: (value) => {
                            let html = '';
                            if(value != '') {
                                let jsonData = JSON.parse(value);
                                $.each(jsonData,function(i,v) {
                                    html += '<p><b>'+i+'</b> : '+v.old+' -> '+ v.new +'</p>';
                                });
                            }
                            return html;
                        }
                    },
                    {
                        title: "Created By",
                        type: 'object',
                        key: 'user',
                        modifier: value => value ? value.full_name : ''
                    },
                    {
                        title: 'Created At',
                        type: 'custom-html',
                        key: 'created_at',
                        modifier: created_at => formatDateToLocal(created_at, true)
                    },
                ],

                filters: [
                    {
                        title: this.$t('created_date'),
                        type: 'range-picker',
                        key: 'date',
                        option: ['today', 'last7Days', 'lastMonth', 'thisMonth']
                    },
                    {
                        title: this.$t('customers'),
                        type: 'search-and-select-filter',
                        key: "customer",
                        settings: {
                            url: urlGenerator('app/selectable-customers'),
                            modifire: ({id, full_name}) => ({id, full_name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search_by_first_or_last_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'full_name'
                    },
                    {
                        title: this.$t('created_by'),
                        type: 'search-and-select-filter',
                        key: "created_by",
                        settings: {
                            url: urlGenerator('app/selectable/users'),
                            modifire: ({id, full_name}) => ({id, full_name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search_by_first_or_last_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'full_name'
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    created() {},

    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(LOG_REPORT);
    },
    watch: {
        getBranchOrWarehouseId(){
            this.updateUrl(LOG_REPORT);
        },
    },
}