<template>
    <modal id="discount-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Edit Discount'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='UPDATE_ORDER_DISCOUNT+orderId' @submit.prevent="submitData">

            <div class="row">
                <div class="col-4">
                    <app-form-group
                        v-model="formData.grandTotal"
                        type="number"
                        class="mb-2"
                        :label="$t('Total')"
                        :required="false"
                        :disabled="true"
                        :error-message="$errorMessage(errors, 'total_value')"
                    />
                </div>
                <div class="col-4">
                    <app-form-group
                        v-model="formData.discountValue"
                        type="number"
                        class="mb-2"
                        :keyup="handleAmount()"
                        :label="$t('Discount')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'discount_value')"
                    />
                </div>
                <div class="col-4">
                    <label for="discounttype">Type</label>
                    <select name="discounttype" class="form-control" change="handleAmount()" v-model="formData.discountType" id="discounttype">
                        <option value="fixed">$</option>
                        <option value="percentage">%</option>
                    </select>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <div class="row text-right" style="font-size: 18px;font-weight: 500;">
                            <div class="col-6 px-0 py-3">Total Amount</div>
                            <div class="col-6 px-0 py-3">{{ numberWithCurrencySymbol(totalAmt) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {UPDATE_ORDER_DISCOUNT} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "DiscountActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            UPDATE_ORDER_DISCOUNT,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                isEdit: false,
                grandTotal: this.edititeminfo.sub_total??0,
                discountValue: this.edititeminfo.discount??0,
                discountType: (this.edititeminfo?.discount_type == 'percentage')?this.edititeminfo?.discount_type:'fixed',
            }
        }
    },
    methods: {
        handleAmount(){
            let totalAmt = this.formData.grandTotal;
            let productDiscount = this.formData.discountValue;
            let discountType = this.formData.discountType;
            if(discountType == 'fixed') {
                totalAmt = totalAmt - productDiscount;  
            } else {
                totalAmt = totalAmt - ((totalAmt*productDiscount)/100);
            }
            this.totalAmt = totalAmt;
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#discount-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
        // this.getPaymentDetails();
        // this.paymentListOptions();
    }
}
</script>
