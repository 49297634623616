<template>
    <modal id="payment-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Edit Payment'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='UPDATE_DUE_PAYMENT+paymentId' @submit.prevent="submitData">

            <div class="row">
                <div class="col-4">
                    <app-form-group
                        v-model="formData.paymentAmt"
                        type="number"
                        class="mb-2"
                        :label="$t('Amount')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'payment_amt')"
                    />
                </div>
                <div class="col-4">
                    <label for="paymentMode" class="form-label">Payment Mode:</label>
                    <app-input type="select" :list="paymentModeOptions" list-value-field="value"
                            v-model="formData.paymentMode" :required="false" :placeholder="$placeholder('Payment Mode')" />
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.paymentDate" :min-date="formData.pastDays" :max-date="formData.paymentDate"  :label="$t('Payment Date')"
                :placeholder="$placeholder($t('Payment Date'))" :readOnly="true" />
                    </div>
                </div>
                <app-input
                    type="textarea"
                    class="d-block col-12"
                    :text-area-rows="5"
                    :placeholder="$placeholder('note')"
                    v-model="formData.paymentNote"
                />
                </br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { SALES_VIEW_PRODUCTS, UPDATE_DUE_PAYMENT, SELECTABLE_DROPDOWN, DUE_PAYMENT_INFO_BY_ID} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "PaymentActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        paymentId: {
            require: true
        },
        edititeminfo:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            UPDATE_DUE_PAYMENT,
            showNote: true,
            loading: false,
            totalAmt: 0,
            paymentModeOptions: [],
            formData: {
                isEdit: false,
                paymentAmt: 0,
                paymentMode: 0,
                paymentDate: '',
                pastDays: '',
            }
        }
    },
    methods: {
        formatDate(dateObj) {
            if(dateObj != '' && dateObj != '0000-00-00') {
                dateObj = new Date(dateObj);
                const date = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate()}
                return `${date.year}-${date.month <= 9 ? '0' : ''}${date.month}-${date.day <= 9 ? '0' : ''}${date.day}`;
            }
            return '';
        },
        paymentListOptions() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=14`).then(response => {
                    this.formData.paymentMode = response.data.data[0].id;
                    for (let item of response.data.data) {
                        this.paymentModeOptions.push({
                            id: item.id,
                            value: item.item_label,
                        });
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        getPaymentDetails() {
            axiosGet(`${DUE_PAYMENT_INFO_BY_ID}/${this.paymentId}`).then(response => {
                this.formData.paymentAmt = response.data.paid_amount;
                this.formData.paymentNote = response.data.payment_note;
                this.formData.paymentDate = response.data.updated_at;
                this.formData.pastDays = response.data.past_days;
                this.formData.paymentMode = response.data.payment_mode;
            })
        },
        submitData() {
            this.formData.paymentDate = this.formatDate(this.formData.paymentDate);
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#payment-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
        this.getPaymentDetails();
        this.paymentListOptions();
    }
}
</script>
