<template>
    <section id="app-order-return" class="content-wrapper">

        <app-page-top-section :title="$t('referral_report')" icon="briefcase">
            <app-default-button
                @click="exportReferralSummary"
                :title="$fieldTitle('export', '', true)"/>
        </app-page-top-section>

        <div class="mb-3" v-html="filterHtml"></div>

        <app-table :id="table_id" :options="options" v-if="options.url" @action="triggerActions"></app-table>

        <app-referral-action-modal v-if="isReferralActionModalActive" :value="true" @modal-close="closeModal" :orders="orders" />

    </section>
</template>

<script>
import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import ReferralReportMixins from "./ReferralReportMixins";
import {SALES_REPORT_EXPORT, SALES_REPORT, SALES_SUMMARY} from "../../../../Config/ApiUrl-CP";
import {numberWithCurrencySymbol} from "../../../../Helper/Helper";
import {mapGetters} from "vuex";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name: 'ReferralReport',
    mixins: [DatatableHelperMixin, ReferralReportMixins],
    data() {
        return {
            table_id: 'referral-report-table',
            numberWithCurrencySymbol,
            exportLoading: false,
            isReferralActionModalActive: false,
            orders: [],
            branch_or_warehouse_id: this.getBranchOrWarehouseId,
        };
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('Edit')) {
                this.isReferralActionModalActive = true;
                this.orders = row;
            } else {
                this.getAction(row, action, active)
            }
        },
        closeModal() {
            this.isReferralActionModalActive = false;
            this.$emit('modal-close');
        },
        exportReferralSummary() {
            window.open(urlGenerator(`${SALES_REPORT_EXPORT}/?from=referral&branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.branch_or_warehouse_id = new_id
        },
    }
};
</script>
