<template>
    <modal id="doctors-modal" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('doctors')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : DOCTORS' @submit.prevent="submitData">
            <div class="form-group required">
                <label for="full-name">Full Name</label>
                <input type="text" id="full-name" v-model="formData.full_name" placeholder="Full Name" class="form-control"
                    required />
            </div>
            <div class="opening-balance-input align-items-baseline mb-3">
                <label for="specialisationInput"> {{ $t('Select Specialisation') }} </label>
                <template v-if="formData.specialisation_id === null">
                    <app-input type="search-and-select" :placeholder="$placeholder('specialisation')"
                        :inputclearable="false" :key="specialisationInputKey" :options="specialisationOptions"
                        v-model="formData.specialisation_id" />
                </template>
                <template v-else>
                    <app-input type="search-and-select" :placeholder="$placeholder('specialisation')"
                        :inputclearable="false" :key="specialisationInputKey" :options="specialisationOptions"
                        v-if="formData.specialisation_id" v-model="formData.specialisation_id" />
                </template>
            </div>
            <!-- <div class="form-group required">
                <app-form-group type="select" :list="specialisation" list-value-field="name" :page="'modal'"
                    :label="$t('specialisation')" :required="true" :error-message="$errorMessage(errors, 'specialisation')"
                    :placeholder="$placeholder('specialisation')" v-model="formData.specialisation" />
            </div> -->
            <div class="form-group required">
                <label for="mobileNo">Mobile No.</label>
                <input type="text" id="mobileNo" v-model="formData.mobile_no" placeholder="Mobile No." class="form-control"
                    required />
            </div>
            <div class="form-group">
                <label for="commissionPercentage">Commission %</label>
                <input type="text" id="commissionPercentage" v-model="formData.commission_percentage"
                    placeholder="Commission %" class="form-control" />
            </div>
            <div class="form-group">
                <label for="address">Address</label>
                <textarea id="address" v-model="formData.address" placeholder="Address" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label for="city">City</label>
                <input type="text" id="city" v-model="formData.city" placeholder="City" class="form-control" />
            </div>
            <div class="form-group">
                <label for="payment-mode">Payment Mode</label>
                <input type="text" id="payment-mode" v-model="formData.payment_mode" placeholder="Payment Mode"
                    class="form-control" />
            </div>
            <div class="opening-balance-input align-items-baseline">
                <label for="branchInput"> {{ $t('select_branch') }} </label>
                <app-input type="multi-select" :placeholder="$t('Select Branch')" :inputclearable="false" :list="branchList"
                    v-model="formData.branch_id" :error-message="$errorMessage(errors, 'branch_id')" />
                <!-- <template v-if="formData.branch_id === null">
                    <app-input type="search-and-select" :placeholder="$placeholder('branch')" :inputclearable="false"
                        :key="branchOrWarehouseInputKey" :options="branchOptions" v-model="formData.branch_id" />
                </template>
                <template v-else>
                    <app-input type="search-and-select" :placeholder="$placeholder('branch')" :inputclearable="false"
                        :key="branchOrWarehouseInputKey" :options="branchOptions" v-if="formData.branch_id"
                        v-model="formData.branch_id" />
                </template> -->
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { DOCTORS, SELECTABLE_BRANCHES, SELECTABLE_SPECIALISATION } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { axiosGet, urlGenerator } from "../../../../common/Helper/AxiosHelper";
import store from "../../../../store/Index";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'DoctorCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            DOCTORS,
            doctors: [],
            branchOrWarehouseInputKey: 1,
            specialisationInputKey: 1,
            branchList: [],
            specialisationOptions: {
                url: urlGenerator(SELECTABLE_SPECIALISATION),
                query_name: "search_by_name",
                per_page: 50,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.item_label }),
                // modifire: ({ id, item_label: value }) => ({ id, value }),
            },
            specialisation: [
                { id: '', name: this.$t('Select Specialisation') },
                { id: 'Orthopaedic', name: this.$t('Orthopaedic') },
                { id: 'Diabetologist', name: this.$t('Diabetologist') },
                { id: 'Physiotherapist', name: this.$t('Physiotherapist') },
                { id: 'Neurologist', name: this.$t('Neurologist') },
                { id: 'General Physician', name: this.$t('General Physician') },
                { id: 'Fitness Coach', name: this.$t('Fitness Coach') },
                { id: 'Alternative', name: this.$t('Alternative') },
                { id: 'Other', name: this.$t('Other') },
            ],
            formData: {
                full_name: "",
                mobile_no: "",
                commission_percentage: "",
                address: "",
                city: "",
                payment_mode: "",
                specialisation_id: null,
            },
        };
    },
    created() {
        this.fetchDoctors();
        this.fetchBranchList();
    },
    watch: {
        'formData.branch_id'() {
            if (this.getNewCashRegisterId) return;
            this.formData.id = '';
            this.cashRegisterInputKey++;
        },
        getBranchOrWarehouseId: {
            immediate: true,
            handler(newId) {
                this.formData.branch_id = eval(newId);
                this.branchOrWarehouseInputKey++;
            }
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId', 'getNewCashRegisterId']),

        selectedBranchId() {
            return store.state.auth.branch_id;
        }
    },
    methods: {
        fetchBranchList() {
            axiosGet(`app/selectable/branches`).then(response => {
                for (const item of response.data.data) {
                    this.branchList.push({
                        id: item.id,
                        value: item.name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchDoctors() {
            try {
                const response = axiosGet(`${DOCTORS}`);
                this.doctors = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            // this.formData.branch_id = this.formData.branch_id;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#doctors-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'doctors-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>