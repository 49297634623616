<template>
    <app-modal
        modal-id="tech-order-details-modal"
        modal-size="large"
        modal-alignment="top"
        @close-modal="closeModal"
    >
        <!-- Modal header -->
        <template slot="header">
            <div class="w-100 text-center">
                <h3 class="modal-heading">{{ (extraval == 'new')? 'New Orders' : 'Mod Orders'}} - {{ rowData?.full_name }}</h3>
            </div>
        </template>

        <!-- Modal body -->
        <template slot="body">

            <div class="w-100">
                <table class="table table-bordered" width="100%">
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th>Order No.</th>
                            <th>Patient Name</th>
                            <th>Product Name</th>
                            <th>Product Sub-Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="filteredOrderTechnicians.length > 0">
                            <template v-for="(item, index) in filteredOrderTechnicians" >
                            <tr :key="item.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.order.invoice_number }}</td>
                                <td>{{ item.order.customer.full_name }}</td>
                                <td>{{ item.orderproduct.variant.name }}</td>
                                <td>{{ item.orderproduct.variant.product?.category?.name ?? '-' }}</td>
                            </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            
        </template>

        <!-- Modal Footer -->
        <template slot="footer" class="justify-content-start">
            <app-cancel-button btn-class="btn-secondary" :label="$t('close')" @click="closeModal"/>
        </template>
    </app-modal>
</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name: "techOrderDetails",
    mixins: [FormHelperMixins],
    props: {
        rowData: {
            require: true
        },
        extraval: {
            require: true
        },
        rowSingle: {
            require: true
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        filteredOrderTechnicians() {
            return this.rowSingle.filter(item => {
                if (this.extraval === 'new' && item.new_order) {
                    item.order = item.new_order;
                    return true;
                } else if (this.extraval === 'mod' && item.mod_order) {
                    item.order = item.mod_order;
                    return true;
                }
                return false;
            });
        }
    },
    methods: {
        closeModal() {
            $('#tech-order-details-modal').modal('hide')
            this.$emit('modal-close');
        },
    }
}
</script>