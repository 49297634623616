<template>
    <modal id="cpprice-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Update CP Price'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='PRODUCT_SAVE_CP_PRICE' @submit.prevent="submitData">

            <div class="row">
                <div class="col-4">
                    <app-form-group
                        v-model="formData.cp_base_price"
                        type="number"
                        class="mb-2"
                        :label="$t('CP Base Price')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'cp_base_price')"
                    />
                </div>
                <div class="col-4">
                    <app-form-group
                        v-model="formData.cp_gst"
                        type="number"
                        class="mb-2"
                        :label="$t('CP GST')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'cp_gst')"
                    />
                </div>
                <div class="col-4">
                    <app-form-group
                        v-model="formData.cp_total_price"
                        type="number"
                        class="mb-2"
                        :label="$t('CP Total Price')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'cp_total_price')"
                    />
                </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { PRODUCT_SAVE_CP_PRICE } from "../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../Helper/Helper";
import { mapGetters } from "vuex";

export default {
    name: "CpPriceAction",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        itemData:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            PRODUCT_SAVE_CP_PRICE,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                isEdit: false,
                varid: this.itemData.id??0,
                branchid: this.getBranchOrWarehouseId,
                cp_base_price: this.itemData.cp_prices?.cp_base_price??0,
                cp_gst: this.itemData.cp_prices?.cp_gst??0,
                cp_total_price: this.itemData.cp_prices?.cp_total_price??0,
            }
        }
    },
    computed: {
      ...mapGetters(['getBranchOrWarehouseId']),
    },
    methods: {
        submitData() {
            this.formData.branchid = this.getBranchOrWarehouseId;
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#cpprice-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.msg);
            setTimeout(() => {
                // window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
    }
}
</script>