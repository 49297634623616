import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import { GENERATE_INVOICE_VIEW,GENERATE_PERFOMA_INVOICE_VIEW, INVOICE_LIST, INVOICE_VIEW, ORDER_MAX_MIN_PRICE } from "../../../../Config/ApiUrl-CP";
import { axiosGet, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import { mapGetters } from "vuex";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";


export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            options: {
                name: this.$t('invoice'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Sr No.'),
                        type: 'object',
                        key: 'sr_no',
                        modifier: (value) => value 
                    },
                    {
                        title: this.$t('Order No.'),
                        type: 'component',
                        key: 'invoice_number',
                        componentName: 'app-orders-number'
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                    {
                        title: this.$t('Invoice No.'),
                        type: 'custom-html',
                        key: 'invoice_gen_number',
                        modifier: (invoice_gen_number,row) => invoice_gen_number ? `<a href="${urlGenerator(GENERATE_INVOICE_VIEW+row.id)}" class="redirectTo">${invoice_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Invoice Amount'),
                        type: 'custom-html',
                        key: 'grand_total',
                        isVisible: true,
                        modifier: value => `<p class="text-right mb-0">${numberWithCurrencySymbol(value)}</p>`
                    },
                    {
                        title: this.$t('Proforma No.'),
                        type: 'custom-html',
                        key: 'perfoma_gen_number',
                        modifier: (perfoma_gen_number,row) => perfoma_gen_number ? `<a href="${urlGenerator(GENERATE_PERFOMA_INVOICE_VIEW+row.id)}" class="redirectTo">${perfoma_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Patient Name'),
                        type: 'custom-html',
                        key: 'customer',
                        modifier: (row) => row.full_name ? `<a href="${urlGenerator('customer/view/'+row.id)}" class="redirectTo">${row.full_name}</a>` :'-',
                    },
                    {
                        title: this.$t('Branch Name'),
                        type: 'custom-html',
                        key: 'branch_or_warehouse',
                        modifier: (row) => row.name ? `<p class="">${row.name}</p>` :'-',
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'custom-html',
                        key: 'customer',
                        modifier: (row) => row.users?.full_name ? `<p class="">${row.users?.full_name}</p>` :'-',
                    },
                    {
                        title: this.$t('Type'),
                        type: 'custom-html',
                        key: 'ref_orderid',
                        modifier: (value)=> value != ""?"Mod":'New',
                    },
                    {
                        title: this.$t('Order Status'),
                        type: 'custom-html',
                        key: 'status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'custom-html',
                        key: 'payment_status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Delivery'),
                        type: 'custom-html',
                        key: 'order_products',
                        isVisible: true,
                        modifier: (value) => {
                            if(value.length > 0) {
                                let row = value[value.length - 1];
                                return `<span class="badge badge-pill badge-${row.delivery_status?.class}">${row.delivery_status?.translated_name}</span>`;
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        title: this.$t('Branch/Workshop'),
                        type: 'custom-html',
                        key: 'order_products',
                        modifier: (items) => {
                            let htmlString = '';
                            for (let i = 0; i < items.length; i++) {
                                if(this.shouldDisplayCustomOrders(items[i])) {
                                    if (items[i].stock_deduction_type === 2) {
                                        htmlString = '<p class="">Workshop</p>';
                                        break;
                                    } else {
                                        htmlString = '<p class="">Branch</p>';
                                        break;
                                    }
                                } else {
                                    htmlString = '<p class="">Branch</p>';
                                    break;
                                }
                            }
                            return htmlString;
                        }
                    },
                    // {
                    //     title: this.$t('Warehouse Status'),
                    //     type: 'custom-html',
                    //     key: 'warehouse_status',
                    //     isVisible: true,
                    //     modifier: (value) => {
                    //         return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                    //     }
                    // },
                ],
                actionType: "dropdown",
                actions: [
                    {

                        title: this.$t('view'),
                        type: 'view'
                    },
                    {
                        title: this.$t('Edit'),
                        type: 'edit'
                    },
                    {
                        title: this.$t('Print Invoice'),
                        type: 'download_invoice',
                        modifier: () => this.$can('manage_generate_sales_invoice')
                    },
                    {
                        title: this.$t('Print Proforma Invoice'),
                        type: 'download_perfoma_invoice',
                        modifier: () => this.$can('manage_generate_proforma_invoice')
                    },
                    {
                        title: this.$t('Print Order Confirmation'),
                        type: 'print_order_confirmation',
                        modifier: () => this.$can('manage_generate_order_confirmation')
                    },
                    {
                        title: this.$t('Print Order Form'),
                        type: 'print_order_form',
                        modifier: () => this.$can('manage_generate_order_form')
                    },
                    {
                        title: this.$t('due_receive'),
                        type: 'due_receive',
                        modifier: (row) => {
                            if(this.$can('orders_due_payment')) {
                                return row.due_amount > 0 ? true : false
                            } else {
                                return false;
                            }
                        }
                    },
                    {
                        title: this.$t('Reverse Payment'),
                        type: 'reverse_payment',
                        modifier: () => this.$can('orders_reverse_payment')
                    },
                ],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    {
                        title: this.$t('customer'),
                        type: 'search-and-select-filter',
                        key: 'customers',
                        settings: {
                            url: urlGenerator('app/selectable-customers'),
                            modifire: ({ id, full_name }) => ({ id, full_name }),
                            params: {},
                            per_page: 10,
                            queryName: 'search',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'full_name'
                    },
                    {
                        title: "Price Range",
                        type: "range-filter",
                        key: "range_filter",
                        maxTitle: "Max usd",
                        minTitle: "Min usd",
                        sign: "₹",
                        minRange: 0,
                        maxRange: 0,
                    },
                    {
                        title: this.$t('Technician'),
                        type: 'search-and-select-filter',
                        key: 'technicianid',
                        settings: {
                            url: urlGenerator('app/selectable/technician'),
                            modifire: ({ id, technician_name }) => ({ id, technician_name }),
                            params: {},
                            per_page: 10,
                            queryName: 'search',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'technician_name'
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'search-and-select-filter',
                        key: 'payment_status',
                        settings: {
                            url: urlGenerator("app/selectable-statuses"),
                            modifire: (v) => {
                                return {id: v.id, name: v.translated_name}
                            },
                            params: {
                                type: 'paymentStatus',
                            },
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'name'
                    },
                    {
                        title: "Order Status",
                        type: "multi-select-filter",
                        key: "order_status_ids",
                        option: [],
                        listValueField: 'name'
                    },
                    {
                        title: this.$t('All Branchs'),
                        type: 'checkbox',
                        key: 'all_branch',
                        option: [
                            { id: 'Yes', value: this.$t('Yes') },
                            { id: 'No', value: this.$t('No') },
                        ],
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ''
        }
    },
    methods: {
        getStatusFilterOptions(statusType, listValueField = 'name') {
            return {
                title: this.$t('Order Status'),
                type: 'multi-select-filter',
                key: 'status_id',
                settings: {
                    url: urlGenerator("app/selectable-statuses"),
                    modifire: (v) => {
                        return {id: v.id, name: v.translated_name}
                    },
                    params: {
                        type: statusType,
                    },
                    per_page: 10,
                    queryName: 'search_by_name',
                    loader: 'app-pre-loader'
                },
                listValueField
            }
        },
        shouldDisplayCustomOrders(item) {
            const allowedCategoryIds = [1, 2, 3, 4, 5];
            return item.variant.product.main_category == 3 && allowedCategoryIds.includes(item.variant.product.category_id);
        },
        getMaxMinOrderPrice() {
            axiosGet(this.orderMaxMinPriceUrl).then((res) => {
                this.options?.filters.find(({ key }) => {
                    if (key === 'range_filter') {
                        Object.assign(this.options.filters[2], res.data)
                    }
                })
            })
        },

        updateOrderMaxMinPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        filterInitiate() {
            this.getOrderStatus();
        },
        getOrderStatus() {
            axiosGet("app/selectable-statuses?type=orderStatus").then((response) => {
                let name = this.options.filters.find(element => element.key === 'order_status_ids');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        name: name.translated_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(INVOICE_LIST, this.updateOrderMaxMinPriceUrl);
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
    },
    watch: {
        getBranchOrWarehouseId() {
            this.updateUrl(INVOICE_LIST, this.updateOrderMaxMinPriceUrl);
            this.getMaxMinOrderPrice();
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.filterInitiate();
    },
}
