<template>
    <modal id="technician-modal" v-model="showModal" :loading="loading" :preloader="preloader" :title="generateModalTitle('technician')"
        @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : TECHNICIANS' @submit.prevent="submitData">
            <div class="mb-3 required">
                <label for="technicianName" class="form-label">Technician Name:</label>
                <input type="text" id="technicianName" class="form-control" v-model="formData.technician_name" placeholder="Technician Name"
                    required />
            </div>
            <div class="mb-3 required">
                <label for="branchInput"> {{ $t('select_branch') }} </label>
                <template v-if="formData.branch_id === null">
                    <app-input type="search-and-select" :placeholder="$placeholder('branch')" :inputclearable="false"
                        :key="branchOrWarehouseInputKey" :options="branchOptions" v-model="formData.branch_id" />
                </template>
                <template v-else>
                    <app-input type="search-and-select" :placeholder="$placeholder('branch')" :inputclearable="false"
                        :key="branchOrWarehouseInputKey" :options="branchOptions" v-if="formData.branch_id"
                        v-model="formData.branch_id" />
                </template>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { TECHNICIANS, BRANCHES_OR_WAREHOUSES } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete, urlGenerator } from "../../../../common/Helper/AxiosHelper";
import store from "../../../../store/Index";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'TechnicianCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            branchOrWarehouseInputKey: 1,
            branchOptions: {
                url: urlGenerator(BRANCHES_OR_WAREHOUSES),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
            },
            TECHNICIANS,
            technicians: [],
            formData: {
                technician_name: '',
                branch_id: '',
            },
        };
    },
    created() {
        this.fetchTechnicians();
    },
    watch: {
        'formData.branch_id'() {
            if (this.getNewCashRegisterId) return;
            this.formData.id = '';
            this.cashRegisterInputKey++;
        },
        getBranchOrWarehouseId: {
            immediate: true,
            handler(newId) {
                this.formData.branch_id = eval(newId);
                this.branchOrWarehouseInputKey++;
            }
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId', 'getNewCashRegisterId']),

        selectedBranchId() {
            return store.state.auth.branch_id;
        }
    },
    methods: {
        fetchTechnicians() {
            try {
                const response = axiosGet(`${TECHNICIANS}`);
                this.technicians = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            // this.formData.branch_id = this.selectedBranchId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#technician-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'technician-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>