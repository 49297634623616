<template>
    <modal id="list-items-modal" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('dropdown_list')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : LISTS_ITEMS' @submit.prevent="submitData">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <div class="form-group">
                        <label for="list_id">List</label>
                        <select name="list_id" id="list_id" class="form-control" required v-model="formData.list_id">
                            <option v-for="option in lists" :key="option.id" :value="option.id">
                                {{ option.list_name }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'list_id')">{{ $errorMessage(errors,
                            'list_id')
                        }}</small>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <div class="form-group">
                        <label for="item_label">Item Label</label>
                        <input type="text" v-model="formData.item_label" name="item_label" id="item_label"
                            class="form-control" placeholder="Enter Item Label..." required>
                        <small class="text-danger" v-if="$errorMessage(errors, 'item_label')">{{ $errorMessage(errors,
                            'item_label')
                        }}</small>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { LISTS_ITEMS } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../common/Helper/AxiosHelper";
import store from "../../../../store/Index";

export default {
    name: 'ListItemsCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    props: ['listId'],
    data() {
        return {
            LISTS_ITEMS,
            lists: [],
            formData: {
                list_id: !this.selectedUrl ? this.listId : '',
            },
        };
    },
    created() {
        this.fetchLists();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        fetchLists() {
            try {
                axiosGet(`${LISTS_ITEMS}`).then((response) => {
                    console.log(response.data.data);
                    this.lists = response.data.data;
                    // this.formData.list_id = this.lists[0].id;
                }).catch((error) => {
                    this.$toastr.e(error);
                })
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#list-items-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'dropdown-list-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>