<template>
    <div key>
        <!--summary report -->
        <div class="d-flex justify-content-between container-fluid row mt-3">
            <div class="w-100 mb-primary col-md-2 text-white bg-primary shadow rounded d-flex align-items-center p-4">
                <div class="ml-3">
                    <h5 class="mb-0">{{ occupancy }} working days</h5>
                    <div class="flex-shrink-0">
                        {{ $t('Occupancy') }}
                    </div>
                </div>
            </div>

            <div
                class="w-100 mb-primary mx-2 col-md-2 shadow rounded bg-primary text-white d-flex align-items-center p-4">
                <div class="ml-3">
                    <h5 class="mb-0">{{ newOrder }}</h5>
                    <div class="flex-shrink-0">
                        {{ $t('Total New Orders') }}
                    </div>
                </div>
            </div>
            <div class="w-100 mb-primary col-md-2  shadow rounded d-flex bg-primary text-white align-items-center p-4">
                <div class="ml-3">
                    <h5 class="mb-0">{{ modOrder }}</h5>
                    <div class="flex-shrink-0">
                        {{ $t('Total Mod Orders') }}
                    </div>
                </div>
            </div>
            <div class="w-100 mb-primary col-md-2 shadow rounded d-flex bg-primary text-white align-items-center p-4">
                <div class="ml-3">
                    <h5 class="mb-0">{{ newPendingOrder }}</h5>
                    <div class="flex-shrink-0">
                        {{ $t('Total Pending New Orders') }}
                    </div>
                </div>
            </div>
            <div class="w-100 mb-primary col-md-2 shadow rounded d-flex bg-primary text-white align-items-center p-4">
                <div class="ml-3">
                    <h5 class="mb-0">{{ modPendingOrder }}</h5>
                    <div class="flex-shrink-0">
                        {{ $t('Total Pending Mod Orders') }}
                    </div>
                </div>
            </div>
        </div>
        <!--    end summary report-->
    </div>

</template>

<script>

import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import { axiosGet } from "../../../../common/Helper/AxiosHelper";
import { ACTIVE_QUEUE_SUMMARY } from "../../../Config/ApiUrl-CP";
import { mapGetters } from "vuex";

export default {
    name: "ActiveQueueSummary",
    mixins: [HelperMixin],
    data() {
        return {
            location: window.location,
            options: {
                url: ACTIVE_QUEUE_SUMMARY,
                query: ''
            },
            table_id: 'active-summary',
            occupancy: 0,
            newOrder: 0,
            modOrder: 0,
            newPendingOrder: 0,
            modPendingOrder: 0,
            branch_or_warehouse_id: '',
        }
    },
    methods: {
        activeSummary() {
            axiosGet(`${this.options.url}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}&${this.options.query}`).then((response) => {
                this.occupancy = response.data.occupancy
                this.newOrder = response.data.newOrder
                this.modOrder = response.data.modOrder
                this.newPendingOrder = response.data.newPendingOrder
                this.modPendingOrder = response.data.modPendingOrder
            })
        },
        updateUrl() {
            this.options.url = `${ACTIVE_QUEUE_SUMMARY}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
            this.activeSummary();

            //set current branch_or_warehouse_id
            this.branch_or_warehouse_id = this.getBranchOrWarehouseId;
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },

    mounted() {
        this.$hub.$on('filter-change', (newFilterValues) => {
            let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
            const urlParams = new URLSearchParams({
                ...filterData,
                date: filterData.date ? JSON.stringify(filterData.date) : '',
                range_filter: filterData.range_filter ? JSON.stringify(filterData.range_filter) : ''
            });
            // urlParams.append('branch_or_warehouse_id', branchid)
            this.options.query = `${urlParams}`
            this.activeSummary();
        });
    },

    watch: {
        getBranchOrWarehouseId(new_id) {
            this.activeSummary();
        }
    }
}
</script>