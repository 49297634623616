<template>
    <modal id="chief-complaint-modal" v-model="showModal" :loading="loading" :preloader="preloader" :title="generateModalTitle('chief_complaint')"
        @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CHIEF_COMPLAINT' @submit.prevent="submitData">
            <div class="mb-3 required">
                <label for="chief-complaint-name" class="form-label">Chief complaint Name:</label>
                <input type="text" id="chief-complaint-name" class="form-control" v-model="formData.chief_complaint_name" placeholder="Chief complaint Name"
                    required />
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { CHIEF_COMPLAINT } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../common/Helper/AxiosHelper";
import store from "../../../../store/Index";

export default {
    name: 'ChiefComplaintCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            CHIEF_COMPLAINT,
            chief_complaints: [],
            formData: {
                chief_complaint_name: '',
            },
        };
    },
    created() {
        this.fetchCamps();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        fetchCamps() {
            try {
                const response = axiosGet(`${CHIEF_COMPLAINT}`);
                this.chief_complaints = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_or_warehouse_id  = this.selectedBranchId;
            // console.log(this.formData.branch_or_warehouse_id );
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#chief-complaint-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'chief-complaint-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>