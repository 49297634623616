<template>
    <div>
        <app-table :id="table_id" :options="options" @action="triggerActions"></app-table>
        <app-confirmation-modal
            v-if="Boolean(updateConfirm)"
            icon="alert-circle"
            modal-id="app-confirmation-modal"
            :message="$t('are_you_sure_you_want_to_update_the_referredby')"
            @cancelled="cancelReferralChange"
            @confirmed="updateReferral"
        />
    </div>
</template>
<script>
import { axiosPost, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import { UPDATE_PATIENT_ORDER_REFERRAL } from "../../../../Config/ApiUrl-CP";
import OrderListMixin from "../../../Mixins/OrderListMixin";
export default {
    name: "OrderList",
    props: {
        props: {

        }
    },
    mixins: [HelperMixin, OrderListMixin],
    data() {
        return {
            table_id: 'user-customer-order-table',
            updateConfirm: false,
            tempOrdId: 0,
        };
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('Update Referral')) {
                this.tempOrdId = row.id;
                this.updateConfirm = true;
            }
        },
        cancelReferralChange() {
            this.tempOrdId = 0;
            this.updateConfirm = false;
        },
        updateReferral() {
            if(this.tempOrdId != 0) {
                axiosPost(UPDATE_PATIENT_ORDER_REFERRAL+'/'+this.tempOrdId).then(response => {
                    if(response.data.status) {
                        this.toastAndReload(response.data.msg);
                    } else {
                        this.$toastr.e(response.data.msg)
                    }
                    setTimeout(() => {
                        window.location.href = urlGenerator(`customer/details/${this.props[0]}?tab=Orders`);
                    }, 1000);
                }).catch(error => {
                    console.error(error);
                });
            }
        },
    },
}
</script>