import { render, staticRenderFns } from "./BrandModal.vue?vue&type=template&id=55a5a694&"
import script from "./BrandModal.vue?vue&type=script&lang=js&"
export * from "./BrandModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports