import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { CUSTOMERS, SELECTABLE_CUSTOMER_GROUP } from "../../Config/ApiUrl-CPB";
import { ADDRESS_AREA, ADDRESS_CITY, SELECTABLE_PRACTITIONER } from "../../Config/ApiUrl-CP";
import { axiosGet, urlGenerator } from "../../../common/Helper/AxiosHelper";
import { ucWords } from "../../../common/Helper/Support/TextHelper";
import { mapActions, mapGetters } from "vuex";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                customerGroup: null,
                columns: [
                    // {
                    //     title: this.$t('image'),
                    //     type: 'component',
                    //     key: 'profile_picture',
                    //     componentName: 'app-customer-image'
                    // },
                    {
                        title: this.$t('Reg ID'),
                        type: 'custom-html',
                        key: 'regid',
                        modifier: (value) =>
                            `<p class="pb-0 mb-0">${value}</p>`
                    },
                    {
                        title: this.$t('name'),
                        type: 'component',
                        key: 'full_name',
                        componentName: 'app-customer-action'

                    },
                    // {
                    //     title: this.$t('customer_group'),
                    //     type: 'custom-html',
                    //     key: 'customer_group',
                    //     modifier: (value) => value.name ? `<small class="text-capitalize bg-success px-3 py-1 text-white" style="border-radius: 8rem; width: 5rem;">${value.name}</small>` : '-',
                    // },
                    // {
                    //     title: this.$t('email_s'),
                    //     type: 'component',
                    //     key: 'emails',
                    //     componentName: 'app-customer-contact'
                    // },
                    // {
                    //     title: this.$t('phone_number_s'),
                    //     type: 'component',
                    //     key: 'phone_numbers',
                    //     componentName: 'app-customer-contact'
                    // },
                    {
                        title: this.$t('Primary complaint'),
                        type: 'custom-html',
                        key: 'chief_complaint',
                        modifier: (value) => value?.chief_complaint_name ? `<p>${value.chief_complaint_name}</p>` : '-',
                    },
                    {
                        title: this.$t('Reg date'),
                        type: 'custom-html',
                        key: 'registration_date',
                        modifier: (value) => {
                            if (value === null) {
                                return '<p class="pb-0 mb-0">-</p>';
                            }
                            let dateArr = value.split('-');
                            return `<p class="pb-0 mb-0">${dateArr[2]}-${dateArr[1]}-${dateArr[0]}</p>`;
                        }
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'custom-html',
                        key: 'users',
                        modifier: (value) => value?.full_name ? `<p>${value.full_name}</p>` : '-',

                    },
                    {
                        title: this.$t('Branch'),
                        type: 'custom-html',
                        key: 'branch',
                        modifier: (value) => value?.name ? `<p>${value.name}</p>` : '-',

                    },
                    {
                        title: this.$t('Placed order?'),
                        type: 'custom-html',
                        key: 'orders',
                        modifier: (value, row) => {
                            if (Array.isArray(value) && value.length > 0) {
                                return '<span class="text-success font-weight-bold">Yes</span>';
                            } else {
                                return '<span class="text-danger font-weight-bold">No</span>';
                            }
                        }
                    },
                    {
                        title: this.$t('Satisfaction status'),
                        type: 'custom-html',
                        key: 'satisfaction_status',
                        modifier: (value) => value ?
                            `<span class="text-capitalize px-3 py-1 text-${value === 'Yes' ? 'success' : value === 'No' ? 'danger' : 'muted'} font-weight-bold" style="border-radius: 8rem;">${value}</span>` : '-',
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'custom-html',
                        key: 'orders',
                        modifier: (value) => {
                            if (Array.isArray(value) && value.length > 0) {
                                let paymentPartial = false;
                                let paymentPending = false;
                                for (let v = 0; v < value.length; v++) {
                                    if(value[v]['payment_status_id'] == 59) {
                                        paymentPartial = true;
                                        break;
                                    }
                                    if(!paymentPending) {
                                        if(value[v]['payment_status_id'] == 58) {
                                            paymentPending = true;
                                        }
                                    }
                                }
                                if(paymentPartial) {
                                    return '<span class="font-weight-bold text-warning">Partial</span>';
                                } else if(paymentPending) {
                                    return '<span class="font-weight-bold text-danger">Pending</span>';
                                } else {
                                    return '<span class="font-weight-bold text-success">Completed</span>';   
                                }
                            } else {
                                return '<span class="font-weight-bold text-muted">N/A</span>';
                            }
                        }
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('update_customers'),
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        icon: 'trash-2',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: CUSTOMERS,
                        name: 'delete',
                        modifier: (row) => this.$can('delete_customers') && +row.id !== 1 && row.orders.length == 0

                    }
                ],
                filters: [
                    {
                        title: ucWords(this.$t('customer_group')),
                        type: "checkbox",
                        key: "customer_groups",
                        option: [],

                    },
                    {
                        title: "Practitioner",
                        type: "drop-down-filter",
                        key: "practitioner",
                        option: [],
                    },
                    {
                        title: this.$t('Satisfaction status'),
                        type: 'checkbox',
                        key: 'satisfaction_status',
                        option: [
                            { id: 'N/A', value: this.$t('N/A') },
                            { id: 'Yes', value: this.$t('Yes') },
                            { id: 'No', value: this.$t('No') },
                        ],
                    },
                    {
                        title: this.$t('Placed order?'),
                        type: 'checkbox',
                        key: 'placed_order',
                        option: [
                            { id: 'Yes', value: this.$t('Yes') },
                            { id: 'No', value: this.$t('No') },
                        ],
                    },
                    {
                        title: this.$t('My Patient'),
                        type: 'checkbox',
                        key: 'my_paitent',
                        option: [
                            { id: 'Yes', value: this.$t('Yes') },
                            { id: 'No', value: this.$t('No') },
                        ],
                    },
                    {
                        title: this.$t('All Branchs'),
                        type: 'checkbox',
                        key: 'all_branch',
                        option: [
                            { id: 'Yes', value: this.$t('Yes') },
                            { id: 'No', value: this.$t('No') },
                        ],
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 20,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(CUSTOMERS);
    },
    watch: {
        getBranchOrWarehouseId: {
            immediate: false,
            handler() {
                this.updateUrl(CUSTOMERS);
            }
        }
    },
    created() {
        this.filterInitiate();
    },
    methods: {
        ...mapActions(['setCustomersByBranchOrWarehouseId']),

        filterInitiate() {
            this.customerGroups();
            this.practitionerList();
            this.addressArea();
            this.addressCity();
        },

        customerGroups() {
            axiosGet(SELECTABLE_CUSTOMER_GROUP).then((response) => {

                let name = this.options.filters.find(element => element.key === 'customer_groups');
                if (name) name.option = response.data.map(name => {
                    return {
                        id: name.id,
                        value: name.name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },

        practitionerList() {
            axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
                let name = this.options.filters.find(element => element.key === 'practitioner');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.full_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },

        addressArea() {
            axiosGet(ADDRESS_AREA).then((response) => {

                let name = this.options.filters.find(element => element.key === 'address_area');
                if (name) name.option = response.data.map(name => {
                    return {
                        id: name.area,
                        name: name.area
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },

        addressCity() {
            axiosGet(ADDRESS_CITY).then((response) => {

                let name = this.options.filters.find(element => element.key === 'address_city');
                if (name) name.option = response.data.map(name => {
                    return {
                        id: name.city,
                        name: name.city
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        }
    }
}
