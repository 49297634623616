<template>
    <section id="active-queue-report-section" class="content-wrapper">
        <div class="">
          <app-page-top-section :title="'Active Queue Report'" icon="briefcase">
            <app-default-button
                @click="openSortModal"
                :title="'Sort'"/>
                <app-default-button
                @click="exportSalesSummary"
                :title="$fieldTitle('export', '', true)"/>
          </app-page-top-section>
        </div>
        <app-table :id="table_id" :options="options" class="tblScroll tblActiveFixHead" v-if="options.url" @action="triggerActions">
            <template #data-cards>
                <app-active-queue-summary></app-active-queue-summary>
            </template>
        </app-table>

        <app-active-queue-report-sort
            v-if="isActiveQueueSortModal"
            :value="true"
            @modal-close="closeModal"
        />
        <app-queue-action-modal v-if="isQueueUpdateModalActive" :value="true" @modal-close="closeModal" :itemdata="itemdata" />

    </section>
</template>

<script>
import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import ActiveQueueReportMixins from "./ActiveQueueReportMixins";
import {ACTIVE_QUEUE_REPORT_EXPORT, SALES_REPORT, SALES_SUMMARY} from "../../../Config/ApiUrl-CP";
import {numberWithCurrencySymbol} from "../../../Helper/Helper";
import {mapGetters} from "vuex";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name: 'ActiveQueueReport',
    mixins: [DatatableHelperMixin, ActiveQueueReportMixins],
    data() {
        return {
            table_id: 'active-queue-report-table',
            numberWithCurrencySymbol,
            exportLoading: false,
            isActiveQueueSortModal: false,
            isQueueUpdateModalActive: false,
            itemdata: [],
            branch_or_warehouse_id: this.getBranchOrWarehouseId,
        };
    },
    methods: {
        openSortModal() {
            this.isActiveQueueSortModal = true;
        },
        exportSalesSummary() {
            window.open(urlGenerator(`${ACTIVE_QUEUE_REPORT_EXPORT}/?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
        triggerActions(row, action, active) {
            if (action.title === this.$t('Edit')) {
                this.isQueueUpdateModalActive = true;
                this.itemdata = row
            } else {
                this.getAction(row, action, active)
            }
        },
        closeModal() {
            this.isActiveQueueSortModal = false;
            this.isQueueUpdateModalActive = false;
            this.$emit('modal-close');
        },
        openModal() {
            this.isActiveQueueSortModal = true;
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.branch_or_warehouse_id = new_id
        },
    }
};
</script>
<style>
.tblScroll .table-responsive.custom-scrollbar table { width: 2350px; }
table.table tr:has(span.workActive) {
    background-color: #FFA726;
}
table.table tr:has(span.workCompleted) {
    background-color: #66BB6A;
}
table.table tr:has(span.workInactive) {
    background-color: #E0E0E0;
}
table.table tr:has(span.workCancel) {
    background-color: #EF5350;
}
table.table tr:has(span.workProgress) {
    background-color: #29B6F6;
}
#active-queue-report-section a.redirectTo {
    color: #006a97;
}
.tblActiveFixHead .table-responsive.custom-scrollbar { overflow: auto; height: 600px; padding-top: 0 !important;}
.tblActiveFixHead .table-responsive.custom-scrollbar table thead { position: sticky; top: 0; z-index: 2; background-color: #fff !important; }
.tblActiveFixHead .table-responsive.custom-scrollbar table thead tr th:first-child { position: sticky; left: 0; z-index: 2; background-color: #fff !important;}
.tblActiveFixHead .table-responsive.custom-scrollbar table tbody tr td:first-child { position: sticky; left: 0; z-index: 1; }
.tblActiveFixHead .table-responsive.custom-scrollbar table thead th { padding-top: 2rem !important; }
</style>
