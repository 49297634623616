<template>
    <modal id="delivery-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="generateModalTitle('delivery details')"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='SAVE_ORDER_DELIVERY+orderId' @submit.prevent="submitData">

            <div class="row">
                <div class="form-element col-6 col-md-6 mb-4">
                    <div class="mb-3">
                        <label for="qualitycheck" class="form-label">Quality Check</label>
                        <app-input type="select" :list="qualityCheckOptions" list-value-field="value"
                                v-model="formData.qualitycheck" :required="false" :placeholder="$placeholder('Quality Check')" />
                    </div>
                </div>
                <!-- <div class="form-element col-6 col-md-6 mb-4">
                    <div class="mb-3">
                        <label for="qualitycheckby" class="form-label">Quality Check By</label>
                        <app-input type="select" :list="qualityCheckByOptions" list-value-field="value"
                                v-model="formData.qualitycheckby" :required="true" :placeholder="$placeholder('Quality Check By')" />
                    </div>
                </div> -->
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.targetDate" :min-date="(formData.readyDate)?formData.readyDate:todayDate" :label="$t('Target Date')"
                :placeholder="$placeholder($t('Target Date'))" :readOnly="true" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label for="deliveryMode" class="form-label">Delivery Mode:</label>
                        <app-input type="select" :list="deliveryModeOptions" list-value-field="name"
                                v-model="formData.deliveryMode" :required="false" :placeholder="$placeholder('Delivery Mode')" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.readyDate" :min-date="todayDate" :label="$t('Ready Date')"
                :placeholder="$placeholder($t('Ready Date'))" :readOnly="true" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.pickupDate" :label="$t('Pickup Date')"
                :placeholder="$placeholder($t('Pickup Date'))" :readOnly="true" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.dispatchDate" :label="$t('Dispatch Date')"
                :placeholder="$placeholder($t('Dispatch Date'))" :readOnly="true" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group :label="$t('Patient Informed?')" v-model="formData.patientInformed" :placeholder="$placeholder($t('Patient Informed'))">
                        </app-form-group>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group :label="$t('Pickup Person')" v-model="formData.pickupPerson" :placeholder="$placeholder($t('Pickup Person'))">
                        </app-form-group>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <app-form-group :label="$t('Tracking Info')" v-model="formData.trackingInfo" :placeholder="$placeholder($t('Tracking Info'))">
                        </app-form-group>
                    </div>
                </div>
            </div>
        </form>

    </br></br></br></br></br></br></br></br></br>
    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { SAVE_ORDER_DELIVERY, SELECTABLE_DROPDOWN} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "DeliveryActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            SAVE_ORDER_DELIVERY,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                qualitycheck: 0,
                isEdit: this.edititeminfo?.order_product_delivery_details?true:false,
                orderDeliveryId: this.edititeminfo?.order_product_delivery_details?this.edititeminfo?.order_product_delivery_details.id:0,
                order_product_id: this.edititeminfo?.id??0,
            },
            todayDate: new Date(),
            qualityCheckOptions: [],
            deliveryModeOptions: [
                { id: '', name: this.$t('Select Delivery Mode') },
                { id: 'Pickup', name: this.$t('Pickup') },
                { id: 'Delivery', name: this.$t('Delivery') },
            ],
        }
    },
    methods: {
        getQualityCheckOptions() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=15`).then(response => {
                    this.qualityCheckOptions.push({
                        id: 0,
                        value: 'None',
                    });    
                    for (let item of response.data.data) {
                        this.qualityCheckOptions.push({
                            id: item.id,
                            value: item.item_label,
                        });
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        formatDate(dateObj) {
            if(dateObj != '' && dateObj != '0000-00-00') {
                dateObj = new Date(dateObj);
                const date = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate()}
                return `${date.year}-${date.month <= 9 ? '0' : ''}${date.month}-${date.day <= 9 ? '0' : ''}${date.day}`;
            }
            return '';
        },
        submitData() {
            const self = this;
            Object.entries(this.formData).forEach(function([key, value]) {
                if(['targetDate','readyDate','pickupDate','dispatchDate'].includes(key) && value != null) {
                    value = self.formatDate(value);
                }
                self.formData[key] = value;
            });
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#delivery-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
        
    },
    created() {
        this.getQualityCheckOptions();
        // this.qualityCheckByOptions();
        if(this.edititeminfo?.order_product_delivery_details?.id > 0) {
            this.formData.targetDate = this.edititeminfo?.order_product_delivery_details?.target_date; 
            this.formData.readyDate = this.edititeminfo?.order_product_delivery_details?.ready_date; 
            this.formData.pickupDate = this.edititeminfo?.order_product_delivery_details?.pickup_date; 
            this.formData.dispatchDate = this.edititeminfo?.order_product_delivery_details?.dispatch_date; 
            this.formData.deliveryMode = this.edititeminfo?.order_product_delivery_details?.delivery_mode; 
            this.formData.trackingInfo = this.edititeminfo?.order_product_delivery_details?.tracking_info; 
            this.formData.qualitycheck = this.edititeminfo?.order_product_delivery_details?.qualityid; 
            this.formData.patientInformed = this.edititeminfo?.order_product_delivery_details?.patient_informed;
            this.formData.pickupPerson = this.edititeminfo?.order_product_delivery_details?.pickup_person;
        }
    }
}
</script>
