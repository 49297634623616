var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"form-group specification-group mb-4"},[_c('legend',{staticClass:"form-group-title border-bottom pb-2"},[_vm._v("\n      "+_vm._s(_vm.$t('edit'))+"\n      "+_vm._s(_vm.$t('specification'))+"\n   ")]),_vm._v(" "),_c('div',{staticClass:"row align-items-center specification-input product-group-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-group-input"}},[_vm._v(_vm._s(_vm.$t('group')))]),_vm._v(" "),_c('div',{staticClass:"row no-gutters col-12 col-lg-7 form-group d-flex"},[_c('app-input',{staticClass:"form-input col-10 pr-2 col-lg-11",attrs:{"type":"search-select","id":"product-group-input","list":_vm.selectableLists.productGroupList,"placeholder":_vm.$t('search_and_select', {
                  name: _vm.$t('group').toLowerCase(),
               })},model:{value:(_vm.relevantFormData.productGroupId),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productGroupId", $$v)},expression:"relevantFormData.productGroupId"}}),_vm._v(" "),_c('button',{staticClass:"btn specification-add-btn default-base-color col-2 col-lg-1",on:{"click":function($event){$event.preventDefault();return _vm.activateModal('group')}}},[_c('app-icon',{staticClass:"primary-text-color",attrs:{"name":"plus"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row specification-input product-category-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-category-input"}},[_vm._v(_vm._s(_vm.$t('category')))]),_vm._v(" "),_c('div',{staticClass:"row no-gutters col-12 col-lg-7 form-group d-flex"},[_c('app-input',{staticClass:"form-input col-10 pr-2 col-lg-11",attrs:{"type":"search-select","id":"product-category-input","list":_vm.selectableLists.productCategoryList,"placeholder":_vm.$t('search_and_select', {
                  name: _vm.$t('category').toLowerCase(),
               })},model:{value:(_vm.relevantFormData.productCategoryId),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productCategoryId", $$v)},expression:"relevantFormData.productCategoryId"}}),_vm._v(" "),_c('button',{staticClass:"btn specification-add-btn default-base-color col-2 col-lg-1",on:{"click":function($event){$event.preventDefault();return _vm.activateModal('category')}}},[_c('app-icon',{staticClass:"primary-text-color",attrs:{"name":"plus"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row specification-input product-sub-category-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-subcategory-input"}},[_vm._v(_vm._s(_vm.$t('sub_category')))]),_vm._v(" "),_c('div',{staticClass:"row no-gutters col-12 col-lg-7 form-group d-flex"},[_c('app-input',{staticClass:"form-input col-10 pr-2 col-lg-11",attrs:{"type":"search-select","id":"product-subcategory-input","list":_vm.selectableLists.productSubCategoryList,"placeholder":_vm.$t('search_and_select', {
                  name: _vm.$t('sub_category').toLowerCase(),
               })},model:{value:(_vm.relevantFormData.productSubCategoryId),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productSubCategoryId", $$v)},expression:"relevantFormData.productSubCategoryId"}}),_vm._v(" "),_c('button',{staticClass:"btn specification-add-btn default-base-color col-2 col-lg-1",on:{"click":function($event){$event.preventDefault();return _vm.activateModal('subCategory')}}},[_c('app-icon',{staticClass:"primary-text-color",attrs:{"name":"plus"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row specification-input product-brand-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-brand-list"}},[_vm._v(_vm._s(_vm.$t('brand')))]),_vm._v(" "),_c('div',{staticClass:"row no-gutters col-12 col-lg-7 form-group d-flex"},[_c('app-input',{staticClass:"form-input col-10 pr-2 col-lg-11",attrs:{"type":"search-select","id":"product-brand-list","list":_vm.selectableLists.productBrandList,"placeholder":_vm.$t('search_and_select', {
                  name: _vm.$t('brand').toLowerCase(),
               })},model:{value:(_vm.relevantFormData.productBrandId),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productBrandId", $$v)},expression:"relevantFormData.productBrandId"}}),_vm._v(" "),_c('button',{staticClass:"btn specification-add-btn default-base-color col-2 col-lg-1",on:{"click":function($event){$event.preventDefault();return _vm.activateModal('brand')}}},[_c('app-icon',{staticClass:"primary-text-color",attrs:{"name":"plus"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row specification-input product-unit-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-unit-input"}},[_vm._v(_vm._s(_vm.$t('unit')))]),_vm._v(" "),_c('div',{staticClass:"row no-gutters col-12 col-lg-7 form-group d-flex"},[_c('app-input',{staticClass:"form-input col-10 pr-2 col-lg-11",attrs:{"type":"search-select","id":"product-unit-input","list":_vm.selectableLists.productUnitList,"placeholder":_vm.$t('search_and_select', {
                  name: _vm.$t('unit').toLowerCase(),
               })},model:{value:(_vm.relevantFormData.productUnitId),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productUnitId", $$v)},expression:"relevantFormData.productUnitId"}}),_vm._v(" "),_c('button',{staticClass:"btn specification-add-btn default-base-color col-2 col-lg-1",on:{"click":function($event){$event.preventDefault();return _vm.activateModal('unit')}}},[_c('app-icon',{staticClass:"primary-text-color",attrs:{"name":"plus"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row specification-input product-variant-input"},[_c('label',{staticClass:"col-12 col-lg-3",attrs:{"for":"product-type-input"}},[_vm._v(_vm._s(_vm.$t('product_type')))]),_vm._v(" "),_c('app-input',{staticClass:"form-input col-12 col-lg-9",attrs:{"id":"product-type-input","type":"radio","list":_vm.selectableLists.productTypesList},model:{value:(_vm.relevantFormData.productTypesList),callback:function ($$v) {_vm.$set(_vm.relevantFormData, "productTypesList", $$v)},expression:"relevantFormData.productTypesList"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }