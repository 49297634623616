<template>
    <div class="sub-category row w-100">
        <div class="col-lg-3 border-bottom py-3 mb-3">
            <span class="text-muted">{{ $t('List Item') }}:</span>
            {{ listItem.item_label }}
        </div>

        <!-- <div class="col-lg-4 border-bottom py-3 mb-3">
            <p>
                <span class="text-muted">{{ $t('description') }}:</span>
                {{ listItem.description }}
            </p>
        </div>

        <div class="col-lg-3 border-bottom py-3 mb-3 d-flex">
            <span class="text-muted pr-2"> {{ $t('status') }} : </span>
            <app-input type="switch" v-model="active" @click="triggerStatusChange" />
            <span class="listItems-status">{{ listItem.status.translated_name }}</span>
        </div>-->

        <div class="col-lg-2 border-bottom py-3 mb-3 gl-5 d-flex justify-content-center">
            <dropdown-action :key="`listItems-dropdown-key`" :actions="listItemActions" @action="triggerAction"
                :rowData="listItem" />
        </div>

    </div>
</template>

<script>
import DropdownAction from '../../../../core/components/datatable/DropdownAction';
import StatusQueryMixin from "../../../../common/Mixin/Global/StatusQueryMixin";
import { LISTS_ITEMS } from "../../../Config/ApiUrl-CPB";
import { axiosDelete, urlGenerator } from "../../../../common/Helper/AxiosHelper";

export default {
    name: "app-dropdown-list",
    mixins: [StatusQueryMixin],
    components: {
        DropdownAction
    },
    props: ['listItem', 'tableId'],
    data() {
        return {
            // active: this.listItem.status.translated_name === 'Active',
            listItemActions: [
                {
                    title: this.$t('edit'),
                    icon: 'edit',
                    type: 'modal',
                    name: 'edit',
                    component: 'app-list-items-modal',
                    modalId: 'list-items-modal',
                    modifier: (row) => {
                        return this.$can('update_category');
                    },
                },
                {
                    title: this.$t('delete'),
                    icon: 'trash-2',
                    type: 'modal',
                    component: 'app-confirmation-modal',
                    modalId: 'app-confirmation-modal',
                    url: LISTS_ITEMS,
                    name: 'delete',
                    modifier: (row) => {
                        return this.$can('delete_category');
                    },
                }
            ],
        }
    },
    methods: {
        triggerAction(rowData, action, active) {
            if (action.name === 'edit') {
                this.$hub.$emit('listItems-edit', { listItemsId: rowData.id });
            } else if (action.name === 'delete') {
                this.$hub.$emit('listItems-delete', this.listItem.id);
            }
        },
        triggerStatusChange() {
            this.switchStatus(
                'sub_category',
                this.listItem.id,
                this.listItem.status.translated_name.toLowerCase(),
                this.tableId
            );
        }
    }
}
</script>