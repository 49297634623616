<template>
    <modal id="order-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="generateModalTitle('product')"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='SAVE_ORDER_PRODUCT+orderId' @submit.prevent="submitData">

            <div class="row">
                <div class="form-element col-12 col-md-12 mb-4" v-if="!formData.isEdit && formData.parentproductid">
                    <label>{{ $t('Parent Products') }}</label>
                    <div class="form-group">
                        <select id="parentproductid"
                            class="parentproductid form-control"
                            :disabled="formData.isEdit"
                            name="parentproductid" v-model="formData.parentproductid" style="width: 100%;">
                            <option :value="row.id" v-for="(row,index) in parentProductOptions">
                                {{ row.variant.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-element col-12 col-md-12 mb-4">
                    <label>{{ $t('Products') }}</label>
                    <div class="form-group">
                        <select id="productid"
                            class="productid form-control"
                            change="handleAmount()"
                            :disabled="formData.isEdit"
                            name="productid" v-model="formData.productid" style="width: 100%;">
                        </select>
                    </div>
                </div>
                <div class="col-3">
                    <app-form-group
                        v-model="formData.productQty"
                        type="number"
                        class="mb-2"
                        :keyup="handleAmount()"
                        :label="$t('Quantity')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'product_qty')"
                    />
                </div>
                <div class="col-4">
                    <app-form-group
                        v-model="formData.productAmt"
                        type="number"
                        class="mb-2"
                        :label="$t('Amount')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'product_amt')"
                    />
                </div>
                <div class="col-3">
                    <app-form-group
                        v-model="formData.productDiscount"
                        type="number"
                        :keyup="handleAmount()"
                        class="mb-2"
                        :label="$t('Discount')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'product_discount')"
                    />
                </div>
                <div class="col-2">
                    <label for="discounttype">Type</label>
                    <select name="discounttype" class="form-control" change="handleAmount()" v-model="formData.discountType" id="discounttype">
                        <option value="fixed">$</option>
                        <option value="percentage">%</option>
                    </select>
                </div>
                <div class="col-12 row">
                    <app-input
                        type="checkbox"
                        class="col-6 my-3"
                        :list="stockDeductionTypeList"
                        v-model="formData.stockDeductionType"
                        list-value-field="type"
                    />
                    <div class="col-6">
                        <app-form-group
                            v-model="formData.productExtDays"
                            type="number"
                            class="mb-2"
                            :label="$t('Warranty Days')"
                            :required="false"
                            :error-message="$errorMessage(errors, 'extended_warranty_days')"
                        />
                    </div>
                </div>
                <app-input
                    type="textarea"
                    class="d-block col-12"
                    :text-area-rows="5"
                    :placeholder="$placeholder('note')"
                    v-model="formData.productNote"
                />
                <app-input
                    type="textarea"
                    class="d-block col-12 mt-3"
                    :text-area-rows="5"
                    :placeholder="$placeholder('Custom Description')"
                    v-model="formData.customDesc"
                />
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <div class="row text-right" style="font-size: 20px;font-weight: 500;">
                            <div class="col-6 px-0 py-3">Total Amount</div>
                            <div class="col-6 px-0 py-3">{{ numberWithCurrencySymbol(totalAmt) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { SALES_VIEW_PRODUCTS, SAVE_ORDER_PRODUCT} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "OrderActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{},
        orderinfo:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            SAVE_ORDER_PRODUCT,
            showNote: true,
            loading: false,
            totalAmt: 0,
            parentProductOptions:this.orderinfo.products??[],
            formData: {
                isEdit: false,
                due_amount: 0,
                parentproductid: (this.orderinfo.products.length > 0)?this.orderinfo.products[0].id:0,
                productid: this.edititeminfo?.order_product_id??0,
                productQty: this.edititeminfo?.quantity??1,
                productAmt: this.edititeminfo?.price??0,
                productDiscount: this.edititeminfo?.discount_value??0,
                productNote: this.edititeminfo?.note??'',
                customDesc: this.edititeminfo?.custom_desc??'',
                productExtDays: this.edititeminfo?.extra_warranty_days??0,
                discountType: (this.edititeminfo?.discount_type == 'percentage')?this.edititeminfo?.discount_type:'fixed',
                itemdata: '',
                stockDeductionType: (this.edititeminfo?.stock_deduction_type == 1)?"":"2"
            },
            stockDeductionTypeList: [
                {id: "2", type: 'CFU'}
            ],
        }
    },
    methods: {
        handleAmount(){
            let productQty = this.formData.productQty;
            let productAmt = this.formData.productAmt;
            let productDiscount = this.formData.productDiscount;
            let discountType = this.formData.discountType;
            let totalAmt = (productAmt*productQty);
            if(discountType == 'fixed') {
                totalAmt = totalAmt - productDiscount;  
            } else {
                totalAmt = totalAmt - ((totalAmt*productDiscount)/100);
            }
            this.totalAmt = totalAmt;
        },
        submitData() {
            // console.log(this.formData.stockDeductionType);
            // return false;
            if(this.formData.productid == 0) {
                this.$toastr.e('Product is required.');
                return false;
            }
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#order-action-modal').modal('hide');
            this.$emit('input', false);
            if(data.status) {
                this.toastAndReload(data.message);
            } else {
                this.toastException(data);
            }
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
        let self = this;
        var select2Option = {
            multiple: false,
            tokenSeparators: [',', ' '],
            minimumInputLength: 3,
            minimumResultsForSearch: 50,
            placeholder: 'Select product',
            dropdownParent: $("#order-action-modal"),
            ajax: {
                url: urlGenerator(SALES_VIEW_PRODUCTS),
                dataType: "json",
                data: function (params) {
                    var query = {
                        search: params.term,
                        prodid: self.edititeminfo?.order_product_id??0
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: $.map(data.data, function (item) {
                            return {
                                text: item.name,
                                upc: item.variant.upc,
                                selling_price: item.variant.selling_price,
                                itemdata: item,
                                id: item.id
                            }
                        })
                    };
                },
            },
            templateResult: function (item) {
                if (!item.id) {
                    return item.text;
                }
                var $item = $('<span><b>' + item.text + '</b><br> #'+item.upc+' <br> '+numberWithCurrencySymbol(item.selling_price)+'</span>');
                return $item;
            },
            templateSelection: function (item) {
                if (item.id) {
                    if(!self.edititeminfo?.order_product_id) {
                        self.formData.productAmt = item.selling_price;
                        self.formData.itemdata = item.itemdata;
                        self.formData.productExtDays = item.itemdata.variant.product.warranty_duration??0;
                    }
                    self.formData.productid = item.id;
                }
                return item.text;
            }
        };
        $('.productid').select2(select2Option);
        if(this.edititeminfo?.order_product_id) {
            self.formData.isEdit = true;
            var option = new Option(this.edititeminfo.variant.name, this.edititeminfo.order_product_id, true, true);
            $('.productid').append(option).trigger('change');
            self.formData.itemdata = this.edititeminfo;
        }
        
    }
}
</script>
