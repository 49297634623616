<template>
    <fieldset :disabled="isDisable">
        <div class="row">
            <div class="col-lg-12">
                <form ref="form" class="mb-0 position-relative" :class="{ 'loading-opacity': loading }"
                    :data-url='selectedUrl ? selectedUrl : CUSTOMERS' @submit.prevent="submitData">
                    <app-overlay-loader v-if="loading" />
                    <div class="row">
                        <div class="col-12 col-sm-2 col-md-2">
                            <app-form-group type="select" :list="Prefix" :class="'required'" list-value-field="name"
                                :label="$t('Prefix')" :required="true" :error-message="$errorMessage(errors, 'prefix')"
                                :placeholder="$placeholder('Prefix')" v-model="formData.prefix" />
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <app-form-group :label="$t('first_name')" :class="'required'" v-model="formData.first_name"
                                :error-message="$errorMessage(errors, 'first_name')"
                                :placeholder="$placeholder($t('first_name'))" :required="true">
                            </app-form-group>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <app-form-group v-model="formData.last_name" :error-message="$errorMessage(errors, 'last_name')"
                                :label="$t('last_name')" :placeholder="$placeholder($t('last_name'))" />
                        </div>
                        <div class="customer-group-input mb-3 col-md-2">
                            <label>{{ $t('customer_group') }}</label>
                            <app-input class="margin-right-8" type="search-and-select" :key="customerGroupInputKey"
                                :placeholder="$t('search_and_select', { name: $t('customer_group').toLowerCase() })"
                                :options="customerGroupOptions" :inputclearable="false" v-model="formData.customer_group_id"
                                :error-message="$errorMessage(errors, 'customer_group_id')" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group v-model="formData.record_created_by"
                                :error-message="$errorMessage(errors, 'record_created_by')" :label="$t('Record created by')"
                                :placeholder="$placeholder($t('Record created by'))" disabled />
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label>{{ $t('First assigned to') }}</label>
                            <div class="form-group">
                                <select id="first_assigned_to"
                                    class="first_assigned_to form-control "
                                    name="first_assigned_to" v-model="formData.first_assigned_to"
                                    :disabled="formData.first_assigned_to !== null & isDisable" style="width: 100%;">
                                    <!--  <option value="">Select a user</option> Add a default empty option -->
                                    <option v-for="user in userList" :key="user.id" :value="user.id">
                                        {{ user.value }}
                                    </option>
                                </select>
                            </div>
                            <!-- <app-input class="margin-right-8" type="search-and-select" :inputclearable="false"
                            :placeholder="$t('Select First assigned to')" :options="userOptions"
                            v-model="formData.first_assigned_to"
                            :error-message="$errorMessage(errors, 'first_assigned_to')" /> -->
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label>{{ $t('Currently assigned to') }}</label>
                            <div class="form-group">
                                <select id="currently_assigned_to"
                                    class="currently_assigned_to form-control "
                                    name="currently_assigned_to" v-model="formData.currently_assigned_to"
                                    style="width: 100%;" :disabled="disableInput">
                                    <!--  <option value="">Select a user</option> Add a default empty option -->
                                    <option v-for="user in userList" :key="user.id" :value="user.id">
                                        {{ user.value }}
                                    </option>
                                </select>
                            </div>
                            <!-- <app-input class="margin-right-8" type="search-and-select" :inputclearable="false"
                            :placeholder="$t('Select Currently assigned to')" :options="userOptions"
                            v-model="formData.currently_assigned_to"
                            :error-message="$errorMessage(errors, 'currently_assigned_to')" /> -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group type="date" v-model="formData.registration_date"
                                :error-message="$errorMessage(errors, 'registration_date')" :label="$t('Registration Date')"
                                :placeholder="$placeholder($t('Registration Date'))" :readOnly="true" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group type="select" :list="ExaminationVenue" list-value-field="name" :page="'modal'"
                                :label="$t('Examination Venue')" :required="true"
                                :error-message="$errorMessage(errors, 'examination_venue')"
                                :placeholder="$placeholder('Examination Venue')" v-model="formData.examination_venue" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 required">
                            <label>{{ $t('Original Branch') }}</label>
                            <div class="form-group">
                                <select id="original_branch"
                                    class="original_branch form-control "
                                    name="original_branch" v-model="formData.original_branch" style="width: 100%;"
                                    :disabled="disableInput">
                                    <option v-for="branch in branchList" :key="branch.id" :value="branch.id">
                                        {{ branch.value }}
                                    </option>
                                </select>
                                <small class="text-danger" v-if="$errorMessage(errors, 'original_branch')">{{
                                    $errorMessage(errors,
                                        'original_branch')
                                }}</small>
                            </div>
                            <!-- <app-input class="margin-right-8" type="warehouse-or-branch"
                            :placeholder="$t('Select Original Branch')" :inputclearable="false"
                            :options="branchOrWareHoueeOptions" v-model="formData.original_branch"
                            :error-message="$errorMessage(errors, 'original_branch')" /> -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group type="select" :list="LegacyPatient" list-value-field="name" :page="'modal'"
                                :label="$t('Legacy Patient')" :required="true"
                                :error-message="$errorMessage(errors, 'legacy_patient')"
                                :placeholder="$placeholder('Legacy Patient')" v-model="formData.legacy_patient" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group :label="$t('Guardian name & relationship')"
                                v-model="formData.guardian_name_relationship"
                                :error-message="$errorMessage(errors, 'guardian_name_relationship')"
                                :placeholder="$placeholder($t('Guardian name & relationship'))" :required="true">
                            </app-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <label>{{ $t('Linked patients') }}</label>
                            <app-input type="multi-select" :disabled="isDisable" :placeholder="$t('Select Linked patients')"
                                :inputclearable="false" :list="linkedPatientsList" v-model="formData.linked_patients"
                                :error-message="$errorMessage(errors, 'linked_patients')"
                                @input="handleLinkedPatientsChange" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <app-form-group type="date" :label="$t('Date of birth')" :class="'required'"
                                v-model="formData.date_of_birth" :error-message="$errorMessage(errors, 'date_of_birth')"
                                :placeholder="$placeholder($t('Date of birth'))" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <app-form-group :label="$t('Age')" :required="true" :class="'required'" v-model="formData.age"
                                :error-message="$errorMessage(errors, 'age')" :placeholder="$placeholder($t('Age'))" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <label>{{ $t('Gender') }}</label>
                            <div class="app-radio-group">
                                <!-- Male Option -->
                                <label class="customized-radio radio-default">
                                    <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Male'"
                                        :checked="formData.gender === 'male'" @change="onGenderChange('male')" />
                                    <span class="outside">
                                        <span class="inside"></span>
                                    </span>
                                    {{ $t('male') }}
                                </label>

                                <!-- Female Option -->
                                <label class="customized-radio radio-default">
                                    <input type="radio" class="radio-inline" :name="'gender'"
                                        :id="'customRadioGender-Female'" :checked="formData.gender === 'female'"
                                        @change="onGenderChange('female')" />
                                    <span class="outside">
                                        <span class="inside"></span>
                                    </span>
                                    {{ $t('female') }}
                                </label>

                                <!-- Other Option -->
                                <label class="customized-radio radio-default">
                                    <input type="radio" class="radio-inline" :name="'gender'"
                                        :id="'customRadioGender-Other'" :checked="formData.gender === 'other'"
                                        @change="onGenderChange('other')" />
                                    <span class="outside">
                                        <span class="inside"></span>
                                    </span>
                                    {{ $t('other') }}
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 required">
                            <label>{{ $t('Weight') }} ({{ weightUnit || 'kgs' }})</label>
                            <app-input type="number" v-model="formData.weight"
                                :error-message="$errorMessage(errors, 'weight')" :placeholder="$placeholder($t('Weight'))"
                                onkeydown="if(event.key==='.'){event.preventDefault();}"
                                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" />
                        </div>

                        <div class="col-12 col-sm-6 col-md-3">
                            <app-form-group type="select" :list="ActivityLevel" list-value-field="name" :page="'modal'"
                                v-model="formData.activity_level" :error-message="$errorMessage(errors, 'activity_level')"
                                :label="$t('Activity Level')" :placeholder="$placeholder($t('Activity Level'))" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 required">
                            <label>{{ $t('Primary complaint') }}</label>
                            <div class="form-group">
                                <select id="primary_complaint"
                                    class="primary_complaint form-control "
                                    name="primary_complaint" v-model="formData.primary_complaint" style="width: 100%;"
                                    :disabled="isDisable">
                                    <option v-for="primaryComplaint in primaryComplaintList" :key="primaryComplaint.id"
                                        :value="primaryComplaint.id">
                                        {{ primaryComplaint.value }}
                                    </option>
                                </select>
                                <small class="text-danger" v-if="$errorMessage(errors, 'primary_complaint')">{{
                                    $errorMessage(errors,
                                        'primary_complaint')
                                }}</small>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <app-form-group v-model="formData.occupation"
                                :error-message="$errorMessage(errors, 'occupation')" :label="$t('Occupation')"
                                :placeholder="$placeholder($t('Occupation'))" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <app-form-group type="select" :class="'required'" :list="PreferredLanguage"
                                list-value-field="name" :page="'modal'" v-model="formData.preferred_language"
                                :error-message="$errorMessage(errors, 'preferred_language')"
                                :label="$t('Preferred language')" :placeholder="$placeholder($t('Preferred language'))" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 required">
                            <label>{{ $t('Referred by') }}</label>
                            <div class="form-group">
                                <select id="referred_by"
                                    class="referredby form-control "
                                    name="referred_by" v-model="formData.referred_by" style="width: 100%;">
                                    <option v-for="row in referredBy" :key="row.id" :value="row.id">
                                        {{ row.value }}
                                    </option>
                                </select>
                                <small class="text-danger" v-if="$errorMessage(errors, 'referred_by')">{{ $errorMessage(errors,
                                    'referred_by')
                                }}</small>
                            </div>
                        </div>
                        <div :class="getSourceColumnClass()">
                            <app-form-group type="select" :class="'required'" :list="Source" input-class=" form_source" list-value-field="name"
                                :page="'modal'" v-model="formData.source" :error-message="$errorMessage(errors, 'source')"
                                :label="$t('Source')" :placeholder="$placeholder($t('Source'))" />
                        </div>
                        <div :class="getCampColumnClass()">
                            <label>{{ $t('Camp Name') }}</label>
                            <div class="form-group">
                                <select id="camp_name"
                                    class="campname form-control "
                                    name="camp_name" v-model="formData.camp_name" style="width: 100%;">
                                    <option v-for="row in campList" :key="row.id" :value="row.id">
                                        {{ row.value }}
                                    </option>
                                </select>
                                <small class="text-danger" v-if="$errorMessage(errors, 'camp_name')">{{ $errorMessage(errors,
                                    'camp_name')
                                }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('email') }}(s)</label>
                        <div v-for="(rowData, index) in inputEmailRow" :key="index" class="row"
                            :class="{ 'form-group': index !== inputEmailRow.length - 1 }">
                            <div class="col-md-11 col-lg-11">
                                <app-input class="mb-2 mb-sm-2 mb-md-0" v-model="rowData.email"
                                    :disabled="!userCanUpdateCustomer" :error-message="$errorMessage(errors, 'email')"
                                    :placeholder="$placeholder('email')" />
                            </div>
                            <!-- <div class="col-md-4 col-lg-5">
                            <app-input class="mb-2 mb-sm-2 mb-md-0" v-model="rowData.email_type"
                                :disabled="!userCanUpdateCustomer" :error-message="$errorMessage(errors, 'email_type')"
                                :list="typeList" list-value-field="name" type="select" />
                        </div> -->
                            <div class="col-md-2 col-lg-1" v-if="userCanUpdateCustomer">
                                <div class="text-right h-100">
                                    <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color"
                                        type="button" @click="addEmailRow">
                                        <app-icon class="size-19 primary-text-color" name="plus" />
                                    </button>
                                    <button v-else :key="'trash' + index" class="btn default-base-color" type="button"
                                        @click="removeEmailRow(index)">
                                        <app-icon class="size-19 primary-text-color" name="trash" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group required">
                        <label>{{ $t('phone_number') }}(s)</label>
                        <div v-for="(rowData, index) in inputPhoneNumberRow" :key="index" class="row"
                            :class="{ 'form-group': index !== inputPhoneNumberRow.length - 1 }">
                            <div class="col-md-11 col-lg-11">
                                <app-input class="mb-2 mb-sm-2 mb-md-0" v-model="rowData.phone_number"
                                    :disabled="!userCanUpdateCustomer"
                                    :error-message="$errorMessage(errors, 'phone_number')"
                                    :placeholder="$placeholder('phone_number')" />
                            </div>
                            <!-- <div class="col-md-4 col-lg-5">
                            <app-input class="mb-2 mb-sm-2 mb-md-0" v-model="rowData.phone_number_type"
                                :disabled="!userCanUpdateCustomer" :error-message="$errorMessage(errors, 'type')"
                                :list="typeList" list-value-field="name" type="select" />
                        </div> -->
                            <div class="col-md-2 col-lg-1" v-if="userCanUpdateCustomer">
                                <div class="text-right h-100">
                                    <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color"
                                        type="button" @click="addPhoneNumberRow">
                                        <app-icon class="size-19 primary-text-color" name="plus" />
                                    </button>
                                    <button v-else :key="'trash' + index" class="btn default-base-color" type="button"
                                        @click="removePhoneNumberRow(index)">
                                        <app-icon class="size-19 primary-text-color" name="trash" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group v-model="formData.discount" :error-message="$errorMessage(errors, 'discount')"
                                :label="$t('Discount %')" :placeholder="$placeholder($t('Discount'))" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group :label="$t('Satisfaction status')" type="radio" :list="[
                                { id: 'N/A', value: $t('N/A') },
                                { id: 'Yes', value: $t('Yes') },
                                { id: 'No', value: $t('No') }
                            ]" v-model="formData.satisfaction_status"
                                :error-message="$errorMessage(errors, 'satisfaction_status')" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="form-check" style="top: .8rem; scale: 1.2; padding-top: 13px;">
                                <input type="checkbox" class="form-check-input" id="DND" v-model="formData.dnd">
                                <label class="form-check-label" for="DND">{{ $t('DND') }}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <app-form-group type="textarea" :text-area-rows="3" v-model="formData.important_notes"
                                :error-message="$errorMessage(errors, 'important_notes')" :label="$t('Important Notes')"
                                :placeholder="$placeholder($t('Important Notes'))" />
                        </div>
                    </div>
                    <div v-if="userCanUpdateCustomer && !isDisable" class="mt-primary mb-4">
                        <button class="btn btn-primary" type="button" @click.prevent="submitData">
                            {{ $t('save') }}
                        </button>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12">
                            <!-- Load app-customer-address component dynamically -->
                            <h4>Address</h4>
                            <app-customer-address :props="this.props"></app-customer-address>
                        </div>
                    </div>
                    <hr>
                    <!-- Attachments Section -->
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12 mb-4">
                            <h4>Attachments</h4>
                            <div v-for="attachment in inputAttachments" :key="attachment.id">
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <p>{{ getAttachmentFileName(attachment.file_path) }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <a class="btn btn-primary text-white cursor-pointer"
                                            :href="urlGenerator(attachment.file_path)" target="_blank"><app-icon name="eye"/></a>
                                        <a class="btn btn-primary text-white cursor-pointer"
                                            :href="urlGenerator(attachment.file_path)" download target="_blank"><app-icon name="download"/></a>
                                        <a class="btn btn-danger text-white cursor-pointer"
                                            @click="() => deleteAttachment(attachment.id)" v-if="!isDisable"><app-icon name="trash-2"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 mb-4">
                            <div v-for="(attachment, index) in attachments" :key="index">
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <label>Description</label>
                                        <input class="form-control" type="text" v-model="attachment.description"
                                            placeholder="Description" />
                                    </div>
                                    <div class="col-md-5">
                                        <label>Files</label>
                                        <input class="form-control" type="file" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt" @change="handleFileChange(index, $event)" />
                                    </div>
                                    <div class="col-md-1">
                                        <label>{{ $t('Remove') }}</label>
                                        <button class="btn default-base-color" @click="removeAttachment(index)">
                                            <app-icon class="size-19 primary-text-color" name="trash" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <a v-if="!isDisable" class="btn btn-primary cursor-pointer text-white" @click="addAttachment">Add Attachment</a>
                            <a v-if="!isDisable" class="btn btn-primary cursor-pointer text-white" @click="submitAttachments">Submit
                                Attachments</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </fieldset>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import DefaultAction from "../../../../../core/components/datatable/DefaultAction";
import {
    SELECTABLE_CUSTOMER_GROUP,
    CUSTOMERS,
    ATTACHMENTS,
} from "../../../../Config/ApiUrl-CPB";
import { LINKED_CUSTOMERS, WEIGHT_UNIT, GET_CUSTOMERS_NAME } from "../../../../Config/ApiUrl-CP";
import { axiosGet, axiosPost, axiosPatch, urlGenerator, axiosDelete } from "../../../../../common/Helper/AxiosHelper";
import { mapState, mapGetters } from "vuex";
import 'select2/dist/css/select2.css'; // Import Select2 CSS
import 'select2'; // Import Select2 JavaScript

export default {
    name: "PersonalInfo",
    mixins: [FormHelperMixins],
    props: {
        props: {
            customerId: {},
        },
    },
    components: {
        DefaultAction
    },
    data() {
        return {
            selectedUser: '',
            userList: [],
            primary_complaint: [],
            weightUnit: '',
            attachments: [],
            referredBy: [],
            campList: [],
            attachmentDescriptions: {},
            loading: true,
            SELECTABLE_CUSTOMER_GROUP,
            CUSTOMERS,
            customerOptions: {
                url: urlGenerator("app/selectable-customer-groups"),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: false,
            },
            actions: [
                {
                    title: this.$t('delete'),
                    icon: 'trash-2',
                    type: 'delete',
                    className: 'btn-dark text-primacy'
                }
            ],
            actionsAdd: [
                {
                    title: this.$t('add'),
                    icon: 'plus',
                    type: 'add',
                    className: 'text-primary',
                }
            ],
            typeList: [
                {
                    id: '',
                    name: this.$t('select_type'),
                },
                {
                    id: 'home',
                    name: this.$t('home'),
                },
                {
                    id: 'work',
                    name: this.$t('work'),
                },
                {
                    id: 'office',
                    name: this.$t('office'),
                }
            ],
            customerGroupInputKey: 0,
            customerGroupOptions: {
                url: urlGenerator("app/selectable-customer-groups"),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: false,
            },
            formData: {
                primary_complaint: '',
                customer_group_id: '',
                addresses: {},
                contacts: {},
                tin: '',
                tenant_id: '',
                gender: 'male',
                satisfaction_status: 'N/A',
                title: '',
                original_branch: '',
                referred_by: '',
                camp_name: '',
                record_created_by: window.user.full_name || '',
                registration_date: '', // Added registration_date property
                addToReport: false,
                first_assigned_to: null,
                currently_assigned_to: null,
            },
            branchList: [],
            primaryComplaintList: [],
            referredByList: [],
            linkedPatientsList: [],
            maxSelections: 3,
            Prefix: [
                { id: '', name: this.$t('Select Prefix') },
                { id: 'Mr.', name: this.$t('Mr.') },
                { id: 'Ms.', name: this.$t('Ms.') },
                { id: 'Mrs.', name: this.$t('Mrs.') },
                { id: 'Dr.', name: this.$t('Dr.') },
            ],
            CurrentUser: [
                { id: '', name: this.$t('Select User') },
                { id: window.user.full_name || '', name: this.$t(window.user.full_name || '') },
            ],
            GSTTypeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'Unregistered', name: this.$t('Unregistered') },
                { id: 'Registered', name: this.$t('Registered') },
                { id: 'Composite', name: this.$t('Composite') },
            ],
            ExaminationVenue: [
                { id: '', name: this.$t('Select Examination Venue') },
                { id: 'Branch', name: this.$t('Branch') },
                { id: 'Home Visit', name: this.$t('Home Visit') },
                { id: 'Doctors Clinic', name: this.$t('Doctors Clinic') },
                { id: 'Online', name: this.$t('Online') },
                { id: 'Camp', name: this.$t('Camp') },
            ],
            ActivityLevel: [
                { id: '', name: this.$t('Select Activity Level') },
                { id: 'Home Based', name: this.$t('Home Based') },
                { id: 'Sedentary', name: this.$t('Sedentary') },
                { id: 'Morning Walks', name: this.$t('Morning Walks') },
                { id: 'On foot Occupation', name: this.$t('On foot Occupation') },
                { id: 'Recreational sport', name: this.$t('Recreational sport') },
                { id: 'Professional sport', name: this.$t('Professional sport') },
            ],
            LegacyPatient: [
                { id: '', name: this.$t('Select Legacy Patient') },
                { id: 'Yes', name: this.$t('Yes') },
                { id: 'No', name: this.$t('No') },
            ],
            PreferredLanguage: [
                { id: '', name: this.$t('Select Preferred Language') },
                { id: 'English', name: this.$t('English') },
                { id: 'Hindi', name: this.$t('Hindi') },
                { id: 'Marathi', name: this.$t('Marathi') },
            ],
            Source: [
                { id: '', name: this.$t('Select Source') },
                { id: 'Camp', name: this.$t('Camp') },
                { id: 'Google Business Page', name: this.$t('Google Business Page') },
                { id: 'Google Ad', name: this.$t('Google Ad') },
                { id: 'Website', name: this.$t('Website') },
                { id: 'Doctor', name: this.$t('Doctor') },
                { id: 'Walk-in', name: this.$t('Walk-in') },
                { id: 'YouTube', name: this.$t('YouTube') },
                { id: 'Facebook', name: this.$t('Facebook') },
                { id: 'Instagram', name: this.$t('Instagram') },
                { id: 'Referred by friend', name: this.$t('Referred by friend') },
                { id: 'Other', name: this.$t('Other') },
            ],
            customerOptions: {
                url: urlGenerator("app/selectable-customers"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            },
            campsOptions: {
                url: urlGenerator("app/selectable/camps"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.camp_name }),
            },
            inputEmailRow: [],
            inputAttachments: [],
            removableEmailRow: true,
            inputPhoneNumberRow: [],
            removablePhoneNumberRow: true,
            inputAddressRow: [],
            removableAddressRow: true,
            customerBranchId: null,
            isDisable: (this.$can('edit_patient_info') == true)?false:true,
            disableInput: null,
        }
    },
    created() {
        this.fetchCustomerBranchId();
        this.userListOptions();
        this.branchListOptions();
        this.primaryComplaintOptions();
        if(window.user.roles[0].id != 1) {
            this.disableInput = true;
        }
        if(!this.isDisable) {
            this.isDisable = this.props[1];
        }
    },
    watch: {
        inputEmailRow() {
            this.removableEmailRow = this.inputEmailRow.length <= 1;
        },
        inputPhoneNumberRow() {
            this.removablePhoneNumberRow = this.inputPhoneNumberRow.length <= 1;
        },
        inputAddressRow() {
            this.removableAddressRow = this.inputAddressRow.length <= 1;
        },
        customer: {
            handler: function (customer) {
                this.formData = customer;
            }
        },

        'formData.date_of_birth'(newDateOfBirth) {
            if (newDateOfBirth) {
                const today = new Date();
                const birthDate = new Date(newDateOfBirth);
                const age = today.getFullYear() - birthDate.getFullYear();

                // Adjust age based on month and day of birth
                if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                    this.formData.age = age - 1; // Update the age property directly
                } else {
                    this.formData.age = age; // Update the age property directly
                }
            }
        },
    },
    mounted() {
        this.addEmailRow();
        this.addPhoneNumberRow();
        // Initialize Select2
        let self = this;
        $('.first_assigned_to').select2({ placeholder: 'Select a user', }).on("change", function () {
            self.onSelectChangeFirstAssignedTo(this.value);
        });

        $('.original_branch').select2({ placeholder: 'Select a user', }).on("change", function () {
            self.formData.linked_patients = [];
            self.onSelectChangeOriginalBranch(this.value);
        });

        $('.currently_assigned_to').select2({ placeholder: 'Select a user', }).on("change", function () {
            self.onSelectChange(this.value);
        });

        $('.primary_complaint').select2({ placeholder: 'Select a Primary Complaint', }).on("change", function () {
            self.onSelectChangePrimaryComplaint(this.value);
        });
        
        $('.campname').select2({ placeholder: 'Select Camp name', }).on("change", function () {
            self.onSelectChangeCampName(this.value);
        });

        $('.referredby').select2({ placeholder: 'Select Referred by', }).on("change", function () {
            self.onSelectChangeReferredBy(this.value);
        });
    },
    methods: {
        showLoader(hide=false){
            $("div#app").addClass('apploader');
            if(hide) $("div#app").removeClass('apploader');
        },
        deleteAttachment(attachmentId) {
            axiosDelete(`app/delete-attachment/${attachmentId}`).then((response) => {
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                this.toastAndReload(response.data.message);
            }).catch((error) => {
                // Handle any errors that occur during the request
                console.error(error);
            });
        },
        getAttachmentFileName(filename) {
            let nameArr = filename.split('/');
            return nameArr[nameArr.length-1];
        },
        fetchCustomerBranchId() {
            axiosGet(`${CUSTOMERS}/${this.props[0]}`).then(response => {
                this.formData = response.data;
                this.customerGroupInputKey++;
                this.customerBranchId = response.data.original_branch;
                console.log('B', this.getBranchOrWarehouseId);

                // Call loadWeightUnit with customerBranchId as branchId
                this.loadWeightUnit(this.customerBranchId);
                // Call fetchlinkedCustomers with customerBranchId as branchId
                // this.fetchlinkedCustomers(this.customerBranchId);
                this.onSelectChangeOriginalBranch(this.customerBranchId);

                this.inputAttachments = this.formData.attachments;
                if (this.formData.contacts.email) {
                    this.inputEmailRow = this.formData.contacts.email.map((item) => {
                        return {
                            email: item.value,
                            email_type: item.type
                        }
                    });
                }

                if (this.formData.contacts.phone_number) {
                    this.inputPhoneNumberRow = this.formData.contacts.phone_number.map((item) => {
                        return {
                            phone_number: item.value,
                            phone_number_type: item.type
                        }
                    });
                }
            }).catch(error => {
                this.$toastr.e(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        onSelectChangeFirstAssignedTo(val) {
            this.formData.first_assigned_to = val;
        },
        onSelectChangeOriginalBranch(val) {
            this.formData.original_branch = val;
            this.fetchLinkedDoctors();
            this.fetchLinkedCamps();
            this.fetchlinkedCustomers(val);
        },
        onSelectChangePrimaryComplaint(val) {
            this.formData.primary_complaint = val;
        },
        onSelectChangeReferredBy(val) {
            this.formData.referred_by = val;
        },
        onSelectChangeCampName(val) {
            this.formData.camp_name = val;
        },
        onSelectChange(val) {
            this.formData.currently_assigned_to = val;
        },
        userListOptions() {
            axiosGet(`app/selectable/users`).then(response => {
                for (const item of response.data.data) {
                    this.userList.push({
                        id: item.id,
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        referredByOptions() {
            axiosGet(`app/selectable/doctors`).then(response => {
                for (const item of response.data.data) {
                    // console.log("data", item);
                    this.referredByList.push({
                        id: item.id,
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        primaryComplaintOptions() {
            axiosGet(`app/selectable-pathologies`).then(response => {
                for (const item of response.data.data) {
                    // console.log("data", item);
                    this.primaryComplaintList.push({
                        id: item.id,
                        value: item.chief_complaint_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        branchListOptions() {
            axiosGet(`app/selectable/branches`).then(response => {
                for (const item of response.data.data) {
                    this.branchList.push({
                        id: item.id,
                        value: item.name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchLinkedDoctors() {
            this.referredBy = [];
            axiosGet(`app/selectable/doctors?branch_id=${this.formData.original_branch}`).then(response => {
                for (const item of response.data.data) {
                    this.referredBy.push({
                        id: item.id,
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchLinkedCamps() {
            this.campList = [];
            axiosGet(`app/selectable/camps?branch_id=${this.formData.original_branch}`).then(response => {
                for (const item of response.data.data) {
                    this.campList.push({
                        id: item.id,
                        value: item.camp_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        onGenderChange(selectedGender) {
            // Access the selected gender
            this.formData.gender = selectedGender;
        },
        getSourceColumnClass() {
            return this.formData.source === 'Camp' ? 'col-12 col-sm-6 col-md-4' : 'col-12 col-sm-6 col-md-4';
        },
        getCampColumnClass() {
            return this.formData.source === 'Camp' ? 'col-12 col-sm-6 col-md-4' : 'd-none'; // Hide if source is not 'Camp'
        },
        handleFileChange(index, event) {
            var allowedExtensions = ['jpg', 'jpeg', 'gif', 'cdr', 'dxf', 'pdf', '.svg', 'stl', 'cnc', 'imed', 'png', 'psd', 'txt'];
            const fileInput = event.target;
            const file = event.target.files[0];
            var fileName = file.name.toLowerCase();
            var fileExtension = fileName.split('.').pop();
            if (allowedExtensions.indexOf(fileExtension) === -1) {
                fileInput.value = '';
                alert('Invalid file type. Please select a valid file.');
                return false;
            } else {
                this.attachments[index].file = file;
            }
        },
        addAttachment() {
            this.attachments.push({ description: '', file: null });
        },
        removeAttachment(index) {
            this.attachments.splice(index, 1);
        },
        submitAttachments() {
            const formData = new FormData();
            this.attachments.forEach((attachment) => {
                formData.append('attachment_descriptions[]', attachment.description);
                formData.append('attachments[]', attachment.file);
            });
            try {
                this.showLoader();
                axiosPost(`${ATTACHMENTS}/${this.props[0]}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    // Handle response as needed
                    this.showLoader(true);
                    this.toastAndReload(response.data.message);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }).catch(error => {
                    console.error(error);
                });

            } catch (error) {
                console.error(error);
            }
        },
        loadWeightUnit(branchId) {
            axiosGet(`${WEIGHT_UNIT}/${branchId}`).then(response => {
                // console.log('response:', response.data.weight_unit);
                const weightUnit = response.data.weight_unit;

                // Set the weightUnit value in the data
                this.weightUnit = weightUnit;
            }).catch(error => {
                console.error(error);
            });
        },
        fetchlinkedCustomers(branchId) {
            this.linkedPatientsList = [];
            axiosGet(`${LINKED_CUSTOMERS}/${branchId}`).then(response => {
                for (const item of response.data.data) {
                    this.linkedPatientsList.push({
                        id: item.id.toString(),
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        handleLinkedPatientsChange() {
            console.log(this.formData.linked_patients.length);
            if (this.formData.linked_patients.length > this.maxSelections) {
                // If the user exceeds the maximum allowed selections, remove the latest added patients
                const removedPatients = this.formData.linked_patients.splice(this.maxSelections);
                // Show an error message to inform the user about the limit
                alert(`You can select up to ${this.maxSelections} linked patients.`);
            }
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1; // Note: month is zero-based
            let day = today.getDate();

            // Pad month and day with leading zeroes if necessary
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${year}-${month}-${day}`;
        },

        setInitialRegistrationDate() {
            this.formData.registration_date = this.getCurrentDate(); // Set initial value of registration_date
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            // this.loading = true;
            this.message = '';
            this.errors = {};

            this.formData.first_assigned_to = $($(".first_assigned_to")[0]).val();
            this.formData.currently_assigned_to = $($(".currently_assigned_to")[0]).val();
            this.formData.original_branch = $($(".original_branch")[0]).val();
            this.formData.primary_complaint = $($(".primary_complaint")[0]).val();
            this.formData.referred_by = $($(".referredby")[0]).val();
            this.formData.camp_name = $($(".campname")[0]).val();

            this.formData.email_details = this.inputEmailRow.map((item) => {
                return {
                    'email': item.email,
                    'email_type': item.email_type,
                }
            });

            this.formData.phone_number_details = this.inputPhoneNumberRow.map((item) => {
                return {
                    'phone_number': item.phone_number,
                    'phone_number_type': item.phone_number_type,
                }
            });

            this.formData.attachments = this.attachments.map((attachment) => {
                return {
                    description: attachment.description,
                    file: attachment.file, // Include the file if needed
                };
            });

            // console.log(this.formData);
            this.showLoader();
            axiosPatch(`${CUSTOMERS}/${this.props[0]}`, this.formData)
                .then(response => {
                    this.showLoader(true);
                    this.toastAndReload(response.data.message);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
                .catch(err => {
                    this.showLoader(true);
                    // Check if the error response contains a message
                    if (err.response && err.response.data && err.response.data.message) {
                        this.$toastr.e(err.response.data.message);
                        this.errors = err.response.data.errors || {};
                    } else {
                        // If there's no specific message in the error response, display a generic error message
                        this.$toastr.e('An error occurred while updating the customer.');
                    }
                });


        },

        afterSuccess(response) {
            this.$toastr.s('', response.data.message);
            setTimeout('', location.reload());
        },
        setDefaultGroupId() {
            this.axiosGet(SELECTABLE_CUSTOMER_GROUP).then((response) => {
                let id;
                response.data.filter((item) => {
                    if (item.is_default === 1)
                        id = item.id;
                })
                this.formData.customer_group_id = id;
            })
        },

        addEmailRow() {
            let checkEmptyRows = this.inputEmailRow.filter(
                line => line.number === null
            );
            if (checkEmptyRows.length >= 1 && this.inputEmailRow.length > 0) return;
            this.inputEmailRow.push({
                email: '',
                email_type: '',
            });
        },

        removeEmailRow(rowId) {
            if (!this.removableEmailRow) {
                this.inputEmailRow.splice(rowId, 1);
            }
        },

        addPhoneNumberRow() {
            let checkEmptyRows = this.inputPhoneNumberRow.filter(
                line => line.number === null
            );
            if (checkEmptyRows.length >= 1 && this.inputPhoneNumberRow.length > 0) return;
            this.inputPhoneNumberRow.push({
                phone_number: null,
            });
        },

        removePhoneNumberRow(rowId) {
            if (!this.removablePhoneNumberRow) {
                this.inputPhoneNumberRow.splice(rowId, 1);
            }
        },

        addAddress() {
            this.addAddressRow()
        },
        addAddressRow() {
            let checkEmptyRows = this.inputAddressRow.filter(
                line => line.number === null
            );
            if (checkEmptyRows.length >= 1 && this.inputAddressRow.length > 0) return;
            this.inputAddressRow.push({
                address_title: null,
            });
        },

        removeAddressRow(rowId) {
            if (!this.removableAddressRow) {
                this.inputAddressRow.splice(rowId, 1);
            }
        },
    },
    computed: {
        ...mapState({
            customer: state => state.customer.customer,
        }),

        doctorsOptions() {
            return {
                url: urlGenerator("app/selectable/doctors"),
                queryName: 'search_by_name',
                per_page: 50,
                params: { 'branch_id': this.getBranchOrWarehouseId, },
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            }
        },

        userOptions() {
            return {
                url: urlGenerator("app/selectable/users"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            }
        },
        userCanUpdateCustomer() {
            return this.$can('update_customers');
        },

        ...mapGetters(['getBranchOrWarehouseName']),
        ...mapGetters(['getBranchOrWarehouseId']),
    },
}
</script>

<style>
.required>label:first-child:after {
    color: red;
    content: ' *';
    display: inline;
}

/* Increase the width of the Select2 input */
.select2-container--default .select2-selection--single {
    width: 100%;
    border: 1px solid #ced4da;
}

/* Increase the height of the Select2 input */
.select2-container--default .select2-selection--single {
    height: 40px;
}

/* Increase padding and font size */
.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 5px 10px;
    font-size: 16px;
}
</style>
<style lang="scss" scoped>
fieldset:disabled a.btn {
  pointer-events: all;
}
</style>