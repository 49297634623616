<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('dropdown_list')" icon="briefcase">
            <app-default-button @click="openModal()" v-if="this.$can('create_dropdown_list')"
                :title="$fieldTitle('add', 'dropdown_list', true)" />
        </app-page-top-section>

        <app-table v-if="options.url" :id="table_id" :options="options" @action="triggerActions" />

        <app-dropdown-list-modal v-if="isModalActive" v-model="isModalActive" :selected-url="selectedUrl"
            @close="isModalActive = false" />

        <app-list-items-modal v-if="isListItemsModalActive" v-model="isListItemsModalActive" :list-id="listId"
            :selected-url="selectedUrl" @close="isListItemsModalActive = false" />

        <app-confirmation-modal v-if="confirmationModalActive" icon="trash-2" modal-id="app-confirmation-modal"
            @confirmed="confirmed('dropdown-list-table')" @cancelled="cancelled" />
    </div>
</template>

<script>

import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import ListMixin from "./ListMixin";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { LISTS, LISTS_ITEMS, CUSTOMERS_EXPORT } from "../../../Config/ApiUrl-CPB";
import { urlGenerator } from "../../../../common/Helper/AxiosHelper";

export default {
    name: "List",
    mixins: [HelperMixin, ListMixin],
    data() {
        return {
            table_id: 'dropdown-list-table',
            isModalActive: false,
            isListItemsModalActive: false,
            selectedUrl: '',
            totalCustomer: '',
            urlGenerator,
            listId: null,
        }
    },
    extends: CoreLibrary,
    mounted() {
        this.$hub.$on('listItems-edit', (data) => {
            this.isListItemsModalActive = true;
            this.selectedUrl = `${LISTS_ITEMS}/${data.listItemsId}`;
        });
        this.$hub.$on('listItems-delete', (listItemsId) => {
            this.delete_url = `app/list-items/${listItemsId}`
            this.confirmationModalActive = true;
        });
    },
    methods: {
        triggerActions(row, action, active) {
            this.listId = row.id;
            if (action.title === this.$t('edit')) {
                this.selectedUrl = `${LISTS}/${row.id}`;
                this.isModalActive = true;
            } else if (action.name === 'add_list_items') {
                this.isListItemsModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },

        openModal() {
            this.isModalActive = true;
            this.selectedUrl = ''
        },

        exportCustomers() {
            window.location = CUSTOMERS_EXPORT;
        },
    }
}
</script>