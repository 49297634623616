import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import {CAMP_REPORT, ORDER_MAX_MIN_PRICE, SELECTABLE_CAMPS} from "../../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            options: {
                name: this.$t('camp_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Camp Name'),
                        type: 'object',
                        key: 'camp_name',
                        modifier: (value) => {
                            return value;
                        }
                    },
                    {
                        title: this.$t('Date of camp'),
                        type: 'object',
                        key: 'date',
                        modifier: (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title: this.$t('branch'),
                        type: 'object',
                        key: 'branch',
                        modifier: (value) => {
                            return value.name;
                        }
                    },
                    {
                        title: this.$t('Registrations'),
                        type: 'expandable-column',
                        key: 'customers',
                        className: 'link text-primary text-decoration-none p-0',
                        componentName: 'app-patients-report', 
                        showIcon: 'eye',
                        hideIcon: 'eye-off',
                        modifier: (customers) => {
                            return {
                                title: customers.length,
                                expandable: true,
                                button: true,
                                visible: true,
                            }
                        }
                    },
                    {
                        title: this.$t('Orders'),
                        type: 'object',
                        key: 'customers',
                        modifier: (customers) => {
                            var orderCount = 0;
                            customers.forEach(({ orders }) => {
                                orderCount += orders.length
                            });
                            return orderCount;
                        }
                    },
                    {
                        title: this.$t('Order Value'),
                        type: 'object',
                        key: 'customers',
                        modifier: (customers) => {
                            var orderValue = 0;
                            customers.forEach(({ orders }) => {
                                orders.forEach(({ grand_total }) => {
                                    orderValue += grand_total
                                });
                            });
                            return numberWithCurrencySymbol(orderValue);
                        }
                    },
                    // {
                    //     title: this.$t('Expand'),
                    //     type: 'expandable-column',
                    //     key: 'branch_or_warehouse',
                    //     className: 'link text-primary text-decoration-none p-0',
                    //     componentName: 'app-practitioner-report',
                    //     showTitle: 'Show',
                    //     hideTitle: 'Hide', 
                    //     showIcon: 'eye',
                    //     hideIcon: 'eye-off',
                    //     modifier: (value, row) => {
                    //         return {
                    //             expandable: true,
                    //             button: true,
                    //             visible: true,
                    //         }
                    //     }
                    // },
                ],
                actionType: "dropdown",
                actions: [],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    {
                        title: "Camp",
                        type: "drop-down-filter",
                        key: "camp",
                        option: [],
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        practitionerList() {
            axiosGet(SELECTABLE_CAMPS).then((response) => {
                let name = this.options.filters.find(element => element.key === 'camp');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.camp_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(CAMP_REPORT, this.updateMinMaxPriceUrl);
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(CAMP_REPORT, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
        this.practitionerList();
    }
}