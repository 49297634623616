<template>
    <div class="content-wrapper">
        <div v-if="this.$can('create_stock_location')" class="d-flex align-items-center justify-content-between">
            <app-breadcrumb :page-title="$t('Stock Location')"/>
            <button
                type="button"
                class="btn btn-primary btn-with-shadow mb-4"
                @click="openModal()">
                {{ $t('Add Location') }}
            </button>
        </div>

        <app-table
            :id="table_id"
            :v-if="options.url"
            :options="options"
            @action="triggerActions"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            :firstButtonName="$t('yes')"
            modal-class="warning"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('stock-location-table')"
            @cancelled="cancelled"
        />

        <app-stock-location-create-edit-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />
    </div>
</template>

<script>

import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import {DELETE_STOCK_LOCATION, STOCK_LOCATIONS} from "../../../../Config/ApiUrl-CP";
import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import StockLocationMixin from "../../../Mixins/Stock/StockLocationMixin";

export default {
    name: "StockLocation",
    mixins: [DatatableHelperMixin, HelperMixin, StockLocationMixin],
    data() {
        return {
            table_id: 'stock-location-table',
            isModalActive: false,
            confirmationModalActive: false,
            selectedUrl: '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl = `${STOCK_LOCATIONS}/${row.id}`;
                this.isModalActive = true;
            } else if (action.title === this.$t('delete')) {
                this.confirmationModalActive = true;
                this.delete_url = DELETE_STOCK_LOCATION + row.id;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl = ''
        },
    }
}
</script>