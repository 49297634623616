import {LOT, STOCK_LOCATIONS} from "../../../Config/ApiUrl-CP";
import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal} from "../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            options: {
                name: this.$t('stock_location'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Location'),
                        type: 'custom-html',
                        key: 'location',
                        modifier: (location) => `<p class=" pb-0">${location}</p>`,
                    },
                    {
                        title: this.$t('branch_or_warehouse'),
                        type: 'custom-html',
                        key: 'branch_or_warehouse',
                        modifier: value => value ? `<p class="pb-0">${value.name} <span class="text-${ value.type.toLowerCase() === 'branch' ? 'warning' : 'info' }">(${value.type[0].toUpperCase()})</span></p>` : ''
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'actions',
                    }
                ],
                actionType: "dropdown",
                actions: [
                    //stock adjustment update shouldn't possible
                
                    {
                        title: this.$t('edit'),
                        type: 'modal',
                        name: 'edit',
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        name: 'delete',
                        modifier: () => this.$can('delete_stock_location')
                    },
                ],
                filters: [],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(STOCK_LOCATIONS);
    },
    watch: {
        getBranchOrWarehouseId(){
            this.updateUrl(STOCK_LOCATIONS);
        },
    },
}
