import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { DOCTORS } from "../../Config/ApiUrl-CPB";
import { mapActions, mapGetters } from "vuex";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Full Name'),
                        type: 'text',
                        key: 'full_name',
                    },
                    {
                        title: this.$t('Mobile No.'),
                        type: 'text',
                        key: 'mobile_no',
                    },
                    {
                        title: this.$t('specialisation'),
                        type: 'custom-html',
                        key: 'specialisation',
                        modifier: (value) =>
                            value ? `<small class="text-capitalize bg-success px-3 py-1 text-white font-weight-bold" style="border-radius: 8rem;">${value.item_label}</small>`:'--'
                    },
                    {
                        title: this.$t('address'),
                        type: 'text',
                        key: 'address',
                    },
                    {
                        title: this.$t('city'),
                        type: 'text',
                        key: 'city',
                    },
                    {
                        title: this.$t('branch'),
                        type: 'custom-html',
                        key: 'branch_name',
                        modifier: (value) => value ? `<p>${value}</p>` : '-',
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('update_camps'),
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        icon: 'trash-2',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: DOCTORS,
                        name: 'delete',
                        modifier: () => this.$can('delete_camps')

                    }
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    methods: {
        ...mapActions(['setDoctorsByBranchOrWarehouseId']),
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(DOCTORS);
    },
    watch: {
        getBranchOrWarehouseId: {
            immediate: false,
            handler() {
                this.updateUrl(DOCTORS);
            }
        }
    },
}
