import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import {SALES_REPORT, ORDER_MAX_MIN_PRICE, SELECTABLE_USERS, GENERATE_INVOICE,GENERATE_PERFOMA_INVOICE} from "../../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            filterHtml: '',
            options: {
                name: this.$t('referral_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Sr No.'),
                        type: 'object',
                        key: 'sr_no',
                        modifier: (value) => value 
                    },
                    {
                        title: this.$t('Order ID'),
                        type: 'component',
                        key: 'invoice_number',
                        componentName: 'app-invoice-number'
                    },
                    {
                        title: this.$t('date'),
                        type: 'custom-html',
                        key: 'created_at',
                        modifier: value => value ? `<span>${formatDateToLocal(value)}</span>` : ''
                    },
                    {
                        title: this.$t('customer'),
                        type: 'custom-html',
                        key: 'customer',
                        modifier: (row) => row.full_name ? `<a href="${urlGenerator('customer/view/'+row.id)}" class="redirectTo">${row.full_name}</a>` :'-',
                    },
                    {
                        title: this.$t('branch_or_warehouse'),
                        type: 'custom-html',
                        key: 'branch_or_warehouse',
                        modifier: value => value ? `<span>${value.name} <span class="text-${value.type.toLowerCase() === 'branch' ? 'warning' : 'info'}">(${value.type[0].toUpperCase()})</span></span>` : ''
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'object',
                        key: 'created_by',
                        modifier: (created_by) => (created_by?.full_name)
                    },
                    {
                        title: this.$t('Order Value'),
                        type: 'custom-html',
                        key: 'grand_total',
                        titleAlignment: 'right',
                        modifier: value => `<p class="text-right mb-0">${numberWithCurrencySymbol(value)}</p>`
                    },
                    {
                        title: this.$t('Order Status'),
                        type: 'custom-html',
                        key: 'status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'custom-html',
                        key: 'payment_status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('ReferredBy'),
                        type: 'object',
                        key: 'referred_by',
                        modifier: (row) => row?.full_name??'--'
                    },
                    {
                        title: this.$t('Commission %'),
                        type: 'object',
                        key: 'ref_doctor_com_per',
                        modifier: (row) => row??'--'
                    },
                    {
                        title: this.$t('Commission'),
                        type: 'object',
                        key: 'ref_doctor_com',
                        accountAble: true,
                        modifier: (row) => row??'--'
                    },
                    {
                        title: this.$t('Referral'),
                        type: 'object',
                        key: 'ref_doctor_status',
                        modifier: (value) => value??'NA'
                    },
                    {
                        title: this.$t('Referral Note'),
                        type: 'object',
                        key: 'ref_doctor_note',
                        modifier: (value) => value??'--'
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('Edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-referral-action-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('orders_edit'),
                    },
                ],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    {
                        title: this.$t('Doctors'),
                        type: 'search-and-select-filter',
                        key: 'referredby',
                        settings: {
                            url: urlGenerator('app/selectable/doctors'),
                            modifire: ({id, full_name}) => ({id, full_name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'full_name'
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        updateUrl(baseUrl, callback){
            const branchIdOnLocalStorage = localStorage.getItem('currentBranchWahrehouseId');
            this.options.url = `${baseUrl}?from=referral&branch_or_warehouse_id=${branchIdOnLocalStorage ?? this.getBranchOrWarehouseId}`
            if (callback) callback();
            this.$hub.$emit(`reload-${this.table_id}`)
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(SALES_REPORT, this.updateMinMaxPriceUrl);
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
        this.$hub.$on('filter-change', (newFilterValues) => {
            this.filterHtml = '';
            let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
            if(filterData?.date?.start) {
                this.filterHtml = '<span class="d-block">Referral Report from '+formatDateToLocal(filterData?.date.start,false,null,0,'DD MMM YYYY')+' to '+formatDateToLocal(filterData?.date.end,false,null,0,'DD MMM YYYY')+'</span>';
            }
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(SALES_REPORT, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
    }
}