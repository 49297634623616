<template>
    <modal id="warehouse-modal" v-model="showModal" :title="generateModalTitle('Workshop')" @submit="submitData"
        :loading="loading" :preloader="preloader">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : BRANCH_OR_WAREHOUSE' @submit.prevent="submitData">

            <app-form-group :label="$t('Workshop Name')" :placeholder="$placeholder('Workshop Name')"
                v-model="formData.name" :required="true" :error-message="$errorMessage(errors, 'name')" />

            <app-form-group type="textarea" :text-area-rows="5" :page="'modal'" :label="$t('Address')" :required="true"
                :error-message="$errorMessage(errors, 'address')" :placeholder="$placeholder('Address')"
                v-model="formData.address" />

            <app-form-group type="number" :page="'modal'" :label="$t('Phone number')" :required="true"
                :error-message="$errorMessage(errors, 'phone_number')" :placeholder="$placeholder('Phone number')"
                v-model="formData.phone_number" />

            <app-form-group type="file" :page="'modal'" :label="$t('Logo')" :required="true"
                :error-message="$errorMessage(errors, 'logo')" :placeholder="$placeholder('Logo')"
                v-model="formData.logo" />

            <app-form-group type="select" :list="WeightUnit" list-value-field="name" :page="'modal'"
                :label="$t('Weight unit')" :required="true" :error-message="$errorMessage(errors, 'weight_unit')"
                :placeholder="$placeholder('Weight Unit')" v-model="formData.weight_unit" />
        </form>
    </modal>
</template>

<script>
import {
    SELECTABLE_BRANCH,
    SELECTABLE_INVOICE_TEMPLATE,
    BRANCH_OR_WAREHOUSE,
    SALES_PEOPLE
} from "../../../../Config/ApiUrl-CPB";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { urlGenerator } from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "WarehouseAddEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        tableType: {
            default: 'workshop',
        },
        branchId: {},
    },
    data() {
        return {
            url: BRANCH_OR_WAREHOUSE,
            managerOptions: {
                url: urlGenerator("app/selectable/managers"),
                query_name: "search_by_first_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, full_name: value }) => ({ id, value }),
            },
            formData: {
                name: '',
                manager_id: '',
                type: 'workshop',
                location: '',
            },
            WeightUnit: [
                { id: '', name: this.$t('select_type') },
                { id: 'kg', name: this.$t('kg') },
                { id: 'lbs', name: this.$t('lbs') },
            ],
            SELECTABLE_BRANCH,
            SELECTABLE_INVOICE_TEMPLATE,
            BRANCH_OR_WAREHOUSE,
            SALES_PEOPLE
        }
    },
    methods: {
        submitData() {
            this.submitFromFixin(this.selectedUrl ? 'patch' : 'post', this.selectedUrl ?? this.url, this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            $('#warehouse-modal').modal('hide');
            this.$emit('warehouse-created');
            this.toastAndReload(data.message, 'branches-and-warehouses-table');
        },
    },
}
</script>
