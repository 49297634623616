import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { CAMPS } from "../../Config/ApiUrl-CPB";
import {mapActions, mapGetters} from "vuex";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Camp Name'),
                        type: 'text',
                        key: 'camp_name',
                    },
                    {
                        title: this.$t('date'),
                        type: 'custom-html',
                        key: 'date',
                        modifier: (value) =>
                            `<small class="text-capitalize bg-success px-3 py-1 text-white font-weight-bold" style="border-radius: 8rem;">${value}</small>`
                    },
                    {
                        title: this.$t('address'),
                        type: 'text',
                        key: 'address',
                    },
                    {
                        title: this.$t('city'),
                        type: 'text',
                        key: 'city',
                    },
                    {
                        title: this.$t('branch'),
                        type: 'custom-html',
                        key: 'branch',
                        modifier: (value) => value?.name ? `<p>${value.name}</p>` : '-',
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('update_camps'),
                    },
                    {
                        title: this.$t('delete'),
                        type: 'modal',
                        icon: 'trash-2',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: CAMPS,
                        name: 'delete',
                        modifier: () => this.$can('delete_camps')

                    }
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    methods: {
        ...mapActions(['setCampsByBranchOrWarehouseId']),
    },
    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(CAMPS);
    },
    watch: {
        getBranchOrWarehouseId: {
            immediate: false,
            handler() {
                this.updateUrl(CAMPS);
            }
        }
    },
}
