<template>
    <modal id="cppayment-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Update CP Payment Status'"
           @input="closeModal"
           @submit="submitData">

        <form ref="form" :data-url='UPDATE_CPPAYMENT_STATUS+orderId' @submit.prevent="submitData">

            <div class="row">

                <div class="col-12 mb-4">
                    <label for="paymentStatus" class="form-label">Payment Status:</label>
                    <app-input type="select" :list="paymentStatusOptions" list-value-field="value"
                            v-model="formData.paymentStatus" :required="false" :placeholder="$placeholder('Payment Status')" />
                </div>
                <app-input
                    v-model="formData.cpinvoiceno"
                    type="text"
                    class="d-block col-12 mb-3"
                    :label="'CP Invoice No.'"
                    :placeholder="$placeholder('CP Invoice No.')"
                    :required="false"
                    :error-message="$errorMessage(errors, 'cpinvoiceno')"
                />
                <app-input
                    type="textarea"
                    class="d-block col-12"
                    :text-area-rows="5"
                    :placeholder="$placeholder('payment note')"
                    v-model="formData.paymentNote"
                />

            </div>

        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { UPDATE_CPPAYMENT_STATUS, UPDATE_DUE_PAYMENT, SELECTABLE_DROPDOWN, SELECTABLE_PAYMENT_STATUSES} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "CPpaymentStatusModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{},
    },
    data() {
        return {
            numberWithCurrencySymbol,
            UPDATE_CPPAYMENT_STATUS,
            showNote: true,
            loading: false,
            paymentStatusOptions: [],
            formData: {
                isEdit: false,
                cpinvoiceno: (this.edititeminfo?.cp_invoice_no)??'',
                paymentStatus: (this.edititeminfo?.cp_payment_status?.id)??0,
                paymentNote: (this.edititeminfo?.cp_payment_note)??'',
            }
        }
    },
    methods: {
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        paymentListOptions() {
            try {
                axiosGet(`${SELECTABLE_PAYMENT_STATUSES}`).then(response => {
                    this.formData.paymentStatus = response.data[0].id;
                    this.formData.paymentStatus = (this.edititeminfo?.cp_payment_status?.id)??response.data[0].id;
                    for (let item of response.data) {
                        this.paymentStatusOptions.push({
                            id: item.id,
                            value: item.translated_name,
                        });
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#cppayment-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.msg);
            if(data.status) {
                setTimeout(() => {
                    window.location.href = urlGenerator('order/details/'+this.orderId);
                }, 1000);
            } else {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {},
    created() {
        this.paymentListOptions();
    }
}
</script>
