<template>
    <modal id="custom-insoles-modal" size="extra-large" v-model="showModal" :loading="loading" :preloader="preloader"
        title="Insoles Customization Details" :invoice="invoice" :item="item" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOM_INSOLES_CUSTOMIZATION' @submit.prevent="submitData">
            <div class="container">
                <div class="row mb-3">
                    <div class="col-md-4 row">
                        <div class="col-md-6">
                            <label for="Order">Order:</label>
                        </div>
                        <div class="col-md-6">{{ invoice.invoice_number }}</div>
                    </div>
                    <div class="col-md-4 row text-center">
                        <div class="col-md-6">
                            <label for="Qty">Qty:</label>
                        </div>
                        <div class="col-md-6">{{ item.quantity }}</div>
                    </div>
                    <div class="col-md-4 row text-right">
                        <div class="col-md-4">
                            <label for="Date">Date:</label>
                        </div>
                        <div class="col-md-8">{{ item.ordered_at }}</div>
                    </div>
                </div>

                <!-- Composite -->
                <div class="row mb-3">
                    <div class="col-md-2 d-flex align-items-center">
                        <label for="Composite">Composite:</label>
                    </div>
                    <div class="col-md-10">
                        <select class="col-md-11" v-model="formData.composite_id">
                            <option value="">Select Composite</option>
                            <option v-for="option in compositeProducts" :key="option.id" :value="option.id">
                                {{ option.value }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Shoe -->
                <div class="row mb-3">
                    <div class="col-md-4 mb-3">
                        <label for="Shoe type">Shoe type</label>
                        <select id="shoeType"
                            class="ci_shoeType form-control"
                            name="shoe_type" v-model="formData.shoe_type" style="width: 100%;">
                            <option v-for="row in shoeTypeOptions" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <!-- <template>
                            <app-input type="search-and-select" :placeholder="$placeholder('Shoe type')"
                                :inputclearable="false" :options="shoeTypeOptions" v-model="formData.shoe_type" />
                        </template> -->
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="Shoe heel height">Shoe heel height</label>
                        <template>
                            <app-input type="text" :placeholder="$placeholder('Shoe heel height')" :inputclearable="false"
                                v-model="formData.shoe_heel" />
                        </template>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="Shoe brand">Shoe brand</label>
                        <template>
                            <app-input type="text" :placeholder="$placeholder('Shoe brand')" :inputclearable="false"
                                v-model="formData.shoe_brand" />
                        </template>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="Shoe Size">Shoe Size</label>
                        <select id="shoeSize"
                            class="ci_shoeSize form-control"
                            name="shoe_size" v-model="formData.shoe_size" style="width: 100%;">
                            <option v-for="row in shoeSizeOptions" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="Insole template Length">Insole template Length</label>
                        <select id="templateLength"
                            class="ci_templateLength form-control"
                            name="template_length" v-model="formData.template_length" style="width: 100%;">
                            <option v-for="row in templateLengthOrWidthOptions" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <!-- <template>
                            <app-input type="search-and-select" :placeholder="$placeholder('Insole template Length')"
                                :inputclearable="false" :options="templateLengthOrWidthOptions"
                                v-model="formData.template_length" />
                        </template> -->
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="Insole template Width">Insole template Width</label>
                        <select id="templateWidth"
                            class="ci_templateWidth form-control"
                            name="templateWidth" v-model="formData.template_width" style="width: 100%;">
                            <option v-for="row in templateLengthOrWidthOptions" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <!-- <template>
                            <app-input type="search-and-select" :placeholder="$placeholder('Insole template Width')"
                                :inputclearable="false" :options="templateLengthOrWidthOptions"
                                v-model="formData.template_width" />
                        </template> -->
                    </div>
                </div>

                <!-- Linked Orders -->
                <div class="row mb-3">
                    <div class="col-md-12">
                        <app-form-group v-model="formData.linked_orders" type="radio" class="mb-2"
                            :label="$t('Linked Orders')" :list="linkedOrders" :required="true"
                            :error-message="$errorMessage(errors, 'linked_orders')" />
                    </div>
                </div>

                <!-- Mould -->
                <div class="row mb-3">
                    <div class="col-md-6 mb-3">
                        <label for="Mould">Mould</label>
                        <select id="mould"
                            class="ci_mould form-control"
                            name="mould" v-model="formData.mould" style="width: 100%;">
                            <option v-for="row in mouldOptions" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <!-- <template>
                            <app-input type="search-and-select" :placeholder="$placeholder('Mould')" :inputclearable="false"
                                :options="mouldOptions" v-model="formData.mould" />
                        </template> -->
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="Medial wall">Medial wall</label>
                        <select class="form-control" v-model="formData.medial_wall">
                            <option value="">Select Medial wall</option>
                            <option value="low">Low</option>
                            <option value="standard">Standard</option>
                            <option value="high">High</option>
                        </select>
                    </div>
                    <div class="col-md-12">
                        <label for="CustomModule">Custom Module</label>
                        <textarea class="form-control" name="custom_modules" v-model="formData.custom_modules" id="CustomModule" rows="3"
                            placeholder="Enter Custom Module..."></textarea>
                    </div>
                </div>

                <!-- Delivery -->
                <div class="row mb-3">
                    <div class="col-md-12 mb-3">
                        <label for="Delivery">Delivery</label>
                        <select class="form-control" v-model="formData.delivery">
                            <option value="">Select Delivery</option>
                            <option value="in_trial">In trial</option>
                            <option value="finished">Finished</option>
                        </select>
                    </div>
                </div>

                <!-- Layering -->
                <div class="row">
                    <div class="col-md-12 row">
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Layering</div>
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Left</div>
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Right</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition1">Addition 1:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_left_id_1"
                                @change="resetQuantity('layering_left')">
                                <option value="">Select Layering Left</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringLeft">{{ exceedsQuantityLayeringLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_right_id_1"
                                @change="resetQuantity('layering_right')">
                                <option value="">Select Layering Right</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringRight">{{ exceedsQuantityLayeringRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition2">Addition 2:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_left_id_2"
                                @change="resetQuantity('layering_left')">
                                <option value="">Select Layering Left</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringLeft">{{ exceedsQuantityLayeringLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_right_id_2"
                                @change="resetQuantity('layering_right')">
                                <option value="">Select Layering Right</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringRight">{{ exceedsQuantityLayeringRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition3">Addition 3:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_left_id_3"
                                @change="resetQuantity('layering_left')">
                                <option value="">Select Layering Left</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringLeft">{{ exceedsQuantityLayeringLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_right_id_3"
                                @change="resetQuantity('layering_right')">
                                <option value="">Select Layering Right</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringRight">{{ exceedsQuantityLayeringRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition4">Addition 4:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_left_id_4"
                                @change="resetQuantity('layering_left')">
                                <option value="">Select Layering Left</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringLeft">{{ exceedsQuantityLayeringLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_right_id_4"
                                @change="resetQuantity('layering_right')">
                                <option value="">Select Layering Right</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringRight">{{ exceedsQuantityLayeringRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition5">Addition 5:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_left_id_5"
                                @change="resetQuantity('layering_left')">
                                <option value="">Select Layering Left</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringLeft">{{ exceedsQuantityLayeringLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.layering.layering_right_id_5"
                                @change="resetQuantity('layering_right')">
                                <option value="">Select Layering Right</option>
                                <option v-for="option in layeringProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityLayeringRight">{{ exceedsQuantityLayeringRight
                            }}</small>
                        </div>
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4">
                            <label for="sameAsLeft">Same as Left:</label>
                            <input type="checkbox" v-model="sameAsLeft.layering" @change="setRightSameAsLeft('layering', 1)">
                        </div>
                    </div>
                </div>

                <!-- Reinforcements -->
                <div class="row">
                    <div class="col-md-12 row">
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Reinforcements</div>
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Left</div>
                        </div>
                        <div class="col-md-4">
                            <div class="font-weight-bold mb-4">Right</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition1">Addition 1:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_left_id_1"
                                @change="resetQuantity('reinforcements_left', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Left</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsLeft">{{
                                exceedsQuantityReinforcementsLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_right_id_1"
                                @change="resetQuantity('reinforcements_right', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Right</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsRight">{{
                                exceedsQuantityReinforcementsRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition2">Addition 2:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_left_id_2"
                                @change="resetQuantity('reinforcements_left', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Left</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsLeft">{{
                                exceedsQuantityReinforcementsLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_right_id_2"
                                @change="resetQuantity('reinforcements_right', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Right</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsRight">{{
                                exceedsQuantityReinforcementsRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition3">Addition 3:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_left_id_3"
                                @change="resetQuantity('reinforcements_left', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Left</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsLeft">{{
                                exceedsQuantityReinforcementsLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_right_id_3"
                                @change="resetQuantity('reinforcements_right', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Right</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsRight">{{
                                exceedsQuantityReinforcementsRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4">
                            <label for="Addition4">Addition 4:</label>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_left_id_4"
                                @change="resetQuantity('reinforcements_left', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Left</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsLeft">{{
                                exceedsQuantityReinforcementsLeft
                            }}</small>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_right_id_4"
                                @change="resetQuantity('reinforcements_right', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Right</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsRight">{{
                                exceedsQuantityReinforcementsRight
                            }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 row mb-3 d-flex align-items-center">
                        <div class="col-md-4 mb-3">
                            <label for="Addition5">Addition 5:</label>
                        </div>
                        <div class="col-md-4 mb-3">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_left_id_5"
                                @change="resetQuantity('reinforcements_left', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Left</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsLeft">{{
                                exceedsQuantityReinforcementsLeft
                            }}</small>
                        </div>
                        <div class="col-md-4 mb-3">
                            <select class="form-control col-md-12" v-model="formData.reinforcements.reinforcements_right_id_5"
                                @change="resetQuantity('reinforcements_right', 'reinforcements', 1)">
                                <option value="">Select Reinforcements Right</option>
                                <option v-for="option in reinforcementsProducts" :key="option.id" :value="option.id">
                                    {{ option.value }}
                                </option>
                            </select>
                            <small class="text-danger" v-if="exceedsQuantityReinforcementsRight">{{
                                exceedsQuantityReinforcementsRight
                            }}</small>
                        </div>
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4">
                            <label for="sameAsLeft">Same as Left:</label>
                            <input type="checkbox" v-model="sameAsLeft.reinforcements" @change="setRightSameAsLeft('reinforcements', 1)">
                        </div>
                    </div>
                </div>

                <!-- Notes -->
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="Notes">Notes</label>
                        <textarea class="form-control" name="Notes" v-model="formData.notes_left" id="Notes" rows="3"
                            placeholder="Enter Notes..."></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="NotesFromWorkshop">Notes from Workshop</label>
                        <textarea class="form-control" v-model="formData.notes_workshop" name="NotesFromWorkshop" id="NotesFromWorkshop" rows="3"
                            placeholder="Enter Notes from Workshop..."></textarea>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import { CUSTOM_INSOLES_CUSTOMIZATION } from "../../../../../Config/ApiUrl-CP";
import { SELECTABLE_SHOE_TYPE, SELECTABLE_TEMPLATE_LENGTH_OR_WIDTH, SELECTABLE_SHOE_SIZE, SELECTABLE_MOULD } from "../../../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete, urlGenerator } from "../../../../../../common/Helper/AxiosHelper";
import store from "../../../../../../store/Index";

export default {
    name: 'CustomInsolesCreateEditModal',
    props: {
        branchOrWarehouseId: {
            type: Number, // Adjust the type according to your data type
            required: true,
        },
        orderProductId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        orderId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        item: {
            type: Object,
            required: true
        },
        invoice: {
            type: Object,
            required: true
        }
    },
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            CUSTOM_INSOLES_CUSTOMIZATION,
            CustomInsoles: [],
            shoeTypeOptions: [],
            shoeSizeOptions: [],
            mouldOptions: [],
            templateLengthOrWidthOptions: [],
            formData: {
                shoe_type: null,
                shoe_heel: null,
                shoe_brand: null,
                notes_workshop: null,
                notes_left: null,
                linked_orders: 0,
                order_id: null,
                template_length: null,
                template_width: null,
                custom_modules: null,
                medial_wall: '',
                delivery: '',
                composite_id: '',
                layering:{
                    layering_left_id_1: '',
                    layering_left_id_2: '',
                    layering_left_id_3: '',
                    layering_left_id_4: '',
                    layering_left_id_5: '',
                    layering_right_id_1: '',
                    layering_right_id_2: '',
                    layering_right_id_3: '',
                    layering_right_id_4: '',
                    layering_right_id_5: '',
                },
                reinforcements:{
                    reinforcements_left_id_1: '',
                    reinforcements_left_id_2: '',
                    reinforcements_left_id_3: '',
                    reinforcements_left_id_4: '',
                    reinforcements_left_id_5: '',
                    reinforcements_right_id_1: '',
                    reinforcements_right_id_2: '',
                    reinforcements_right_id_3: '',
                    reinforcements_right_id_4: '',
                    reinforcements_right_id_5: '',
                },
            },
            compositeProducts: [],
            layeringProducts: [],
            reinforcementsProducts: [],
            linkedOrders: [
                {
                    id: 1,
                    value: this.$t('Yes')
                },
                {
                    id: 0,
                    value: this.$t('No')
                }
            ],
            dropdownOptions: [
                { label: "Demo", value: "Demo" },
                { label: "Demo 1", value: "Demo 1" },
                { label: "Demo 2", value: "Demo 2" },
                { label: "Demo 3", value: "Demo 3" },
                { label: "Demo 4", value: "Demo 4" },
                { label: "Demo 5", value: "Demo 5" },
            ],
            // shoeTypeOptions: {
            //     url: urlGenerator(SELECTABLE_SHOE_TYPE),
            //     query_name: "search_by_name",
            //     per_page: 50,
            //     loader: "app-pre-loader", // by default 'app-overlay-loader'
            //     modifire: (value) => ({ id: value.id, value: value.item_label }),
            //     // modifire: ({ id, item_label: value }) => ({ id, value }),
            // },
            // templateLengthOrWidthOptions: {
            //     url: urlGenerator(SELECTABLE_TEMPLATE_LENGTH_OR_WIDTH),
            //     query_name: "search_by_name",
            //     per_page: 50,
            //     loader: "app-pre-loader", // by default 'app-overlay-loader'
            //     modifire: (value) => ({ id: value.id, value: value.item_label }),
            //     // modifire: ({ id, item_label: value }) => ({ id, value }),
            // },
            // shoeSizeOptions: {
            //     url: urlGenerator(SELECTABLE_SHOE_SIZE),
            //     query_name: "search_by_name",
            //     per_page: 50,
            //     loader: "app-pre-loader", // by default 'app-overlay-loader'
            //     modifire: (value) => ({ id: value.id, value: value.item_label }),
            //     // modifire: ({ id, item_label: value }) => ({ id, value }),
            // },
            // mouldOptions: {
            //     url: urlGenerator(SELECTABLE_MOULD),
            //     query_name: "search_by_name",
            //     per_page: 50,
            //     loader: "app-pre-loader", // by default 'app-overlay-loader'
            //     modifire: (value) => ({ id: value.id, value: value.item_label }),
            //     // modifire: ({ id, item_label: value }) => ({ id, value }),
            // },
            selectedType: "Demo",
            Mould: [
                { label: '3D scan', value: this.$t('3D scan') },
                { label: 'POP cast', value: this.$t('POP cast') },
            ],
            selectedMould: "3D scan",
            exceedsQuantityLayeringLeft: null,
            exceedsQuantityLayeringRight: null,
            exceedsQuantityReinforcementsLeft: null,
            exceedsQuantityReinforcementsRight: null,
            sameAsLeft: {
                layering: false,
                reinforcements:false
            },
        };
    },
    mounted(){
        // let self = this;
        // $('#shoeType').select2({ placeholder: 'Select Shoe Type',dropdownParent: $("#custom-insoles-modal") }).on("change", function () {
        //     self.onSelectChangeShoeType(this.value);
        // });
        // $('#shoeSize').select2({ placeholder: 'Select Shoe Size',dropdownParent: $("#custom-insoles-modal") }).on("change", function () {
        //     self.onSelectChangeShoeSize(this.value);
        // });
        // $('#templateWidth').select2({ placeholder: 'Select Template Width',dropdownParent: $("#custom-insoles-modal") }).on("change", function () {
        //     self.onSelectChangeTemplateWidth(this.value);
        // });
        // $('#templateLength').select2({ placeholder: 'Select Template Length',dropdownParent: $("#custom-insoles-modal") }).on("change", function () {
        //     self.onSelectChangeTemplateLength(this.value);
        // });
        // $('#mould').select2({ placeholder: 'Select Mould',dropdownParent: $("#custom-insoles-modal") }).on("change", function () {
        //     self.onSelectChangeMould(this.value);
        // });
    },
    created() {
        this.customInsolesOptions();
        this.fetchCustomInsoles();
        this.fetchShoeTypes();
        this.fetchShoeSizes();
        this.fetchTemplateLengthOrWidth();
        this.fetchMould();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        onSelectChangeShoeType(val) {
            this.formData.shoe_type = val;
        },
        onSelectChangeShoeSize(val) {
            this.formData.shoe_size = val;
        },
        onSelectChangeTemplateLength(val) {
            this.formData.template_length = val;
        },
        onSelectChangeTemplateWidth(val) {
            this.formData.template_width = val;
        },
        onSelectChangeMould(val) {
            this.formData.mould = val;
        },
        fetchShoeTypes() {
            this.shoeTypeOptions = [];
            axiosGet(SELECTABLE_SHOE_TYPE).then(response => {
                for (const item of response.data.data) {
                    this.shoeTypeOptions.push({
                        id: item.id,
                        value: item.item_label,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchShoeSizes() {
            this.shoeSizeOptions = [];
            axiosGet(SELECTABLE_SHOE_SIZE).then(response => {
                for (const item of response.data.data) {
                    this.shoeSizeOptions.push({
                        id: item.id,
                        value: item.item_label,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchTemplateLengthOrWidth() {
            this.templateLengthOrWidthOptions = [];
            axiosGet(SELECTABLE_TEMPLATE_LENGTH_OR_WIDTH).then(response => {
                for (const item of response.data.data) {
                    this.templateLengthOrWidthOptions.push({
                        id: item.id,
                        value: item.item_label,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchMould() {
            this.mouldOptions = [];
            axiosGet(SELECTABLE_MOULD).then(response => {
                for (const item of response.data.data) {
                    this.mouldOptions.push({
                        id: item.id,
                        value: item.item_label,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        setRightSameAsLeft(fieldName, rowNumber) {
            if(typeof fieldName != 'undefined') {
                if (this.sameAsLeft[fieldName]) {
                    for (let i = rowNumber; i <= 5; i++) {
                        this.formData[fieldName][`${fieldName}_right_id_${i}`] = this.formData[fieldName][`${fieldName}_left_id_${i}`];
                    }
                } else {
                    for (let i = 1; i <= 5; i++) {
                        this.formData[fieldName][`${fieldName}_right_id_${i}`] = '';
                    }
                }
            }
        },
        resetQuantity(fieldName, field_name, rowNumber) {
            // Set the quantity to 0 for the specified field
            this.formData[fieldName + '_quantity'] = 1;
            this.handleQuantityInput(fieldName);
            this.setRightSameAsLeft(field_name, rowNumber);
        },
        handleQuantityInput(fieldName) {
            const enteredQuantity = this.formData[fieldName + '_quantity'];
            let selectedOption;

            if (fieldName === 'layering_left' || fieldName === 'layering_right') {
                selectedOption = this.layeringProducts.find(item => item.id === this.formData[fieldName + '_id']);
            } else {
                selectedOption = this.reinforcementsProducts.find(item => item.id === this.formData[fieldName + '_id']);
            }

            // Use the respective error message variable based on the field name
            const errorVariableName = 'exceedsQuantity' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

            if (!selectedOption) {
                // The selected option is null, which means the product is not available
                this[errorVariableName] = `No product available for ${fieldName.replace('_', ' ')}.`;
            } else if (!this.validateQuantity(enteredQuantity, selectedOption.quantity)) {
                this[errorVariableName] = `The entered quantity (${enteredQuantity}) exceeds the available quantity (${selectedOption.quantity}) for ${fieldName.replace('_', ' ')}.`;
            } else {
                this[errorVariableName] = null; // Reset the error message if the quantity is valid
            }

            // Update the stockId, branchId, and variantId in formData
            if (selectedOption) {
                this.formData[fieldName + '_stock_id'] = selectedOption.stockId;
                this.formData[fieldName + '_branch_id'] = selectedOption.branchId;
                this.formData[fieldName + '_variant_id'] = selectedOption.variantId;
            }
        },
        validateQuantity(quantity, availableQty) {
            return quantity <= availableQty;
        },
        customInsolesOptions() {
            axiosGet(`app/selectable/custom-insoles-products?branch_or_warehouse_id=${this.branchOrWarehouseId}`).then(response => {
                const data = response.data.data;
                for (const items of data) {
                    const option = {
                        id: items.variant.id,
                        value: items.variant.name,
                        quantity: items.available_qty,
                        stockId: items.id,
                        branchId: items.branch_or_warehouse_id,
                        variantId: items.variant_id,
                    };
                    if (items.variant.product.sub_category_id === 5) {
                        // Product has subcategory 5 (compositeProducts)
                        this.compositeProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 6) {
                        // Product has subcategory 6 (layeringProducts)
                        this.layeringProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 7) {
                        // Product has subcategory 7 (reinforcementsProducts)
                        this.reinforcementsProducts.push(option);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        async fetchCustomInsoles() {
            try {
                await axiosGet(`${CUSTOM_INSOLES_CUSTOMIZATION}/${this.orderProductId}`).then(response => {
                    let resp = response.data[0];
                    // this.formData = data;
                    if(typeof resp != 'undefined' && resp.id > 0) {
                        this.formData.id = resp.id;
                        this.formData.shoe_type = resp.shoe_type;
                        this.formData.shoe_size = resp.shoe_size;
                        this.formData.shoe_heel = resp.shoe_heel;
                        this.formData.shoe_brand = resp.shoe_brand;
                        this.formData.notes_workshop = resp.notes_workshop;
                        this.formData.notes_left = resp.notes_left;
                        this.formData.linked_orders = resp.linked_orders;
                        this.formData.order_id = resp.order_id;
                        this.formData.medial_wall = resp.medial_walls;
                        this.formData.delivery = resp.delivery;
                        this.formData.composite_id = resp.compositeid;
                        this.formData.template_length = resp.template_length;
                        this.formData.template_width = resp.template_width;
                        this.formData.custom_modules = resp.custom_modules;
                        this.formData.mould = resp.moulds;
                        let jsonData = JSON.parse(resp.jsondata);
                        let dataKeysLayering = Object.keys(jsonData.layering);
                        let dataKeysReinforcements = Object.keys(jsonData.reinforcements);
                        if(dataKeysLayering.length > 0) {
                            for (const keys of dataKeysLayering) {
                                this.formData.layering[keys] = jsonData.layering[keys]??'';
                            }
                        }
                        if(dataKeysReinforcements.length > 0) {
                            for (const keys of dataKeysReinforcements) {
                                this.formData.reinforcements[keys] = jsonData.reinforcements[keys]??'';
                            }
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            this.formData.order_id = this.orderId;
            this.formData.order_product_id = this.orderProductId;
            
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#custom-insoles-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'custom-insoles-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
/* Increase the width of the Select2 input */
.select2-container--default .select2-selection--single {
    width: 100%;
    border: 1px solid #ced4da;
}

/* Increase the height of the Select2 input */
.select2-container--default .select2-selection--single {
    height: 40px;
}

/* Increase padding and font size */
.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 5px 10px;
    font-size: 16px;
}
</style>