<template>
  <div class="content-wrapper">
    <div class="">
      <app-page-top-section :title="$t('Log Report')" icon="briefcase">
      </app-page-top-section>
    </div>

    <app-table
        :id="table_id"
        v-if="options.url"
        :options="options"
        @action="triggerActions">
    </app-table>
  </div>

</template>

<script>

import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";
import {mapGetters} from "vuex";
import LogReportMixin from "../../Mixins/LogReportMixin";

export default {
  name: "LogReport",
  mixins: [HelperMixin, LogReportMixin],
  data() {
    return {
      table_id: 'log-report-table',
      isModalActive: false,
      selectedUrl: '',
      totalCustomer: '',
      urlGenerator,
      exportLoading: false,
      branch_or_warehouse_id: this.getBranchOrWarehouseId,
    }
  },
  extends: CoreLibrary,
  methods: {
    triggerActions(row, action, active) {},

    openModal() {
      this.isModalActive = true;
      this.selectedUrl = ''
    },
  },

  computed: {
    ...mapGetters(['getBranchOrWarehouseId']),
  },

  watch: {
    getBranchOrWarehouseId(new_id) {
      this.branch_or_warehouse_id = new_id
    },
  },

}
</script>