<template>
    <modal id="payment-modal" v-model="showModal" :loading="loading" :preloader="preloader" :title="generateModalTitle('payment')"
        @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : PAYMENTS' @submit.prevent="submitData">
            <div class="mb-3 required">
                <label for="verified" class="form-label">Verified :</label>
                <select name="verified" id="verified" v-model="formData.verified" class="form-control">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { PAYMENTS, BRANCHES_OR_WAREHOUSES } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";

export default {
    name: 'PaymentsCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            branchOrWarehouseInputKey: 1,
            PAYMENTS,
            formData: {
                verified: '',
            },
        };
    },
    methods: {
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#payment-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'payments-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>