<template>
    <modal id="referral-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Edit Referral'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='UPDATE_ORDER_REFERRAL+orders.id' @submit.prevent="submitData">

            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-4">
                            <div class="mb-3">
                                <label for="formData_referralStatus" class="form-label">Referral Status:</label>
                                <app-input type="select" :list="referralOptions" list-value-field="name"
                                        v-model="formData.referralStatus" :required="false" :placeholder="$placeholder('Referral Status')" />
                            </div>
                        </div>
                        <div class="col-8">
                            <h3>Referral note</h3>
                            <div class="mb-3">
                                <textarea v-model="formData.referralNote" id="" cols="30" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { UPDATE_ORDER_REFERRAL } from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "ReferralActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orders: {
            require: true
        },
    },
    data() {
        return {
            numberWithCurrencySymbol,
            UPDATE_ORDER_REFERRAL,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                isEdit: false,
                referralStatus: this.orders.ref_doctor_status??'NA',
                referralNote: this.orders.ref_doctor_note??''
                
            },
            referralOptions: [
                { id: 'NA', name: this.$t('NA') },
                { id: 'No', name: this.$t('No') },
                { id: 'Yes', name: this.$t('Yes') },
            ],
        }
    },
    methods: {
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#referral-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.msg);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
}
</script>
