<template>
    <section id="app-camp-report" class="content-wrapper">

        <app-page-top-section :title="$t('camp_report')" icon="briefcase">
            <app-default-button
                @click="exportCampSummary"
                :title="$fieldTitle('export', '', true)"/>
            <app-default-button
                @click="exportCampPatientsSummary"
                :title="$fieldTitle('Export Camp Patients', '', true)"/>
        </app-page-top-section>

        <app-table :id="table_id" :options="options" v-if="options.url" @action=""></app-table>

    </section>
</template>

<script>
import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import CampReportMixins from "./CampReportMixins";
import {CAMP_REPORT_EXPORT} from "../../../../Config/ApiUrl-CP";
import {numberWithCurrencySymbol} from "../../../../Helper/Helper";
import {mapGetters} from "vuex";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name: 'CampReport',
    mixins: [DatatableHelperMixin, CampReportMixins],
    data() {
        return {
            table_id: 'camp-report-table',
            numberWithCurrencySymbol,
            exportLoading: false,
            branch_or_warehouse_id: this.getBranchOrWarehouseId,
        };
    },
    methods: {
        exportCampSummary() {
            window.open(urlGenerator(`${CAMP_REPORT_EXPORT}/?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
        exportCampPatientsSummary() {
            window.open(urlGenerator(`${CAMP_REPORT_EXPORT}/?from=camppatient&branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.branch_or_warehouse_id = new_id
        },
    }
};
</script>
