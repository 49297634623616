<template>
    <modal id="camp-modal" size="extra-large" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('Custom Footwear Customization')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOM_FOOTWEAR_CUSTOMIZATION' @submit.prevent="submitData">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <div class="row">
                            <div class="col-md-6">Order ID:</div>
                            <div class="col-md-6">{{ item.order_id }}</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 text-right">
                        <div class="row">
                            <div class="col-md-6">Order quantity:</div>
                            <div class="col-md-6">{{ item.quantity }}</div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3 form-group">
                        <label for="ArticleDetails">Article details</label>
                        <textarea class="form-control" name="article_details" v-model="formData.article_details" id="article_details" rows="3"
                            placeholder="Enter Article details..."></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label for="LastLeft">Last left</label>
                                <select class="form-control" v-model="formData.last_left">
                                    <option value=""> Select </option>
                                    <option v-for="option in LastsList" :key="option.id" :value="option.id">
                                        {{ option.item_label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="LastRight">Last right</label>
                                <select class="form-control" v-model="formData.last_right">
                                    <option value=""> Select </option>
                                    <option v-for="option in LastsList" :key="option.id" :value="option.id">
                                        {{ option.item_label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label for="CustomLastNumber">Custom last number</label>
                                <input class="form-control" type="text" name="CustomLastNumber" v-model="formData.custom_last_number" id="CustomLastNumber"
                                    placeholder="Enter custom last number">
                            </div>
                            <div class="col-md-6">
                                <label for="CustomLastLocation">Custom last location</label>
                                <input class="form-control" type="text" name="CustomLastLocation" v-model="formData.custom_last_location" id="CustomLastLocation"
                                    placeholder="Enter Custom last location">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label for="ToeSpring">Toe spring</label>
                                <input class="form-control" type="text" v-model="formData.toe_spring" name="ToeSpring" id="ToeSpring"
                                    placeholder="Enter Toe spring">
                            </div>
                            <div class="col-md-4">
                                <label for="HeelRock">Heel rock</label>
                                <input class="form-control" type="text" name="HeelRock" v-model="formData.heel_rock" id="HeelRock"
                                    placeholder="Enter Heel rock">
                            </div>
                            <div class="col-md-4">
                                <label for="SockAllowance">Sock allowance</label>
                                <input class="form-control" type="text" name="SockAllowance" v-model="formData.sock_allowance" id="SockAllowance"
                                    placeholder="Enter Sock allowance">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label for="Shape">Shape</label>
                                <select class="form-control" v-model="formData.shape">
                                    <option value="">select</option>
                                    <option v-for="option in ShapeList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="Fit">Fit</label>
                                <select class="form-control" v-model="formData.fit">
                                    <option value=""> Select</option>
                                    <option v-for="option in FitList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>{{ $t('Mirror larger size') }}</label>
                                <div class="app-radio-group">
                                    <!-- NA Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'MirrorLargerSize'"
                                            :id="'customRadioMirrorLargerSize-NA'"
                                            :checked="formData.MirrorLargerSize === 'NA'"
                                            @change="onMirrorLargerSizeChange('NA')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('NA') }}
                                    </label>

                                    <!-- Yes Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'MirrorLargerSize'"
                                            :id="'customRadioMirrorLargerSize-Yes'"
                                            :checked="formData.MirrorLargerSize === 'Yes'"
                                            @change="onMirrorLargerSizeChange('Yes')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('Yes') }}
                                    </label>

                                    <!-- No Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'MirrorLargerSize'"
                                            :id="'customRadioMirrorLargerSize-No'"
                                            :checked="formData.MirrorLargerSize === 'No'"
                                            @change="onMirrorLargerSizeChange('No')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('No') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label for="ToeSeparator">Toe separator</label>
                                <select class="form-control" v-model="formData.toe_separator">
                                    <option value="">Select</option>
                                    <option v-for="option in ToeSeparatorList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="Flange">Flange</label>
                                <div class="app-radio-group">
                                    <!-- None Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'Flange'"
                                            :id="'customRadioFlange-None'" :checked="formData.Flange === 'None'"
                                            @change="onFlangeChange('None')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('None') }}
                                    </label>

                                    <!-- Medial Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'Flange'"
                                            :id="'customRadioFlange-Medial'" :checked="formData.Flange === 'Medial'"
                                            @change="onFlangeChange('Medial')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('Medial') }}
                                    </label>

                                    <!-- Lateral Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'Flange'"
                                            :id="'customRadioFlange-Lateral'" :checked="formData.Flange === 'Lateral'"
                                            @change="onFlangeChange('Lateral')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('Lateral') }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>{{ $t('Seamless') }}</label>
                                <div class="app-radio-group">
                                    <!-- Yes Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'Seamless'"
                                            :id="'customRadioSeamless-Yes'" :checked="formData.Seamless === 'Yes'"
                                            @change="onSeamlessChange('Yes')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('Yes') }}
                                    </label>

                                    <!-- No Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'Seamless'"
                                            :id="'customRadioSeamless-No'" :checked="formData.Seamless === 'No'"
                                            @change="onSeamlessChange('No')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('No') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label>{{ $t('Toe ring') }}</label>
                                <div class="app-radio-group">
                                    <!-- Yes Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'ToeRing'"
                                            :id="'customRadioToeRing-Yes'" :checked="formData.ToeRing === 'Yes'"
                                            @change="onToeRingChange('Yes')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('Yes') }}
                                    </label>

                                    <!-- No Option -->
                                    <label class="customized-radio radio-default">
                                        <input type="radio" class="radio-inline" :name="'ToeRing'"
                                            :id="'customRadioToeRing-No'" :checked="formData.ToeRing === 'No'"
                                            @change="onToeRingChange('No')" />
                                        <span class="outside">
                                            <span class="inside"></span>
                                        </span>
                                        {{ $t('No') }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="Buckle">Buckle</label>
                                <select class="form-control" v-model="formData.buckle">
                                    <option value="">Select</option>
                                    <option v-for="option in BuckleList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="Backstrap">Backstrap</label>
                                <select class="form-control" v-model="formData.backstrap">
                                    <option value="">Select</option>
                                    <option v-for="option in BackstrapList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- Heel height -->
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <h4>Heel Height</h4>
                    </div>
                    <div class="col-md-12 mb-2">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <h5 class="mb-3">Left</h5>
                                <div class="row form-group">
                                    <div class="col-md-4">
                                        <label for="StartHeight">Start height</label>
                                        <input class="form-control" type="text" v-model="formData.left_start_height" name="StartHeight" id="StartHeight"
                                            placeholder="Enter Start Height">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="EndHeight">End height</label>
                                        <input class="form-control" type="text" v-model="formData.left_end_height" name="EndHeight" id="EndHeight"
                                            placeholder="Enter End Height">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="EndAt">End at</label>
                                        <select class="form-control" v-model="formData.left_end_at">
                                            <option value="">Select</option>
                                            <option v-for="option in EndAtList" :key="option.value" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <h5 class="mb-3">Right</h5>
                                <div class="row form-group">
                                    <div class="col-md-4">
                                        <label for="StartHeight">Start height</label>
                                        <input class="form-control" type="text" v-model="formData.right_start_height"  name="StartHeight" id="StartHeight"
                                            placeholder="Enter Start Height">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="EndHeight">End height</label>
                                        <input class="form-control" type="text" name="EndHeight" v-model="formData.right_end_height" id="EndHeight"
                                            placeholder="Enter End Height">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="EndAt">End at</label>
                                        <select class="form-control" v-model="formData.right_end_at">
                                            <option value="">Select</option>
                                            <option v-for="option in EndAtList" :key="option.value" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <h5 class="mb-3">Internal Compensation</h5>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="Side">Side</label>
                                        <div class="app-radio-group">
                                            <!-- Right Option -->
                                            <label class="customized-radio radio-default">
                                                <input type="radio" class="radio-inline" :name="'Side'"
                                                    :id="'customRadioSide-Right'" :checked="formData.Side === 'Right'"
                                                    @change="onSideChange('Right')" />
                                                <span class="outside">
                                                    <span class="inside"></span>
                                                </span>
                                                {{ $t('Right') }}
                                            </label>

                                            <!-- Left Option -->
                                            <label class="customized-radio radio-default">
                                                <input type="radio" class="radio-inline" :name="'Side'"
                                                    :id="'customRadioSide-Left'" :checked="formData.Side === 'Left'"
                                                    @change="onSideChange('Left')" />
                                                <span class="outside">
                                                    <span class="inside"></span>
                                                </span>
                                                {{ $t('Left') }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="StartHeight">Start height</label>
                                        <input class="form-control" type="text" v-model="formData.internal_start_height" name="StartHeight" id="StartHeight"
                                            placeholder="Enter Start Height">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="EndHeight">End height</label>
                                        <input class="form-control" type="text" v-model="formData.internal_end_height" name="EndHeight" id="EndHeight"
                                            placeholder="Enter End Height">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="EndAt">End at</label>
                                        <select class="form-control" v-model="formData.internal_end_at">
                                            <option value="">Select</option>
                                            <option v-for="option in EndAtList" :key="option.value" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- Products Selection -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Selection</div>
                            </div>
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Quantity</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="Uppers1">Uppers 1</label>
                                <select class="form-control" v-model="formData.uppers1.id">
                                    <option value="">Select</option>
                                    <option v-for="option in upperProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Uppers1Qty">Uppers quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.uppers1.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Lining">Lining</label>
                                <select class="form-control" v-model="formData.lining.id">
                                    <option value="">Select</option>
                                    <option v-for="option in liningProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="LiningQty">Lining quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.lining.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Counter">Counter</label>
                                <select class="form-control" v-model="formData.counter.id">
                                    <option value="">Select</option>
                                    <option v-for="option in stiffenersProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="CounterQty">Counter quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.counter.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="ToePuff">Toe Puff</label>
                                <select class="form-control" v-model="formData.toe_puff.id">
                                    <option value="">Select</option>
                                    <option v-for="option in stiffenersProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="ToePuffQty">Toe Puff quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.toe_puff.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Welt">Welt</label>
                                <select class="form-control" v-model="formData.welt.id">
                                    <option value="">Select</option>
                                    <option v-for="option in weltProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="WeltQty">Welt quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.welt.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Stitching">Stitching</label>
                                <select class="form-control" v-model="formData.stitching.id">
                                    <option value="">Select</option>
                                    <option v-for="option in threadsProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="StitchingQty">Stitching quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.stitching.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Footbed">Footbed</label>
                                <select class="form-control" v-model="formData.footbed.id">
                                    <option value="">Select</option>
                                    <option v-for="option in footbedProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="FootbedQty">Footbed quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.footbed.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Midsole">Midsole</label>
                                <select class="form-control" v-model="formData.midsole.id">
                                    <option value="">Select</option>
                                    <option v-for="option in midsoleProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="MidsoleQty">Midsole quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.midsole.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-12">
                                <label for="Thickness">Thickness</label>
                                <input type="text" class="form-control" v-model="formData.midsole.thickness" name="thickness" id="thickness"
                                    placeholder="Enter Thickness">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Selection</div>
                            </div>
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Quantity</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="Uppers2">Uppers 2</label>
                                <select class="form-control" v-model="formData.uppers2.id">
                                    <option value="">Select</option>
                                    <option v-for="option in upperProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Uppers2Qty">Uppers quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.uppers2.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Interlining">Interlining</label>
                                <select class="form-control" v-model="formData.interlining.id">
                                    <option value="">Select</option>
                                    <option v-for="option in interliningProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="InterliningQty">Interlining quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.interlining.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="CounterShape">Counter Shape</label>
                                <select class="form-control" v-model="formData.counter_shape.id">
                                    <option value="">Select</option>
                                    <option v-for="option in CounterShapeList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="CounterShapeQty">Counter Shape quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.counter_shape.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="FootbedType">Footbed Type</label>
                                <select class="form-control" v-model="formData.footbed_type.id">
                                    <option value="">Select</option>
                                    <option v-for="option in FootbedTypeList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="FootbedTypeQty">Footbed Type quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.footbed_type.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Lace">Lace</label>
                                <select class="form-control" v-model="formData.lace.id">
                                    <option value="">Select</option>
                                    <option v-for="option in laceProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="LaceQty">Lace quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.lace.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="StitchType">Stitch Type</label>
                                <select class="form-control" v-model="formData.stitch_type.id">
                                    <option value="">Select</option>
                                    <option v-for="option in StitchTypeList" :key="option.value" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="StitchTypeQty">Stitch Type quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.stitch_type.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="FootbedWedge">Footbed Wedge</label>
                                <select class="form-control" v-model="formData.footbed_wedge.id">
                                    <option value="">Select</option>
                                    <option v-for="option in wedgesProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="FootbedWedgeQty">Footbed Wedge quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.footbed_wedge.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Outsole">Outsole</label>
                                <select class="form-control" v-model="formData.outsole.id">
                                    <option value="">Select</option>
                                    <option v-for="option in outsoleProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="OutsoleQty">Outsole quantity</label>
                                <input type="number" id="quantity" class="form-control" v-model="formData.outsole.qty"
                                    placeholder="Quantity" />
                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="Thickness">Thickness</label>
                                <input type="text" class="form-control" v-model="formData.outsole.thickness" name="thickness" id="thickness"
                                    placeholder="Enter Thickness">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <label for="FootbedTemplate">Footbed Template</label>
                        <select class="form-control" v-model="formData.footbed_template">
                            <option value="">Select</option>
                            <option v-for="option in footbedTemplateList" :key="option.id" :value="option.id">
                                {{ option.item_label }}
                            </option>
                        </select>
                    </div>
                </div>
                <hr>
                <!-- Additional materials -->
                <div class="row mb-3">
                    <div class="col-md-12 mb-4">
                        <h4>Additional Materials</h4>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Selection</div>
                            </div>
                            <div class="col-md-6">
                                <div class="font-weight-bold mb-4">Quantity</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="Material1">Material 1</label>
                                <select class="form-control" v-model="formData.material1.id">
                                    <option value="">Select</option>
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material1Qty">Material quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.material1.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material2">Material 2</label>
                                <select class="form-control" v-model="formData.material2.id">
                                    <option value="">Select</option>
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material2Qty">Material quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.material2.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material3">Material 3</label>
                                <select class="form-control" v-model="formData.material3.id">
                                    <option value="">Select</option>
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material3Qty">Material quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.material3.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material4">Material 4</label>
                                <select class="form-control" v-model="formData.material4.id">
                                    <option value="">Select</option>
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material4Qty">Material quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.material4.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material5">Material 5</label>
                                <select class="form-control" v-model="formData.material5.id">
                                    <option value="">Select</option>
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Material5Qty">Material quantity</label>
                                <input type="number" id="quantity" class="form-control"
                                    v-model="formData.material5.qty" placeholder="Quantity" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="Notes">Notes</label>
                        <textarea name="notes" v-model="formData.notes" id="notes" rows="3" placeholder="Enter Notes..."></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="WorkshopNotes">Workshop Notes</label>
                        <textarea name="workshop_notes" v-model="formData.workshop_notes" id="workshop_notes" rows="3"
                            placeholder="Enter Workshop Notes..."></textarea>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import { CUSTOM_FOOTWEAR_CUSTOMIZATION,SELECTABLE_DROPDOWN } from "../../../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../../../common/Helper/AxiosHelper";
import store from "../../../../../../store/Index";

export default {
    name: 'CampCreateEditModal',
    props: {
        item: {
            type: Object,
            required: true
        },
        orderId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        orderProductId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },

    },
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            CUSTOM_FOOTWEAR_CUSTOMIZATION,
            camps: [],
            formData: {
                id: '',
                article_details: '',
                last_left: '',
                last_right: '',
                custom_last_number: '',
                custom_last_location: '',
                toe_spring: '',
                heel_rock: '',
                sock_allowance: '',
                shape: '',
                fit: '',
                toe_separator: '',
                buckle: '',
                backstrap: '',
                left_start_height: '',
                left_end_height: '',
                left_end_at: '',
                right_start_height: '',
                right_end_height: '',
                right_end_at: '',
                internal_start_height: '',
                internal_end_height: '',
                internal_end_at: '',
                notes: '',
                workshop_notes: '',
                uppers1: {
                    id: "",
                    qty: "",
                },
                lining: {
                    id: "",
                    qty: "",
                },
                counter: {
                    id: "",
                    qty: "",
                },

                toe_puff: {
                    id: "",
                    qty: "",
                },
                welt: {
                    id: "",
                    qty: "",
                },
                stitching: {
                    id: "",
                    qty: "",
                },
                footbed: {
                    id: "",
                    qty: "",
                },
                midsole: {
                    id: "",
                    qty: "",
                    thickness: "",
                },
                uppers2: {
                    id: "",
                    qty: "",
                },
                interlining: {
                    id: "",
                    qty: "",
                },
                counter_shape: {
                    id: "",
                    qty: "",
                },
                footbed_type: {
                    id: "",
                    qty: "",
                },
                lace: {
                    id: "",
                    qty: "",
                },
                stitch_type: {
                    id: "",
                    qty: "",
                },
                footbed_wedge: {
                    id: "",
                    qty: "",
                },
                outsole: {
                    id: "",
                    qty: "",
                    thickness: ""
                },
                footbed_template: "",
                material1:{
                    id:"",
                    qty:"",
                },
                material2:{
                    id:"",
                    qty:"",
                },
                material3:{
                    id:"",
                    qty:"",
                },
                material4:{
                    id:"",
                    qty:"",
                },
                material5:{
                    id:"",
                    qty:"",
                },
            },
            upperProducts: [],
            liningProducts: [],
            interliningProducts: [],
            stiffenersProducts: [],
            weltProducts: [],
            laceProducts: [],
            threadsProducts: [],
            footbedProducts: [],
            wedgesProducts: [],
            midsoleProducts: [],
            outsoleProducts: [],
            otherProducts: [],
            LastsList: [],
            footbedTemplateList: [],
            dropdownOptions: [
                { label: "Demo", value: "Demo" },
                { label: "Demo 1", value: "Demo 1" },
                { label: "Demo 2", value: "Demo 2" },
                { label: "Demo 3", value: "Demo 3" },
                { label: "Demo 4", value: "Demo 4" },
                { label: "Demo 5", value: "Demo 5" },
            ],
            selectedType: "Demo",
            Mould: [
                { label: '3D scan', value: this.$t('3D scan') },
                { label: 'POP cast', value: this.$t('POP cast') },
            ],
            ShapeList: [
                { label: 'Cosmetic', value:  'Cosmetic' },
                { label: 'As per foot', value: 'As per foot' },
            ],
            FitList: [
                { label: 'Snug', value:  'Snug' },
                { label: 'Tight', value:  'Tight' },
                { label: 'Loose', value:  'Loose' },
            ],
            ToeRingList: [
                { label: 'None', value:  'None' },
                { label: 'Straight', value:  'Straight' },
                { label: 'Cross', value:  'Cross' },
            ],
            BuckleList: [
                { label: 'None', value:  'None' },
                { label: 'Funcational', value:  'Funcational' },
                { label: 'Decorative', value:  'Decorative' },
            ],
            EndAtList: [
                { label: 'Heel', value:  'Heel' },
                { label: 'Midfoot', value:  'Midfoot' },
                { label: 'Before met heads', value:  'Before met heads' },
                { label: 'Full length', value:  'Full length' },
            ],
            BackstrapList: [
                { label: 'None', value:  'None' },
                { label: 'Elastic', value:  'Elastic' },
                { label: 'Velcro', value:  'Velcro' },
                { label: 'Both', value:  'Both' },
            ],
            CounterShapeList: [
                { label: 'Standard', value:  'Standard' },
                { label: 'Stability', value:  'Stability' },
                { label: 'Medial extension', value:  'Medial extension' },
                { label: 'Lateral extension', value:  'Lateral extension' },
            ],
            FootbedTypeList: [
                { label: 'Custom Insole', value:  'Custom Insole' },
                { label: 'Flat', value:  'Flat' },
                { label: 'Wedged', value:  'Wedged' },
            ],
            StitchTypeList: [
                { label: 'Single', value:  'Single' },
                { label: 'Double', value:  'Double' },
            ],
            ToeSeparatorList: [
                { label: 'None', value:  'None' },
                { label: 'Standard', value:  'Standard' },
                { label: 'Thick silicon', value:  'Thick silicon' },
                { label: 'Thick foam', value:  'Thick foam' },
                { label: 'Silicon tube', value:  'Silicon tube' },
            ],
            selectedMould: "3D scan"
        };
    },
    created() {
        this.getLastsLists();
        this.getFootbedTemplateLists();
        this.customFootwearOptions();
        this.fetchFootwearData();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        customFootwearOptions() {
            axiosGet(`app/selectable/custom-footware-products?branch_or_warehouse_id=${this.selectedBranchId}`).then(response => {
                const data = response.data.data;
                for (const items of data) {
                    const option = {
                        id: items.variant.id,
                        value: items.variant.name,
                        quantity: items.available_qty,
                        stockId: items.id,
                        branchId: items.branch_or_warehouse_id,
                        variantId: items.variant_id,
                    };
                    if (items.variant.product.sub_category_id === 8) {
                        this.upperProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 9) {
                        this.liningProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 10) {
                        this.interliningProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 11) {
                        this.stiffenersProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 12) {
                        this.weltProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 13) {
                        this.laceProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 14) {
                        this.threadsProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 15) {
                        this.footbedProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 16) {
                        this.wedgesProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 17) {
                        this.midsoleProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 18) {
                        this.outsoleProducts.push(option);
                    } else if (items.variant.product.sub_category_id === 31) {
                        this.otherProducts.push(option);
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchFootwearData() {
            try {
                axiosGet(`${CUSTOM_FOOTWEAR_CUSTOMIZATION}/show?order_product_id=${this.orderProductId}`).then(response => {
                    let resp = response.data[0];
                    if(resp.id > 0) {
                        this.formData.id = resp.id;
                        this.formData.article_details = resp.article_details;
                        this.formData.last_left = resp.last_left;
                        this.formData.last_right = resp.last_right;
                        this.formData.custom_last_number = resp.custom_last_number;
                        this.formData.custom_last_location = resp.custom_last_location;
                        this.formData.toe_spring = resp.toe_spring;
                        this.formData.heel_rock = resp.heel_rock;
                        this.formData.sock_allowance = resp.sock_allowance;
                        this.formData.shape = resp.shape;
                        this.formData.fit = resp.fit;
                        this.formData.toe_separator = resp.toe_separator;
                        this.formData.buckle = resp.buckle;
                        this.formData.backstrap = resp.backstrap;
                        this.formData.left_start_height = resp.left_start_height;
                        this.formData.left_end_height = resp.left_end_height;
                        this.formData.left_end_at = resp.left_end_at;
                        this.formData.right_start_height = resp.right_start_height;
                        this.formData.right_end_height = resp.right_end_height;
                        this.formData.right_end_at = resp.right_end_at;
                        this.formData.Side = resp.internal_side;
                        this.formData.internal_start_height = resp.internal_start_height;
                        this.formData.internal_end_height = resp.internal_end_height;
                        this.formData.internal_end_at = resp.internal_end_at;
                        this.formData.MirrorLargerSize = resp.mirror_larger_size;
                        this.formData.Flange = resp.flange;
                        this.formData.Seamless = resp.seamless;
                        this.formData.ToeRing = resp.toe_ring;
                        this.formData.footbed_template = resp.footbed_template;
                        this.formData.notes = resp.notes;
                        this.formData.workshop_notes = resp.workshop_notes;

                        let sectionJson = JSON.parse(resp.section_json);
                        let sectionKeys = Object.keys(sectionJson);
                        if(sectionKeys.length > 0) {
                            for (const keys of sectionKeys) {
                                this.formData[keys] = sectionJson[keys];
                            }
                        }
                        
                        let materialJson = JSON.parse(resp.add_mat_json);
                        let materialKeys = Object.keys(materialJson);
                        if(materialKeys.length > 0) {
                            for (const keys of materialKeys) {
                                this.formData[keys] = materialJson[keys];
                            }
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        getLastsLists() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=5`).then(response => {
                    this.LastsList = response.data.data;
                });
            } catch (error) {
                console.error(error);
            }
        }, 
        getFootbedTemplateLists() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=7`).then(response => {
                    this.footbedTemplateList = response.data.data;
                });
            } catch (error) {
                console.error(error);
            }
        },
        onMirrorLargerSizeChange(selectedSize) {
            // Access the selected Size
            this.formData.MirrorLargerSize = selectedSize;
        },
        onFlangeChange(selectedFlange) {
            // Access the selected Flange
            this.formData.Flange = selectedFlange;
        },
        onSeamlessChange(selectedSeamless) {
            // Access the selected Seamless
            this.formData.Seamless = selectedSeamless;
        },
        onToeRingChange(selectedToeRing) {
            // Access the selected ToeRing
            this.formData.ToeRing = selectedToeRing;
        },
        onSideChange(selectedSide) {
            // Access the selected Side
            this.formData.Side = selectedSide;
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            this.formData.orderid = this.orderId;
            this.formData.order_product_id = this.orderProductId;

            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#camp-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'camp-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>