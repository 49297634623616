<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('doctors')" icon="briefcase">
            <app-default-button @click="openModal()" v-if="this.$can('create_doctors')"
                :title="$fieldTitle('add', 'doctors', true)" />
        </app-page-top-section>

        <app-table v-if="options.url" :id="table_id" :options="options" @action="triggerActions" />

        <app-doctor-modal v-if="isModalActive" v-model="isModalActive" :selected-url="selectedUrl"
            @close="isModalActive = false" />

        <app-confirmation-modal v-if="confirmationModalActive" icon="trash-2" modal-id="app-confirmation-modal"
            @confirmed="confirmed('doctors-table')" @cancelled="cancelled" />
    </div>
</template>

<script>

import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import DoctorMixin from "../../Mixins/DoctorMixin";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { DOCTORS, CUSTOMERS_EXPORT } from "../../../Config/ApiUrl-CPB";
import { urlGenerator } from "../../../../common/Helper/AxiosHelper";

export default {
    name: "Doctors",
    mixins: [HelperMixin, DoctorMixin],
    data() {
        return {
            table_id: 'doctors-table',
            isModalActive: false,
            selectedUrl: '',
            totalCustomer: '',
            urlGenerator,
        }
    },
    extends: CoreLibrary,
    methods: {
        triggerActions(row, action, active) {

            if (action.title === this.$t('edit')) {
                this.selectedUrl = `${DOCTORS}/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },

        openModal() {
            this.isModalActive = true;
            this.selectedUrl = ''
        },

        exportCustomers() {
            window.location = CUSTOMERS_EXPORT;
        },
    }
}
</script>