<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('Payments')" icon="briefcase">
        </app-page-top-section>

        <app-table v-if="options.url" :id="table_id" :options="options" @action="triggerActions" />

        <app-payment-modal v-if="isModalActive" v-model="isModalActive" :selected-url="selectedUrl"
            @close="isModalActive = false" />
        
        <app-payment-action-modal v-if="isPaymentActionModalActive" :value="true" :payment-id="paymentId" @modal-close="closeModal" />

        <app-confirmation-modal v-if="confirmationModalActive" icon="trash-2" modal-id="app-confirmation-modal"
            @confirmed="confirmed('payments-table')" @cancelled="cancelled" />
    </div>
</template>

<script>

import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import PaymentsMixin from "./paymentsMixin";
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import { CUSTOMERS_EXPORT,PAYMENTS } from "../../../../Config/ApiUrl-CPB";
import { urlGenerator } from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "Payments",
    mixins: [HelperMixin, PaymentsMixin],
    data() {
        return {
            table_id: 'payments-table',
            isModalActive: false,
            isPaymentActionModalActive: false,
            selectedUrl: '',
            totalCustomer: '',
            urlGenerator,
            paymentId: 0,
        }
    },
    extends: CoreLibrary,
    methods: {
        triggerActions(row, action, active) {

            if (action.title === this.$t('Verify payment')) {
                this.selectedUrl = `${PAYMENTS}/${row.id}`;
                this.isModalActive = true;
            } else if(action.title === this.$t('Edit payment')) {
                this.paymentId = row.id;
                this.isPaymentActionModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },

        openModal() {
            this.isModalActive = true;
            this.selectedUrl = ''
        },

        exportCustomers() {
            window.location = CUSTOMERS_EXPORT;
        },
    }
}
</script>