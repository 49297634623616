<template>
    <div class="row justify-content-center py-5 max-height-450 overflow-auto">
        <div class="col-11 col-lg-10">
            <div class="d-flex mb-primary">
                <table width="100%" class="expandTblClass patient">
                    <thead>
                        <tr>
                            <th>Patients Name</th>
                            <th>Total Order Value</th>
                            <th>Payment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data.customers" :key="index">
                            <td class="datatable-td"> <a :href="urlGenerator(`customer/details/`+item.id)" class="" target="_blank">{{ item.full_name }}</a></td>
                            <td class="datatable-td"> <a :href="urlGenerator(`customer/details/`+item.id+`?tab=Orders`)" class="" target="_blank">{{ numberWithCurrencySymbol(getOrderValue(item)) }}</a></td>
                            <td class="datatable-td" v-html="getPaymentStatus(item.orders)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {numberWithCurrencySymbol} from "../../../../Helper/Helper";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "PatientsReport",
    mixins: [FormHelperMixins],
    props: ['data'],
    data() {
        return {
            numberWithCurrencySymbol,
            ordersvalue:0,
        }
    },
    methods:{
        getPaymentStatus(value){
            if (Array.isArray(value) && value.length > 0) {
                let paymentPartial = false;
                let paymentPending = false;
                for (let v = 0; v < value.length; v++) {
                    if(value[v]['payment_status_id'] == 59) {
                        paymentPartial = true;
                        break;
                    }
                    if(!paymentPending) {
                        if(value[v]['payment_status_id'] == 58) {
                            paymentPending = true;
                        }
                    }
                }
                if(paymentPartial) {
                    return '<span class="font-weight-bold text-warning">Partial</span>';
                } else if(paymentPending) {
                    return '<span class="font-weight-bold text-danger">Pending</span>';
                } else {
                    return '<span class="font-weight-bold text-success">Completed</span>';   
                }
            } else {
                return '<span class="font-weight-bold text-muted">N/A</span>';
            }
        },
        getOrderValue(item) {
            let price = 0;
            item.orders.forEach(({ grand_total }) => {
                price += grand_total
            });
            return price;
        }
    }
}
</script>
<style>
.datatable table.expandTblClass.patient .table-expanded-column button svg{
    display: none;
}
.datatable table.expandTblClass.patient tbody tr td.datatable-td {
 padding: 1.5rem 1rem !important;
}
</style>