<template>
    <modal id="technician-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="formData.isEdit == true ? 'Edit Workflow' : 'Add Workflow'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='SAVE_ORDER_TECHNICIAN+orderId' @submit.prevent="submitData">

            <div class="row">
                <div class="form-element col-12 col-md-12 mb-4">
                    <div class="mb-3">
                        <label for="technician" class="form-label">User</label>
                        <app-input type="select" :list="technicianOptions" list-value-field="value"
                                v-model="formData.technicianId" :required="true" :placeholder="$placeholder('Technician')" />
                    </div>
                </div>
                <div class="col-4">
                    <app-form-group
                        v-model="formData.estimated_hours"
                        type="number"
                        class="mb-2"
                        :label="$t('Estimated Hours')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'estimated_hours')"
                    />
                </div>
                <div class="col-4">
                    <label for="work_status" class="form-label">Work Status</label>
                    <app-input type="select" :list="workStatusOptions" list-value-field="value"
                            v-model="formData.workStatus" :required="true" :placeholder="$placeholder('Work Status')" />
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <app-form-group type="date" v-model="formData.assignedDate" :label="$t('Assigned Date')"
                :placeholder="$placeholder($t('Assigned Date'))" />
                    </div>
                </div>
            </div>
        </form>
        </br></br></br></br></br></br></br>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { SAVE_ORDER_TECHNICIAN, GET_ORDER_TECHNICIAN, SELECTABLE_PRACTITIONER} from "../../../../Config/ApiUrl-CP";
import { SELECTABLE_TECHNICIAN } from '../../../../Config/ApiUrl-CPB';
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "TechnicianActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{},
        edittechinfo:{},
        itemidx:{},
    },
    data() {
        return {
            numberWithCurrencySymbol,
            SAVE_ORDER_TECHNICIAN,
            GET_ORDER_TECHNICIAN,
            showNote: true,
            loading: false,
            totalAmt: 0,
            formData: {
                isEdit: false,
                orderTechId: 0,
                technicianId: null,
                workStatus: null,
                currItemCount: this.itemidx,
                assignedDate: this.getFormattedCurrentTimeAndDate(),
                estimated_hours: this.edititeminfo?.variant?.estimated_hours??0,
                order_product_id: this.edititeminfo?.id??0,
            },
            usedEstimationHours: 0,
            technicianOptions: [],
            workStatusOptions: [],
        }
    },
    methods: {
        technicianListOptions() {
            axiosGet(SELECTABLE_TECHNICIAN).then(response => {
                for (const item of response.data.data) {
                    this.technicianOptions.push({
                        id: item.id+'-T',
                        value: item.technician_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });

            axiosGet(SELECTABLE_PRACTITIONER).then(response => {
                for (const item of response.data.data) {
                    this.technicianOptions.push({
                        id: item.id+'-P',
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        workStatusListOptions() {
            axiosGet('app/selectable-statuses?type=workStatus').then(response => {
                for (const item of response.data.data) {
                    this.workStatusOptions.push({
                        id: item.id,
                        value: item.translated_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getOrderTechnicians() {
            axiosGet(GET_ORDER_TECHNICIAN+'?orderid='+this.orderId+'&orderproductid='+this.formData.order_product_id).then(response => {
                this.usedEstimationHours = response.data.resp.used_estimation_hours;
                this.formData.estimated_hours -= this.usedEstimationHours;
                this.formData.estimated_hours = (this.formData.estimated_hours < 0)?0:this.formData.estimated_hours;
            }).catch(error => {
                console.error(error);
            });
        },
        formatDate(dateObj) {
            if(dateObj != '' && dateObj != '0000-00-00') {
                dateObj = new Date(dateObj);
                const date = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate()}
                return `${date.year}-${date.month <= 9 ? '0' : ''}${date.month}-${date.day <= 9 ? '0' : ''}${date.day}`;
            }
            return '';
        },
        convertDateObjToStr(dateObj) {
            if (typeof dateObj === 'string') return dateObj;
            const date = {
                year: dateObj.getFullYear(),
                month: dateObj.getMonth() + 1,
                day: dateObj.getDate(),
                hour: dateObj.getHours(),
                minute: dateObj.getMinutes(),
                second: dateObj.getSeconds()
            }

            return `${date.year}-${date.month <= 9 ? '0' : ''}${date.month}-${date.day <= 9 ? '0' : ''}${date.day} ${date.hour}:${date.minute}:${date.second}`;
        },
        getFormattedCurrentTimeAndDate() {
            const currentDate = new Date();
            return this.convertDateObjToStr(currentDate);
        },
        submitData() {
            const self = this;
            Object.entries(this.formData).forEach(function([key, value]) {
                if(['assignedDate'].includes(key) && value != null) {
                    value = self.formatDate(value);
                }
                self.formData[key] = value;
            });
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#technician-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {
        
    },
    created() {
        this.technicianListOptions();
        this.workStatusListOptions();
        if(this.edittechinfo?.id > 0) {
           this.formData.estimated_hours = this.edittechinfo.estimated_hours;
           if(this.edittechinfo.user_type == 1) {
               this.formData.technicianId = this.edittechinfo.techid+'-P'; 
           } else {
               this.formData.technicianId = this.edittechinfo.techid+'-T'; 
           } 
           this.formData.workStatus = this.edittechinfo.work_status_id; 
           this.formData.orderTechId = this.edittechinfo.id; 
           this.formData.isEdit = true; 
        } else {
            this.getOrderTechnicians();
        }
    }
}
</script>
