<template>
    <modal id="user-modal" size="large" v-model="showModal" :title="generateModalTitle('user')" @submit="submit"
        :loading="loading" :preloader="preloader">
        <form ref="form" :data-url='selectedUrl || getBaseURL' enctype="multipart/form-data">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <app-form-group type="email" :label="$t('email')" :placeholder="$placeholder('email')"
                        v-model="formData.email" :disabled="true" :required="true"
                        :error-message="$errorMessage(errors, 'email')" />
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <app-form-group type="password" :label="$t('password')" :placeholder="$placeholder('password')"
                        v-model="formData.password" :required="true" :error-message="$errorMessage(errors, 'password')" />
                </div>
                <div class="col-12 col-sm-2 col-md-2">
                    <app-form-group type="select" :list="Prefix" list-value-field="name" :page="'modal'"
                        :label="$t('Prefix')" :required="true" :error-message="$errorMessage(errors, 'prefix')"
                        :placeholder="$placeholder('Prefix')" v-model="formData.prefix" />
                </div>
                <div class="col-12 col-sm-5 col-md-5">
                    <app-form-group :label="$fieldTitle('first', 'name')" :placeholder="$placeholder('first', 'name')"
                        v-model="formData.first_name" :required="true"
                        :error-message="$errorMessage(errors, 'first_name')" />
                </div>
                <div class="col-12 col-sm-5 col-md-5">
                    <app-form-group :label="$fieldTitle('last', 'name')" :placeholder="$placeholder('last', 'name')"
                        v-model="formData.last_name" :required="true" :error-message="$errorMessage(errors, 'last_name')" />
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-6">
                    <app-form-group :label="$t('choose_a_role')" class="margin-right-8" type="search-and-select"
                        :placeholder="$placeholder('choose_a_role')" :options="roleLists" v-model="formData.role"
                        :error-message="$errorMessage(errors, 'roles')" />
                </div> -->
                <div class="col-12 col-sm-12 col-md-12">
                    <label>{{ $t('branch_or_warehouse') }}</label>
                    <app-input type="multi-select" class="margin-right-8" :placeholder="$t('Select branch_or_warehouse')"
                        :inputclearable="false" :list="branchesOrWarehousesOptions"
                        v-model="formData.branch_or_warehouse_id"
                        :error-message="$errorMessage(errors, 'branch_or_warehouse_id')" />
                    <!-- <app-form-group :label="$t('branch_or_warehouse')" class="margin-right-8" type="search-and-select"
                        :placeholder="$placeholder('branch_or_warehouse')" :options="branchesOrWarehousesOptions"
                        v-model="formData.branch_or_warehouse_id"
                        :error-message="$errorMessage(errors, 'branch_or_warehouse_id')" /> -->
                </div>
            </div>
            <app-form-group :page="'modal'" :label="$t('Phone number')" :required="true"
                :error-message="$errorMessage(errors, 'phone_number')" :placeholder="$placeholder('Phone number')"
                v-model="formData.phone_number" />

            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <label for="user-signature-input">{{ $t('signature') }}</label>
                    <app-input v-if="imageUploaderBoot" id="user-signature-input" type="custom-file-upload"
                        v-model="user_signature" :generate-file-url="false" :label="$t('change user signature')"
                        :required="false" :error-message="$errorMessage(errors, 'user_signature')" />
                        <button type="button" v-if="this.user_signature" class="btn btn-danger btn-sm" @click="() => deleteSignature()">Delete</button>
                    <!-- <app-form-group type="file" :page="'modal'" :label="$t('signature')" :required="true"
            :error-message="$errorMessage(errors, 'signature')" :placeholder="$placeholder('signature')"
            v-model="formData.signature" /> -->
                </div>
            </div>

            <app-form-group type="textarea" :text-area-rows="5" :page="'modal'" :label="$t('Designation')" :required="true"
                :error-message="$errorMessage(errors, 'designation')" :placeholder="$placeholder('Designation')"
                v-model="formData.designation" />

            <app-form-group :page="'modal'" :label="$t('Medical license number')" :required="true"
                :error-message="$errorMessage(errors, 'medical_license_number')"
                :placeholder="$placeholder('Medical license number')" v-model="formData.medical_license_number" />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../Mixin/Global/ModalMixin";
import { TENANT_USERS } from "../../../Config/apiUrl";
import { TENANT_ROLES } from "../../../Config/apiUrl";
import { SELECTABLE_BRANCHES_OR_WAREHOUSES } from "../../../../tenant/Config/ApiUrl-CP";
import { axiosGet, urlGenerator } from "../../../Helper/AxiosHelper";
import { formDataAssigner } from "../../../../common/Helper/Support/FormHelper";

export default {
    name: "UserModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: ['alias'],
    data() {
        return {
            imageUploaderBoot: true,
            user_signature: null,
            userSignatureChanged: false,
            userSignaturePath: '',
            formData: {
                branch_or_warehouse_id: [],
                user_signature: null,
            },
            Prefix: [
                { id: '', name: this.$t('Select Prefix') },
                { id: 'Mr.', name: this.$t('Mr.') },
                { id: 'Ms.', name: this.$t('Ms.') },
                { id: 'Mrs.', name: this.$t('Mrs.') },
                { id: 'Dr.', name: this.$t('Dr.') },
            ],
            role: '',
            roleLists: {
                url: urlGenerator(TENANT_ROLES),
                query_name: "search",
                per_page: 10,
                loader: "app-pre-loader",
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: false,
            },
            branchesOrWarehousesOptions: [],
        }
    },
    watch: {
        user_signature(newSignature, oldSignature) {
            // if (!this.selectedUrl) return;
            this.userSignatureChanged = !this.userSignatureChanged;
            this.formData.user_signature = newSignature;
        }
    },
    methods: {
        deleteSignature() {
            this.user_signature = null;
            this.userSignaturePath = '';
            this.formData.signature = "";
            this.formData.user_signature = "";
            this.userSignatureChanged = true;
            // this.toastException("deleted");
            // axiosDelete(`app/delete-signature/${signatureId}`).then((response) => {
            //     // setTimeout(() => {
            //     //     window.location.reload();
            //     // }, 1500);
            //     this.toastAndReload(response.data.message);
            // }).catch((error) => {
            //     // Handle any errors that occur during the request
            //     console.error(error);
            // });
        },
        submit() {
            // Convert branch_or_warehouse_id to a comma-separated string
            if (!Array.isArray(this.formData.branch_or_warehouse_id)) {
                this.formData.branch_or_warehouse_id = [this.formData.branch_or_warehouse_id];
            }

            const branchWarehouseIds = this.formData.branch_or_warehouse_id
                // .map((item) => item[0])
                .join(',');

            // console.log("Branch:", branchWarehouseIds)
            let url = this.selectedUrl ? this.selectedUrl : this.getBaseURL;
            let formData = { ...this.formData };

            formData = formDataAssigner(new FormData, formData);

            // Append the branch_or_warehouse_id as a comma-separated string
            formData.append("branch_or_warehouse_id", branchWarehouseIds);

            if (this.user_signature === null) {
                // Append user_signature
                formData.append("user_signature", '');
            } else {
                // Append user_signature
                formData.append("user_signature", this.formData.user_signature);
            }

            if (this.selectedUrl) formData.append('_method', 'patch')
            this.submitFromFixin('post', url, formData);
            this.preloader = true;
        },

        afterSuccess({ data }) {
            this.toastAndReload(data.message, 'user-table')
            $('#user-modal').modal('hide');
        },
        fetchBranchesOrWarehousesOptions() {
            axiosGet(SELECTABLE_BRANCHES_OR_WAREHOUSES).then(response => {
                console.log('response:', response.data.data);

                for (const item of response.data.data) {
                    this.branchesOrWarehousesOptions.push({
                        id: item.id,
                        value: item.name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        afterSuccessFromGetEditData(response) {
            this.formData = response.data;
            this.user_signature = this.formData.signature;
            this.preloader = false;
        }
    },
    created() {
        this.fetchBranchesOrWarehousesOptions();
    },
    computed: {
        getBaseURL() {
            return {
                tenant: TENANT_USERS,
                app: `admin/auth/users`
            }[this.alias];
        }
    }
}
</script>

