<template>
    <modal id="dropdown-list-modal" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('dropdown_list')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : LISTS' @submit.prevent="submitData">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <div class="form-group">
                        <label for="module_id">Module</label>
                        <select name="module_id" id="module_id" class="form-control" required v-model="formData.module_id">
                            <option value="">Select a module</option>
                            <option v-for="option in lists" :key="option.id" :value="option.id">
                                {{ option.module_name }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'module_id')">{{ $errorMessage(errors,
                            'module_id')
                        }}</small>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <div class="form-group">
                        <label for="list_name">List Name</label>
                        <input type="text" v-model="formData.list_name" name="list_name" id="list_name" class="form-control"
                            required placeholder="Enter List Name">
                        <small class="text-danger" v-if="$errorMessage(errors, 'list_name')">{{ $errorMessage(errors,
                            'list_name')
                        }}</small>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { LISTS, MODULE } from "../../../Config/ApiUrl-CPB";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../common/Helper/AxiosHelper";
import store from "../../../../store/Index";

export default {
    name: 'ListCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            LISTS,
            MODULE,
            lists: [],
            formData: {
                module_id: '',
            },
        };
    },
    created() {
        this.fetchLists();
    },
    computed: {
        selectedBranchId() {
            return store.state.auth.branch_or_warehouse_id;
        }
    },
    methods: {
        fetchLists() {
            try {
                axiosGet(`${MODULE}`).then((response) => {
                    console.log(response.data);
                    this.lists = response.data;
                    // this.formData.module_id = this.lists[0].id;
                }).catch((error) => {
                    this.$toastr.e(error);
                })
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#dropdown-list-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'dropdown-list-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>