<template>
    <div class="content-wrapper">
        <app-page-top-section
            :title="$t('app_settings')"
            icon="settings"
        />

        <app-tab
            :tabs="tabs"
            icon="settings"
        />
    </div>
</template>

<script>
export default {
    name: "TenantSettingLayout",
    data() {
        return {
            tabs: [
                {
                    name: this.$t('general'),
                    title: this.$t('general'),
                    component: "app-tenant-general-settings",
                    permission: this.$can('view_settings')
                },
                {
                    name: this.$t('email_setup'),
                    title: this.$t('email_setup'),
                    component: "app-delivery-settings",
                    props: {alias: 'app'},
                    permission: Boolean(this.$can('view_delivery_settings') && Boolean(tenant.is_single))
                },
                {
                    "name": this.$t('sms'),
                    "title": this.$t('sms_setting'),
                    "component": "sms-setting",
                    "permission": ""
                },
                {
                    "name": this.$t('sms_template'),
                    "title": this.$t('sms_template'),
                    "component": "sms-template",
                    "permission": "",
                    headerButton: {
                        label: this.$fieldTitle('add', 'template', true),
                        class: 'btn btn-primary',
                    }
                },
                {
                    name: this.$t('notification'),
                    title: this.$t('notification'),
                    component: "app-tenant-notification-settings",
                    permission: this.$can('view_notification_settings'),
                    props: { specific: !Boolean(tenant.is_single), alias: 'tenant' }
                },
                {
                    'name': this.$t('update'),
                    'title': this.$t('update'),
                    'component': 'app-update',
                    'permission': true,
                    'props': "",
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>
