<template>
    <modal id="mod-order-action-modal"
           v-model="showModal"
           :loading="loading"
           :preloader="preloader" :title="'Mod Order'"
           @input="closeModal"
           @submit="submitData">

        <form
            ref="form"
            :data-url='SAVE_MOD_ORDER+orderId' @submit.prevent="submitData">

            <div class="row">
                <div class="col-md-8 row mb-3" v-if="this.edititeminfo">
                    <div class="col-md-6">{{ $t('Order number') }} :</div>
                    <div class="col-md-6">{{ this.edititeminfo.invoice_number }}</div>
                    <div class="col-md-6">{{ $t('status') }} :</div>
                    <div class="col-md-6">{{ $t(this.edititeminfo.status?.name) }}</div>
                </div>
                <table class="table" width="100%">
                    <thead>
                        <tr>
                            <th width="10%">#</th>
                            <th width="90%">Product Name</th>
                        </tr>
                    </thead>
                    <tr v-if="orderProductArr" v-for="(item,index) in orderProductArr">
                        <td>
                            <label class="customized-radio radio-default">
                                <input type="radio" class="radio-inline" :name="'opcheck'" v-model="formData.opcheck" :value="item.id" :id="'opcheck_'+index" />
                                <span class="outside">
                                    <span class="inside"></span>
                                </span>
                            </label>
                        </td>
                        <td> <label class="cursor-pointer" :for="'opcheck_'+index">{{ item.variant.product.name }}</label></td>
                    </tr>
                </table>
            </div>
        </form>

    </modal>

</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { SAVE_MOD_ORDER} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import { numberWithCurrencySymbol } from "../../../../Helper/Helper";

export default {
    name: "OrderModActionModal",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        orderId: {
            require: true
        },
        edititeminfo:{},
        edittechinfo:{}
    },
    data() {
        return {
            numberWithCurrencySymbol,
            SAVE_MOD_ORDER,
            showNote: true,
            loading: false,
            totalAmt: 0,
            orderProductArr: this.edititeminfo.order_products??[],
            formData: {
                isEdit: false,
                opcheck: (this.edititeminfo.order_products)?this.edititeminfo.order_products[0].id:0,
            },
        }
    },
    methods: {
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            this.save(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#mod-order-action-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.msg);
            if(data.status) {
                setTimeout(() => {
                    window.location.href = urlGenerator('order/details/'+data.new_order_id);
                }, 1000);
            } else {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        },
        closeModal(eventData) {
            if (eventData) return;
            this.$emit('modal-close');
        }
    },
    mounted() {},
    created() {}
}
</script>
