<template>
    <section id="app-technician-report" class="content-wrapper">

        <app-page-top-section :title="$t('completion_report')" icon="briefcase">
            <app-default-button
                @click="exportSalesSummary"
                :title="$fieldTitle('export', '', true)"/>
        </app-page-top-section>
        <div class="col-12">
            <app-table :id="table_id" :options="options" v-if="options.url" @action=""></app-table>
        </div>
    </section>
</template>

<script>
import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import TechnicianReportMixins from "./TechnicianReportMixins";
import {TECHNICIAN_REPORT_EXPORT} from "../../../Config/ApiUrl-CP";
import {numberWithCurrencySymbol} from "../../../Helper/Helper";
import {mapGetters} from "vuex";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name: 'TechnicianReport',
    mixins: [DatatableHelperMixin, TechnicianReportMixins],
    data() {
        return {
            table_id: 'technician-report-table',
            numberWithCurrencySymbol,
            exportLoading: false,
            isTechInfoModalActive: false,
            branch_or_warehouse_id: this.getBranchOrWarehouseId,
        };
    },
    methods: {
        exportSalesSummary() {
            window.open(urlGenerator(`${TECHNICIAN_REPORT_EXPORT}/?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`,'_blank'));
        },
        closeModal() {
            this.isTechInfoModalActive = false;
            this.$emit('modal-close');
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.branch_or_warehouse_id = new_id
        },
    }
};
</script>
