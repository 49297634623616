import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {ORDER_MAX_MIN_PRICE,GENERATE_INVOICE,GENERATE_PERFOMA_INVOICE, GENERATE_INVOICE_VIEW, GENERATE_PERFOMA_INVOICE_VIEW} from "../../Config/ApiUrl-CP";
import {CUSTOMER_ORDERS} from "../../Config/ApiUrl-CPB";
import SelectableStatusMixin from "../../Helper/SelectableOptions/SelectableStatusMixin";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../Helper/Helper";
import {axiosGet,urlGenerator} from "../../../common/Helper/AxiosHelper";
import {mapGetters} from "vuex";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            options: {
                name: this.$t('sales_report'),
                url: CUSTOMER_ORDERS,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                tableShadow: false,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Sr No.'),
                        type: 'object',
                        key: 'sr_no',
                        modifier: (value) => value 
                    },
                    {
                        title: this.$t('Order ID'),
                        type: 'custom-html',
                        key: 'invoice_number',
                        modifier: (invoice_number,row) => invoice_number ? `<a href="${urlGenerator('order/details/'+row.id)}" class="redirectTo">${invoice_number}</a>` :'-',
                    },
                    {
                        title: this.$t('date'),
                        type: 'custom-html',
                        key: 'created_at',
                        modifier: value => value ? `<span>${formatDateToLocal(value)}</span>` : ''
                    },
                    {
                        title: this.$t('branch_or_warehouse'),
                        type: 'custom-html',
                        key: 'branch_or_warehouse',
                        modifier: value => value ? `<span>${value.name} <span class="text-${value.type.toLowerCase() === 'branch' ? 'warning' : 'info'}">(${value.type[0].toUpperCase()})</span></span>` : ''
                    },
                    {
                        title: this.$t('Invoice No.'),
                        type: 'custom-html',
                        key: 'invoice_gen_number',
                        modifier: (invoice_gen_number,row) => invoice_gen_number ? `<a href="${urlGenerator(GENERATE_INVOICE_VIEW+row.id)}" class="redirectTo">${invoice_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('Proforma No.'),
                        type: 'custom-html',
                        key: 'perfoma_gen_number',
                        modifier: (perfoma_gen_number,row) => perfoma_gen_number ? `<a href="${urlGenerator(GENERATE_PERFOMA_INVOICE_VIEW+row.id)}" class="redirectTo">${perfoma_gen_number}</a>` :'-',
                    },
                    {
                        title: this.$t('cash_counter'),
                        type: 'custom-html',
                        key: 'cash_register',
                        modifier: value => value ? `${value.name}` : ''
                    },
                    {
                        title: this.$t('sold_by'),
                        type: 'object',
                        key: 'created_by',
                        modifier: (created_by) => (created_by?.full_name)
                    },
                    {
                        title: this.$t('customer'),
                        type: 'object',
                        key: 'customer',
                        modifier: (customer) => (customer?.full_name)
                    },
                    {
                        title: this.$t('Order Status'),
                        type: 'custom-html',
                        key: 'status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'custom-html',
                        key: 'payment_status',
                        isVisible: true,
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('Invoice Amount'),
                        type: 'custom-html',
                        key: 'grand_total',
                        modifier: value => `<p class="text-center mb-0">${numberWithCurrencySymbol(value)}</p>`
                    },
                    {
                        title: this.$t('Referred By'),
                        type: 'custom-html',
                        key: 'referred_by',
                        modifier: value => (value)?value.full_name:'No'
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'referred_by',
                        isVisible: true,
                    },

                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('Update Referral'),
                        icon: 'edit',
                        key: 'referred_by',
                        modifier: (value) => {
                            return (value.referred_by == null || value.referred_by?.full_name == 'None' || value?.ref_doctor_com_per
                                == 0)?true:false
                        }
                    },
                ],
                filters: [
                    {
                        title: this.$t('created_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    {
                        title: "Price Range",
                        type: "range-filter",
                        key: "range_filter",
                        maxTitle: "Max usd",
                        minTitle: "Min usd",
                        sign: "₹",
                        minRange: 0,
                        maxRange: 0,
                    },
                    this.getStatusFilterOptions('paymentStatus'),
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        getStatusFilterOptions(statusType, listValueField = 'name') {
            return {
                title: this.$t('Payment Status'),
                type: 'search-and-select-filter',
                key: 'payment_status',
                settings: {
                    url: urlGenerator("app/selectable-statuses"),
                    modifire: (v) => {
                        return {id: v.id, name: v.translated_name}
                    },
                    params: {
                        type: statusType,
                    },
                    per_page: 10,
                    queryName: 'search_by_name',
                    loader: 'app-pre-loader'
                },
                listValueField
            }
        },
        getMaxMinOrderPrice() {
            axiosGet(this.orderMaxMinPriceUrl).then((res) => {
                this.options?.filters.find(({key}) => {
                    if (key === 'range_filter') {
                        Object.assign(this.options.filters[1], res.data)
                    }
                })
            })
        },
        updateUrl() {
            this.options.url = `${CUSTOMER_ORDERS}/${this.props}`
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
            this.$hub.$emit(`reload-${this.table_id}`)
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl();
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl();
        },
    },
    created() {
        this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        this.getMaxMinOrderPrice();
    }
}
