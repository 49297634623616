import DatatableHelperMixin from "../../../../../../common/Mixin/Global/DatatableHelperMixin";
import {INVOICE_LIST, INVOICE_VIEW, ORDER_MAX_MIN_PRICE} from "../../../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {numberWithCurrencySymbol} from "../../../../../Helper/Helper";


export default {
    mixins: [DatatableHelperMixin,SelectableStatusMixin],
    data() {
        return {
            options: {
                url: null,
                columns: [
                    {
                        title: this.$t('Delivery'),
                        type: 'component',
                        componentName: 'app-delivery-status',
                        key: 'delivery_status_id',
                        modifier: (deliveryStatus) => deliveryStatus && deliveryStatus
                    },
                    {
                        title: this.$t('Payment Status'),
                        type: 'component',
                        componentName: 'app-payment-status',
                        key: 'payment_status_id',
                        modifier: (paymentStatus) => paymentStatus && paymentStatus
                    },
                    {
                        title: this.$t('Warehouse Status'),
                        type: 'component',
                        componentName: 'app-warehouse-status',
                        key: 'warehouse_status_id',
                        modifier: (warehouseStatus) => warehouseStatus && warehouseStatus
                    },
                    {
                        title: this.$t('Order status'),
                        type: 'component',
                        componentName: 'app-orders-status',
                        key: 'statusId',
                        modifier: (status) => status && status
                    },
                    // {
                    //     title: this.$t('actions'),
                    //     type: 'action',
                    //     key: 'invoice',
                    //     isVisible: true
                    // },
                ],
                // actionType: "dropdown",
                // actions: [
                //     {
                //         title: this.$t('view'),
                //         type: 'view'
                //     },
                //     {
                //         title: this.$t('Custom Insoles Customization'),
                //         type: 'custom_insoles_customization'
                //     },
                //     {
                //         title: this.$t('due_receive'),
                //         type: 'due_receive',
                //         modifier: (row) => {
                //             return row.due_amount > 0 ? true : false
                //         }
                //     }
                // ],
                // paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                // showAction: true,
                orderBy: 'desc',
                search: false,
            },
            orderMaxMinPriceUrl:''
        }
    },
    methods:{
        updateOrderMaxMinPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },

      
    },
    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(INVOICE_LIST, this.updateOrderMaxMinPriceUrl);
    },
    watch: {
        getBranchOrWarehouseId(){
            this.updateUrl(INVOICE_LIST, this.updateOrderMaxMinPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    }
}
