import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import {TECHNICIAN_REPORT, ORDER_MAX_MIN_PRICE,SELECTABLE_PRACTITIONER} from "../../../Config/ApiUrl-CP";
import {axiosGet, urlGenerator} from "../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            filterHtml : '',
            options: {
                name: this.$t('completion_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Personnel Name'),
                        type: 'object',
                        key: 'full_name',
                        modifier: (value) => value 
                    },
                    {
                        title: this.$t('Total New Orders'),
                        type: 'component',
                        componentName: 'tech-order-count',
                        key: 'ordertechnician',
                        extraval: 'new'
                    },
                    {
                        title: this.$t('Total Mod Orders'),
                        type: 'component',
                        componentName: 'tech-order-count',
                        key: 'ordertechnician',
                        extraval: 'mod'
                    },
                ],
                actionType: "dropdown",
                actions: [],
                filters: [
                    {
                        title: this.$t('issue_date'),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    },
                    // {
                    //     title: "Practitioner",
                    //     type: "multi-select-filter",
                    //     key: "practitioners",
                    //     option: [],
                    // },
                    // {
                    //     title: this.$t('customer'),
                    //     type: 'search-and-select-filter',
                    //     key: 'customers',
                    //     settings: {
                    //         url: urlGenerator('app/selectable-customers'),
                    //         modifire: ({id, full_name}) => ({id, full_name}),
                    //         params: {},
                    //         per_page: 10,
                    //         queryName: 'search',
                    //         loader: 'app-pre-loader'
                    //     },
                    //     listValueField: 'full_name'
                    // },
                    // {
                    //     title: this.$t('sold_by'),
                    //     type: 'search-and-select-filter',
                    //     key: 'created_by',
                    //     settings: {
                    //         url: urlGenerator('app/selectable/users'),
                    //         modifire: ({id, full_name}) => ({id, full_name}),
                    //         params: {},
                    //         per_page: 10,
                    //         queryName: 'search',
                    //         loader: 'app-pre-loader'
                    //     },
                    //     listValueField: 'full_name'
                    // },
                    // {
                    //     title: "Price Range",
                    //     type: "range-filter",
                    //     key: "range_filter",
                    //     maxTitle: "Max usd",
                    //     minTitle: "Min usd",
                    //     sign: "₹",
                    //     minRange: 0,
                    //     maxRange: 0,
                    // },
                    // {
                    //     title: "Order Status",
                    //     type: "multi-select-filter",
                    //     key: "order_status_ids",
                    //     option: [],
                    //     listValueField: 'name'
                    // },
                    // {
                    //     title: "Payment Status",
                    //     type: "multi-select-filter",
                    //     key: "payment_status",
                    //     option: [],
                    //     listValueField: 'name'
                    // },
                    // {
                    //     title: this.$t('Referral'),
                    //     type: 'checkbox',
                    //     key: 'referral',
                    //     option: [
                    //         { id: 'NA', value: this.$t('NA') },
                    //         { id: 'Yes', value: this.$t('Yes') },
                    //         { id: 'No', value: this.$t('No') },
                    //     ],
                    // },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        getMaxMinOrderPrice() {
            axiosGet(this.orderMaxMinPriceUrl).then((res) => {
                this.options?.filters.find(({key}) => {
                    if (key === 'range_filter') {
                        Object.assign(this.options.filters[3], res.data)
                    }
                })
            })
        },
        getInvoiceIdFilter() {
            this.options.filters.push({
                title: this.$t('invoice_id'),
                type: 'search-and-select-filter',
                key: 'invoice_number',
                settings: {
                    url: urlGenerator('app/selectable-invoice'),
                    modifire: ({id, invoice_number: value}) => ({id, value}),
                    params: {
                        branch_or_warehouse_id: this.getBranchOrWarehouseId,
                    },
                    per_page: 10,
                    queryName: 'search',
                    loader: 'app-pre-loader'
                }
            })
        },
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        practitionerList() {
            axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
                let name = this.options.filters.find(element => element.key === 'practitioners');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.full_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        getOrderStatus() {
            axiosGet("app/selectable-statuses?type=orderStatus").then((response) => {
                let name = this.options.filters.find(element => element.key === 'order_status_ids');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        name: name.translated_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        getPaymentStatus() {
            axiosGet("app/selectable-statuses?type=paymentStatus").then((response) => {
                let name = this.options.filters.find(element => element.key === 'payment_status');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        name: name.translated_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(TECHNICIAN_REPORT, this.updateMinMaxPriceUrl);
        // this.getInvoiceIdFilter();
        this.getMaxMinOrderPrice();
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
        const prac = axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.full_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        const orderStatusArr  = axiosGet("app/selectable-statuses?type=orderStatus").then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.translated_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        const paymentStatusArr  = axiosGet("app/selectable-statuses?type=paymentStatus").then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.translated_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        this.$hub.$on('filter-change', (newFilterValues) => {
            let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
            this.filterHtml = '';
            if(filterData?.date?.start) {
                this.filterHtml = '<span class="d-block">Sales Report from '+formatDateToLocal(filterData?.date.start,false,null,0,'DD MMM YYYY')+' to '+formatDateToLocal(filterData?.date.end,false,null,0,'DD MMM YYYY')+'</span>';
            }
            if(filterData?.practitioners) {
                prac.then((resp) => {
                    let practArr = filterData?.practitioners.split(',');
                    var transformedArray = practArr.map(id => {
                        var option = resp.find((opt) => {
                            if(opt.id == id) return opt;
                        });
                        return option ? option.value : undefined;
                    });
                    this.filterHtml += '<span class="d-block">Practitioner : '+transformedArray.join(',')+'</span>';
                });
            }
            if(filterData?.customers) {
                axiosGet('app/selectable-customers?selected='+filterData?.customers).then((response) => {
                    const customerArr = response.data.data.map(name => {
                        return {
                            id: name.id,
                            value: name.full_name
                        }
                    });
                    this.filterHtml += '<span class="d-block">Patient : '+customerArr[0].value+'</span>';
                }).catch((error) => {
                    this.$toastr.e(error);
                });
            }
            if(filterData?.range_filter) {
                this.filterHtml += '<span class="d-block">Price Range From '+numberWithCurrencySymbol(filterData?.range_filter?.min)+' To '+numberWithCurrencySymbol(filterData?.range_filter?.max)+'</span>';
            }
            if(filterData?.order_status_ids) {
                orderStatusArr.then((resp) => {
                    let practArr = filterData?.order_status_ids.split(',');
                    var transformedArray = practArr.map(id => {
                        var option = resp.find((opt) => {
                            if(opt.id == id) return opt;
                        });
                        return option ? option.value : undefined;
                    });
                    this.filterHtml += '<span class="d-block">Order Status : '+transformedArray.join(',')+'</span>';
                });
            }
            if(filterData?.payment_status) {
                paymentStatusArr.then((resp) => {
                    let practArr = filterData?.payment_status.split(',');
                    var transformedArray = practArr.map(id => {
                        var option = resp.find((opt) => {
                            if(opt.id == id) return opt;
                        });
                        return option ? option.value : undefined;
                    });
                    this.filterHtml += '<span class="d-block">Payment Status : '+transformedArray.join(',')+'</span>';
                });
            }
        });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(TECHNICIAN_REPORT, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id1}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
        this.practitionerList();
        this.getOrderStatus();
        this.getPaymentStatus();
    }
}