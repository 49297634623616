<template>
    <modal id="branch-create-edit-modal" size="extra-large" v-model="showModal" :title="generateModalTitle('branch')"
        @submit="submitData" :loading="loading" :preloader="preloader">

        <form ref="form" :data-url='selectedUrl ? selectedUrl : url' @submit.prevent="submitData">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group :page="'modal'" :label="$t('Business Name')" :required="true"
                        :error-message="$errorMessage(errors, 'business_name')" :placeholder="$placeholder('Business Name')"
                        v-model="formData.business_name" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group :page="'modal'" :label="$t('Company Name')" :required="true"
                        :error-message="$errorMessage(errors, 'company_name')" :placeholder="$placeholder('Company Name')"
                        v-model="formData.company_name" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group :page="'modal'" :label="$t('Branch Name')" :required="true"
                        :error-message="$errorMessage(errors, 'name')" :placeholder="$placeholder('Branch Name')"
                        v-model="formData.name" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="number" :page="'modal'" :label="$t('Phone number')" :required="true"
                        :error-message="$errorMessage(errors, 'phone_number')" :placeholder="$placeholder('Phone number')"
                        v-model="formData.phone_number" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Email')" :required="true"
                        :error-message="$errorMessage(errors, 'email')" :placeholder="$placeholder('Email')"
                        v-model="formData.email" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="statesList" list-value-field="value" :page="'modal'"
                        :label="$t('State')" :required="true" :error-message="$errorMessage(errors, 'stateid')"
                        :placeholder="$placeholder('State')" v-model="formData.stateid" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('City')" :required="true"
                        :error-message="$errorMessage(errors, 'city')" :placeholder="$placeholder('City')"
                        v-model="formData.city" />
                </div>
                <div class="col-12 col-sm-12 col-md-12">
                    <app-form-group type="textarea" :text-area-rows="3" :page="'modal'" :label="$t('Address')"
                        :required="true" :error-message="$errorMessage(errors, 'address')"
                        :placeholder="$placeholder('Address')" v-model="formData.address" />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Website')" :required="true"
                        :error-message="$errorMessage(errors, 'website')" :placeholder="$placeholder('Website')"
                        v-model="formData.website" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="WeightUnit" list-value-field="name" :page="'modal'"
                        :label="$t('Weight unit')" :required="true" :error-message="$errorMessage(errors, 'weight_unit')"
                        :placeholder="$placeholder('Weight Unit')" v-model="formData.weight_unit" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="MeasurementsUnit" list-value-field="name" :page="'modal'"
                        :label="$t('Measurements unit')" :required="true" :error-message="$errorMessage(errors, 'measurements_unit')"
                        :placeholder="$placeholder('Measurements Unit')" v-model="formData.measurements_unit" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Branch Code')" :required="true"
                        :error-message="$errorMessage(errors, 'branch_code')" :placeholder="$placeholder('Branch Code')"
                        v-model="formData.branch_code" />
                </div>
                <div class="col-12 col-sm-12 col-md-12">
                    <label class="form-label">Logo</label>
                    <input type="file" :error-message="$errorMessage(errors, 'logo')" class="form-control" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="GSTTypeList" list-value-field="name" :page="'modal'"
                        :label="$t('GST Type')" :required="true" :error-message="$errorMessage(errors, 'GST_Type')"
                        :placeholder="$placeholder('GST Type')" v-model="formData.GST_Type" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('GSTIN')" :required="true"
                        :error-message="$errorMessage(errors, 'GSTIN')" :placeholder="$placeholder('GSTIN')"
                        v-model="formData.GSTIN" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Bill series prefix')" :required="true"
                        :error-message="$errorMessage(errors, 'bill_series_prefix')"
                        :placeholder="$placeholder('Bill series prefix')" v-model="formData.bill_series_prefix" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Proforma series prefix')" :required="true"
                        :error-message="$errorMessage(errors, 'perfoma_series_prefix')"
                        :placeholder="$placeholder('Proforma series prefix')" v-model="formData.perfoma_series_prefix" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group :page="'modal'" :label="$t('Order series prefix')" :required="true"
                        :error-message="$errorMessage(errors, 'order_series_prefix')"
                        :placeholder="$placeholder('Order series prefix')" v-model="formData.order_series_prefix" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="BranchTypeList" list-value-field="name" :page="'modal'"
                        :label="$t('Branch Type')" :required="true" :error-message="$errorMessage(errors, 'branch_type')"
                        :placeholder="$placeholder('Branch type')" v-model="formData.branch_type" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="LocalWorkshop" list-value-field="name" :page="'modal'"
                        :label="$t('Local workshop')" :required="true"
                        :error-message="$errorMessage(errors, 'local_workshop')"
                        :placeholder="$placeholder('Local workshop')" v-model="formData.local_workshop" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <label>{{ $t('Workshop') }}</label>
                    <app-input class="margin-right-8" type="search-and-select" :inputclearable="false"
                        :placeholder="$t('Select Workshop')" :options="workshopOptions" v-model="formData.workshop"
                        :error-message="$errorMessage(errors, 'workshop')" />
                </div>
            </div>
        </form>

    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import { BRANCH_OR_WAREHOUSE } from "../../../../Config/ApiUrl-CPB";
import { SELECTABLE_STATES } from "../../../../Config/ApiUrl-CP";
import { urlGenerator,axiosGet } from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "BranchCreateEditModal",
    extends: CoreLibrary,
    mixins: [FormHelperMixins, HelperMixin, ModalMixin],
    props: ['status'],
    data() {
        return {
            url: BRANCH_OR_WAREHOUSE,
            statusList: [],
            statesList: [],
            managerOptions: {
                url: urlGenerator("app/selectable/managers"),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, full_name: value }) => ({ id, value }),
            },
            formData: {
                status_id: this.status.id,
                stateid: '',
                type: "branch"
            },
            WeightUnit: [
                { id: '', name: this.$t('select_type') },
                { id: 'kgs', name: this.$t('kgs') },
                { id: 'lbs', name: this.$t('lbs') },
            ],
            MeasurementsUnit: [
                { id: '', name: this.$t('select_type') },
                { id: 'mm', name: this.$t('mm') },
                { id: 'cm', name: this.$t('cm') },
            ],
            GSTTypeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'unregistered', name: this.$t('Unregistered') },
                { id: 'registered', name: this.$t('Registered') },
                { id: 'composite', name: this.$t('Composite') },
            ],
            BranchTypeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'podiapro', name: this.$t('podiapro') },
                { id: 'partner', name: this.$t('partner') },
            ],
            LocalWorkshop: [
                { id: '', name: this.$t('select_type') },
                { id: 'yes', name: this.$t('yes') },
                { id: 'no', name: this.$t('no') },
            ],
            workshopOptions: {
                url: urlGenerator("app/selectable-warehouses"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.name }),
            },
        }
    },
    mounted() {
        this.statesOptions();
    },
    methods: {
        statesOptions() {
            try {
                axiosGet(`${SELECTABLE_STATES}`).then(response => {
                    if(this.formData.stateid == '') this.formData.stateid = response.data.data[0].id;
                    for (let item of response.data.data) {
                        this.statesList.push({
                            id: item.id,
                            value: item.state,
                        });
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.submitFromFixin(this.selectedUrl ? 'patch' : 'post', this.selectedUrl ?? this.url, this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            $('#branch-create-edit-modal').modal('hide');
            this.$emit('branch-created');
            this.toastAndReload(data.message, 'branches-and-warehouses-table');
            setTimeout(() => {
                window.location.reload();
            }, 1000)
        },
    }
}
</script>
