<template>
  <fieldset :disabled="isDisable">
    <div class="col-12 my-2 text-right" v-if="examinationPrintUrl != '' ">
      <a :href="examinationPrintUrl" target="_blank" class="btn btn-primary">Print</a>
    </div>
    <form>
      <!-- Examinaton Data -->
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <h4><b>Clinical Examination</b></h4>
            </div>
            <div class="col-md-4">
              <h4>Left</h4>
            </div>
            <div class="col-md-4">
              <h4>Right</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12 mb-3">
          <div class="row" v-for="field in fields" :key="field.id">
            <div class="col-md-4 d-flex align-items-center">
              <label :for="`${field.name}Left`">{{ field.name }}</label>
            </div>
            <div class="col-md-4 mb-3" v-if="field.name !== 'Leg length discrepancy'">
              <select class="form-control" :id="`${field.name}Left`" :name="`${field.name}Left`"
                v-model="formData[field.id].left">
                <option value="null">Select {{ field.name }}</option>
                <option v-for="option in getDropdownOptions(field.id)" :key="option.id" :value="option.id">
                  {{ option.value }}
                </option>
              </select>
            </div>
            <div class="col-md-4 mb-3" v-if="field.name !== 'Leg length discrepancy'">
              <select class="form-control" :id="`${field.name}Right`" :name="`${field.name}Right`"
                v-model="formData[field.id].right">
                <option value="null">Select {{ field.name }}</option>
                <option v-for="option in getDropdownOptions(field.id)" :key="option.id" :value="option.id">
                  {{ option.value }}
                </option>
              </select>
            </div>
            <div class="col-md-4 mb-3" v-if="field.name === 'Leg length discrepancy'">
              <input type="text" class="form-control" :id="`${field.name}LeftInput`" :name="`${field.name}LeftInput`"
                v-model="formData[field.id].leg_length_discrepancy_left" placeholder="Enter Leg length discrepancy...">
            </div>
            <div class="col-md-4 mb-3" v-if="field.name === 'Leg length discrepancy'">
              <input type="text" class="form-control" :id="`${field.name}RightInput`" :name="`${field.name}RightInput`"
                v-model="formData[field.id].leg_length_discrepancy_right" placeholder="Enter Leg length discrepancy...">
            </div>
          </div>
          <div class="form-group">
            <label for="comments">{{ $t('Comments') }}:</label>
            <textarea class="form-control" placeholder="Enter comments" id="comments" rows="4"
              v-model="formData.examination_fields_comments"></textarea>
          </div>
          <div class="col-md-12">
          </div>
          <div class="mt-primary">
            <div class="form-check mb-4">
              <input type="checkbox" class="form-check-input" id="exAddToReport" v-model="formData.exAddToReport">
              <label class="form-check-label" for="exAddToReport">{{ $t('Add to Report') }}</label>
            </div>
            <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveExaminaton">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>

      <div id="accordionExample" class="accordion">
        <!-- Static Foot Pressure Distribution - First -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#FootPressureAnalysis" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !footPressureAnalysisData
              }">
              <h5 class="card-title">{{ $t('Static Foot Pressure Distribution - First') }}
                <span v-if="footPressureAnalysisData" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="FootPressureAnalysis" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <!-- <div class="form-group">
                    <label for="title">{{ $t('Title') }}:</label>
                    <input type="text" class="form-control" placeholder="Title" id="title" v-model="formData.title">
                  </div> -->
                  <div class="form-group">
                    <label for="date">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="date" v-model="formData.date">
                  </div>
                  <div class="form-group">
                    <label for="posturalImage">{{ $t('Postural Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.posturalImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.posturalImage)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posturalImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posturalImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('FootPressureAnalysis', formData.footPressureDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="entireLeft">{{ $t('Entire Left %') }}:</label>
                    <input type="number" class="form-control" id="entireLeft" v-model="formData.entireLeft">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="entireRight">{{ $t('Entire Right %') }}:</label>
                    <input type="number" class="form-control" id="entireRight" v-model="formData.entireRight">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="leftTop">{{ $t('Left Top %') }}:</label>
                    <input type="number" class="form-control" id="leftTop" v-model="formData.leftTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="leftBottom">{{ $t('Left Bottom %') }}:</label>
                    <input type="number" class="form-control" id="leftBottom" v-model="formData.leftBottom">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="rightTop">{{ $t('Right Top %') }}:</label>
                    <input type="number" class="form-control" id="rightTop" v-model="formData.rightTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="rightBottom">{{ $t('Right Bottom %') }}:</label>
                    <input type="number" class="form-control" id="rightBottom" v-model="formData.rightBottom">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="comments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="comments" rows="4"
                      v-model="formData.comments"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="addToReport" v-model="formData.addToReport">
                    <label class="form-check-label" for="addToReport">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveFootPressureAnalysis">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Static Foot Pressure Distribution - Latest -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#FootPressureAnalysisLatest" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !footPressureAnalysisDataLatest
              }">
              <h5 class="card-title">{{ $t('Static Foot Pressure Distribution - Latest') }}
                <span v-if="footPressureAnalysisDataLatest" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="FootPressureAnalysisLatest" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="footLatestdate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="footLatestdate" v-model="formData.footLatestdate">
                  </div>
                  <div class="form-group">
                    <label for="footLatestposturalImage">{{ $t('Postural Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.footLatestposturalImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.footLatestposturalImage)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.footLatestposturalImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.footLatestposturalImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('FootPressureAnalysis', formData.footLatestfootPressureDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestentireLeft">{{ $t('Entire Left %') }}:</label>
                    <input type="number" class="form-control" id="footLatestentireLeft" v-model="formData.footLatestentireLeft">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestentireRight">{{ $t('Entire Right %') }}:</label>
                    <input type="number" class="form-control" id="footLatestentireRight" v-model="formData.footLatestentireRight">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestleftTop">{{ $t('Left Top %') }}:</label>
                    <input type="number" class="form-control" id="footLatestleftTop" v-model="formData.footLatestleftTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestleftBottom">{{ $t('Left Bottom %') }}:</label>
                    <input type="number" class="form-control" id="footLatestleftBottom" v-model="formData.footLatestleftBottom">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestrightTop">{{ $t('Right Top %') }}:</label>
                    <input type="number" class="form-control" id="footLatestrightTop" v-model="formData.footLatestrightTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footLatestrightBottom">{{ $t('Right Bottom %') }}:</label>
                    <input type="number" class="form-control" id="footLatestrightBottom" v-model="formData.footLatestrightBottom">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="footLatestcomments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="footLatestcomments" rows="4"
                      v-model="formData.footLatestcomments"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="footLatestaddToReport" v-model="formData.footLatestaddToReport">
                    <label class="form-check-label" for="footLatestaddToReport">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveFootPressureAnalysisLatest">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Static Foot Pressure Distribution - Orthotics -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#FootPressureAnalysisOrthotics" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !footPressureAnalysisDataOrthotics
              }">
              <h5 class="card-title">{{ $t('Static Foot Pressure Distribution - Orthotics') }}
                <span v-if="footPressureAnalysisDataOrthotics" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="FootPressureAnalysisOrthotics" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="footOrthoticsdate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="footOrthoticsdate" v-model="formData.footOrthoticsdate">
                  </div>
                  <div class="form-group">
                    <label for="footOrthoticsposturalImage">{{ $t('Postural Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.footOrthoticsposturalImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.footOrthoticsposturalImage)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.footOrthoticsposturalImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.footOrthoticsposturalImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('FootPressureAnalysis', formData.footOrthoticsfootPressureDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsentireLeft">{{ $t('Entire Left %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsentireLeft" v-model="formData.footOrthoticsentireLeft">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsentireRight">{{ $t('Entire Right %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsentireRight" v-model="formData.footOrthoticsentireRight">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsleftTop">{{ $t('Left Top %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsleftTop" v-model="formData.footOrthoticsleftTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsleftBottom">{{ $t('Left Bottom %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsleftBottom" v-model="formData.footOrthoticsleftBottom">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsrightTop">{{ $t('Right Top %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsrightTop" v-model="formData.footOrthoticsrightTop">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="footOrthoticsrightBottom">{{ $t('Right Bottom %') }}:</label>
                    <input type="number" class="form-control" id="footOrthoticsrightBottom" v-model="formData.footOrthoticsrightBottom">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="footOrthoticscomments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="footOrthoticscomments" rows="4"
                      v-model="formData.footOrthoticscomments"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="footOrthoticsaddToReport" v-model="formData.footOrthoticsaddToReport">
                    <label class="form-check-label" for="footOrthoticsaddToReport">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveFootPressureAnalysisOrthotics">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Dynamic Additional Data -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#DynamicImage" aria-expanded="false" aria-controls="permission"
              :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !dynamicAnalysisData
              }">
              <h5 class="card-title">{{ $t('Dynamic Pressure Distribution - First') }}
                <span v-if="dynamicAnalysisData" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="DynamicImage" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicImage">{{ $t('Dynamic Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.dynamicImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.dynamicImage)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('DynamicAdditionalData', formData.dynamicDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureRightKPa">{{ $t('Maximum pressure right KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureRightKPa"
                      v-model="formData.maximumPressureRightKPa" placeholder="Maximum pressure right KPa">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureLeftKPa">{{ $t('Maximum pressure left KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureLeftKPa"
                      v-model="formData.maximumPressureLeftKPa" placeholder="Maximum pressure left KPa">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicComments">{{ $t('Dynamic Comments') }}:</label>
                    <textarea class="form-control" id="dynamicComments" placeholder="Dynamic Comments" rows="4"
                      v-model="formData.dynamicComments"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="addToReport"
                      v-model="formData.dynamicAddToReport">
                    <label class="form-check-label" for="addToReport">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveDynamicAnalysis">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Dynamic Additional Data Latest -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#DynamicImageLatest" aria-expanded="false" aria-controls="permission"
              :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !dynamicAnalysisDataLatest
              }">
              <h5 class="card-title">{{ $t('Dynamic Pressure Distribution - Latest') }}
                <span v-if="dynamicAnalysisDataLatest" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="DynamicImageLatest" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicImageLatest">{{ $t('Dynamic Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.dynamicImageLatest">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.dynamicImageLatest)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImageLatest)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImageLatest)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('DynamicAdditionalData', formData.dynamicDataLatestId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureRightKPaLatest">{{ $t('Maximum pressure right KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureRightKPaLatest"
                      v-model="formData.maximumPressureRightKPaLatest" placeholder="Maximum pressure right KPa">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureLeftKPaLatest">{{ $t('Maximum pressure left KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureLeftKPaLatest"
                      v-model="formData.maximumPressureLeftKPaLatest" placeholder="Maximum pressure left KPa">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicCommentsLatest">{{ $t('Dynamic Comments') }}:</label>
                    <textarea class="form-control" id="dynamicCommentsLatest" placeholder="Dynamic Comments" rows="4"
                      v-model="formData.dynamicCommentsLatest"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="addToReportLatest"
                      v-model="formData.dynamicAddToReportLatest">
                    <label class="form-check-label" for="addToReportLatest">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveDynamicAnalysisLatest">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Dynamic Additional Data Orthotics -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#DynamicImageOrthotics" aria-expanded="false" aria-controls="permission"
              :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !dynamicAnalysisDataOrthotics
              }">
              <h5 class="card-title">{{ $t('Dynamic Pressure Distribution - Orthotics') }}
                <span v-if="dynamicAnalysisDataOrthotics" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="DynamicImageOrthotics" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicImageOrthotics">{{ $t('Dynamic Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.dynamicImageOrthotics">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.dynamicImageOrthotics)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImageOrthotics)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.dynamicImageOrthotics)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('DynamicAdditionalData', formData.dynamicDataOrthoticsId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureRightKPaOrthotics">{{ $t('Maximum pressure right KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureRightKPaOrthotics"
                      v-model="formData.maximumPressureRightKPaOrthotics" placeholder="Maximum pressure right KPa">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="maximumPressureLeftKPaOrthotics">{{ $t('Maximum pressure left KPa') }}:</label>
                    <input type="number" class="form-control" id="maximumPressureLeftKPaOrthotics"
                      v-model="formData.maximumPressureLeftKPaOrthotics" placeholder="Maximum pressure left KPa">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="dynamicCommentsOrthotics">{{ $t('Dynamic Comments') }}:</label>
                    <textarea class="form-control" id="dynamicCommentsOrthotics" placeholder="Dynamic Comments" rows="4"
                      v-model="formData.dynamicCommentsOrthotics"></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="addToReportOrthotics"
                      v-model="formData.dynamicAddToReportOrthotics">
                    <label class="form-check-label" for="addToReportOrthotics">{{ $t('Add to Report') }}</label>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveDynamicAnalysisOrthotics">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Diabetic Foot Risk Ratio -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#DiabeticFootRiskRatio" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !diabeticFootRiskRatio
              }">
              <h5 class="card-title">{{ $t('Diabetic Foot Risk Ratio') }}
                <span v-if="diabeticFootRiskRatio" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="DiabeticFootRiskRatio" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="diabeticFootDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="diabeticFootDate"
                      v-model="formData.diabeticFootDate">
                  </div>
                  <div class="form-group">
                    <label for="diabeticFootImage">{{ $t('Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.diabeticFootImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" :src="urlGenerator(this.formData.diabeticFootImage)"
                           width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.diabeticFootImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.diabeticFootImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('DiabeticFootRiskRatio', formData.diabeticFootRiskDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="diabeticFootComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="diabeticFootComments" rows="4"
                      v-model="formData.diabeticFootComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="diabeticFootAddToReport"
                        v-model="formData.diabeticFootAddToReport">
                      <label class="form-check-label" for="diabeticFootAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveDiabeticFootRiskRatio">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Posterior View Static -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#PosteriorViewStatic" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !posteriorViewStatic
              }">
              <h5 class="card-title">{{ $t('Posterior View Static') }}
                <span v-if="posteriorViewStatic" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="PosteriorViewStatic" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="posteriorViewStaticDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="posteriorViewStaticDate"
                      v-model="formData.posteriorViewStaticDate">
                  </div>
                  <div class="form-group">
                    <label for="posteriorViewStaticImage">{{ $t('Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.posteriorViewStaticImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail"
                          :src="urlGenerator(this.formData.posteriorViewStaticImage)"  width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewStaticImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewStaticImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PosteriorViewStatic', formData.posteriorViewStaticDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="posteriorViewStaticComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="posteriorViewStaticComments" rows="4"
                      v-model="formData.posteriorViewStaticComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="posteriorViewStaticAddToReport"
                        v-model="formData.posteriorViewStaticAddToReport">
                      <label class="form-check-label" for="posteriorViewStaticAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="savePosteriorViewStatic">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Top View Weight bearing -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#TopViewWeightBearing" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !topViewWeightBearing
              }">
              <h5 class="card-title">{{ $t('Top View Weight bearing') }}
                <span v-if="topViewWeightBearing" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="TopViewWeightBearing" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="topViewWeightBearingDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="topViewWeightBearingDate"
                      v-model="formData.topViewWeightBearingDate">
                  </div>
                  <div class="form-group">
                    <label for="topViewWeightBearingImage">{{ $t('Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="imageInput" @change="handleImageUpload" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="imageUrl" class="rounded img-fluid img-thumbnail"  width="200px" alt="Uploaded Image"
                          v-if="imageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!imageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!imageUrl && this.formData.topViewWeightBearingImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail"
                          :src="urlGenerator(this.formData.topViewWeightBearingImage)"  width="200px" alt="Postural Image" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.topViewWeightBearingImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.topViewWeightBearingImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('TopViewWeightBearing', formData.topViewWeightBearingDataId)"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="topViewWeightBearingComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="topViewWeightBearingComments" rows="4"
                      v-model="formData.topViewWeightBearingComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="topViewWeightBearingAddToReport"
                        v-model="formData.topViewWeightBearingAddToReport">
                      <label class="form-check-label" for="topViewWeightBearingAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveTopViewWeightBearing">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Medial View Weight bearing -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#MedialViewWeightBearing" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !medialViewWeightBearing
              }">
              <h5 class="card-title">{{ $t('Medial View Weight bearing') }}
                <span v-if="medialViewWeightBearing" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="MedialViewWeightBearing" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="medialViewWeightBearingDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="medialViewWeightBearingDate"
                      v-model="formData.medialViewWeightBearingDate">
                  </div>
                  <div class="form-group">
                    <label for="medialViewWeightBearingImageRight">{{ $t('Image Right') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="MedialViewWeightBearing-imageRight"
                        @change="handleImagesUpload('MedialViewWeightBearing', 'imageRight')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="MedialViewWeightBearing.imageRightUrl" class="rounded img-fluid img-thumbnail" width="200px" alt="Uploaded Image" v-if="MedialViewWeightBearing.imageRightUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!MedialViewWeightBearing.imageRightUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!MedialViewWeightBearing.imageRightUrl && this.formData.medialViewWeightBearingImageRight">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.medialViewWeightBearingImageRight)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.medialViewWeightBearingImageRight)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.medialViewWeightBearingImageRight)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('MedialViewWeightBearing', formData.medialViewWeightBearingDataId, 'imageRight')"><app-icon name="trash-2"/></a>
                    </div>

                  </div>
                  <div class="form-group">
                    <label for="medialViewWeightBearingImageLeft">{{ $t('Image Left') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="MedialViewWeightBearing-imageLeft"
                        @change="handleImagesUpload('MedialViewWeightBearing', 'imageLeft')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="MedialViewWeightBearing.imageLeftUrl"
                           width="200px" alt="Uploaded Image" v-if="MedialViewWeightBearing.imageLeftUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!MedialViewWeightBearing.imageLeftUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!MedialViewWeightBearing.imageLeftUrl && this.formData.medialViewWeightBearingImageLeft">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.medialViewWeightBearingImageLeft)"
                          alt="Medial View Weight Bearing Left" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.medialViewWeightBearingImageLeft)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.medialViewWeightBearingImageLeft)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('MedialViewWeightBearing', formData.medialViewWeightBearingDataId, 'imageLeft')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="medialViewWeightBearingComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="medialViewWeightBearingComments"
                      rows="4" v-model="formData.medialViewWeightBearingComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="medialViewWeightBearingAddToReport"
                        v-model="formData.medialViewWeightBearingAddToReport">
                      <label class="form-check-label" for="medialViewWeightBearingAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveMedialViewWeightBearing">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Plantar View - Weight bearing -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#PlantarViewWeightBearing" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !plantarViewWeightBearing
              }">
              <h5 class="card-title">{{ $t('Plantar View - Weight bearing') }}
                <span v-if="plantarViewWeightBearing" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="PlantarViewWeightBearing" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="plantarViewWeightBearingDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="plantarViewWeightBearingDate"
                      v-model="formData.plantarViewWeightBearingDate">
                  </div>
                  <div class="form-group">
                    <label for="plantarViewWeightBearingImageWithLights">{{ $t('Image with lights') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PlantarViewWeightBearing-imageWithLights"
                        @change="handleImagesUpload('PlantarViewWeightBearing', 'imageWithLights')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="PlantarViewWeightBearing.imageWithLightsUrl"
                           width="200px" alt="Uploaded Image" v-if="PlantarViewWeightBearing.imageWithLightsUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!PlantarViewWeightBearing.imageWithLightsUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div
                      v-if="!PlantarViewWeightBearing.imageWithLightsUrl && this.formData.plantarViewWeightBearingImageWithLights">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.plantarViewWeightBearingImageWithLights)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewWeightBearingImageWithLights)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewWeightBearingImageWithLights)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PlantarViewWeightBearing', formData.plantarViewWeightBearingDataId, 'imageWithLights')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="plantarViewWeightBearingImageWithoutLights">{{ $t('Image without lights') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PlantarViewWeightBearing-imageWithoutLights"
                        @change="handleImagesUpload('PlantarViewWeightBearing', 'imageWithoutLights')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="PlantarViewWeightBearing.imageWithoutLightsUrl"
                           width="200px" alt="Uploaded Image" v-if="PlantarViewWeightBearing.imageWithoutLightsUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!PlantarViewWeightBearing.imageWithoutLightsUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div
                      v-if="!PlantarViewWeightBearing.imageWithoutLightsUrl && this.formData.plantarViewWeightBearingImageWithoutLights">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.plantarViewWeightBearingImageWithoutLights)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewWeightBearingImageWithoutLights)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewWeightBearingImageWithoutLights)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PlantarViewWeightBearing', formData.plantarViewWeightBearingDataId, 'imageWithoutLights')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="plantarViewWeightBearingComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="plantarViewWeightBearingComments"
                      rows="4" v-model="formData.plantarViewWeightBearingComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="plantarViewWeightBearingAddToReport"
                        v-model="formData.plantarViewWeightBearingAddToReport">
                      <label class="form-check-label" for="plantarViewWeightBearingAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="savePlantarViewWeightBearing">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Plantar View Non Weight bearing -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#PlantarViewNonWeightBearing" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !plantarViewNonWeightBearing
              }">
              <h5 class="card-title">{{ $t('Plantar View Non Weight bearing') }}
                <span v-if="plantarViewNonWeightBearing" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="PlantarViewNonWeightBearing" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="plantarViewNonWeightBearingDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="plantarViewNonWeightBearingDate"
                      v-model="formData.plantarViewNonWeightBearingDate">
                  </div>
                  <div class="form-group">
                    <label for="plantarViewNonWeightBearingImageRight">{{ $t('Image Right') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PlantarViewNonWeightBearing-imageRight"
                        @change="handleImagesUpload('PlantarViewNonWeightBearing', 'imageRight')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="PlantarViewNonWeightBearing.imageRightUrl"
                           width="200px" alt="Uploaded Image" v-if="PlantarViewNonWeightBearing.imageRightUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!PlantarViewNonWeightBearing.imageRightUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div
                      v-if="!PlantarViewNonWeightBearing.imageRightUrl && this.formData.plantarViewNonWeightBearingImageRight">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.plantarViewNonWeightBearingImageRight)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewNonWeightBearingImageRight)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewNonWeightBearingImageRight)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PlantarViewNonWeightBearing', formData.plantarViewNonWeightBearingDataId, 'imageRight')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="plantarViewNonWeightBearingImageLeft">{{ $t('Image Left') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PlantarViewNonWeightBearing-imageLeft"
                        @change="handleImagesUpload('PlantarViewNonWeightBearing', 'imageLeft')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="PlantarViewNonWeightBearing.imageLeftUrl"
                           width="200px" alt="Uploaded Image" v-if="PlantarViewNonWeightBearing.imageLeftUrl" />
                      </div>
                    </div>
                    <div class="text-danger"
                      v-if="!PlantarViewNonWeightBearing.imageLeftUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div
                      v-if="!PlantarViewNonWeightBearing.imageLeftUrl && this.formData.plantarViewNonWeightBearingImageLeft">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.plantarViewNonWeightBearingImageLeft)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewNonWeightBearingImageLeft)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.plantarViewNonWeightBearingImageLeft)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PlantarViewNonWeightBearing', formData.plantarViewNonWeightBearingDataId, 'imageLeft')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="plantarViewNonWeightBearingComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="plantarViewNonWeightBearingComments"
                      rows="4" v-model="formData.plantarViewNonWeightBearingComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="plantarViewNonWeightBearingAddToReport"
                        v-model="formData.plantarViewNonWeightBearingAddToReport">
                      <label class="form-check-label" for="plantarViewNonWeightBearingAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="savePlantarViewNonWeightBearing">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Posterior view in gait -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#PosteriorViewinGait" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !posteriorViewInGait
              }">
              <h5 class="card-title">{{ $t('Posterior view in gait') }}
                <span v-if="posteriorViewInGait" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="PosteriorViewinGait" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="posteriorViewInGaitDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="posteriorViewInGaitDate"
                      v-model="formData.posteriorViewInGaitDate">
                  </div>
                  <div class="form-group">
                    <label for="posteriorViewInGaitImageRight">{{ $t('Image Right') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PosteriorViewinGait-imageRight"
                        @change="handleImagesUpload('PosteriorViewinGait', 'imageRight')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="PosteriorViewinGait.imageRightUrl"
                           width="200px" alt="Uploaded Image" v-if="PosteriorViewinGait.imageRightUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!PosteriorViewinGait.imageRightUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!PosteriorViewinGait.imageRightUrl && this.formData.posteriorViewInGaitImageRight">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.posteriorViewInGaitImageRight)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewInGaitImageRight)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewInGaitImageRight)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PosteriorViewinGait', formData.posteriorViewInGaitDataId, 'imageRight')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="posteriorViewInGaitImageLeft">{{ $t('Image Left') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="PosteriorViewinGait-imageLeft"
                        @change="handleImagesUpload('PosteriorViewinGait', 'imageLeft')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img :src="PosteriorViewinGait.imageLeftUrl"  width="200px" alt="Uploaded Image"
                          v-if="PosteriorViewinGait.imageLeftUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!PosteriorViewinGait.imageLeftUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!PosteriorViewinGait.imageLeftUrl && this.formData.posteriorViewInGaitImageLeft">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.posteriorViewInGaitImageLeft)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewInGaitImageLeft)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.posteriorViewInGaitImageLeft)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('PosteriorViewinGait', formData.posteriorViewInGaitDataId, 'imageLeft')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="posteriorViewInGaitComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="posteriorViewInGaitComments" rows="4"
                      v-model="formData.posteriorViewInGaitComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="posteriorViewInGaitAddToReport"
                        v-model="formData.posteriorViewInGaitAddToReport">
                      <label class="form-check-label" for="posteriorViewInGaitAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="savePosteriorViewInGait">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Anterior view in gait -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#AnteriorViewinGait" aria-expanded="false" aria-controls="permission"
              :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !anteriorViewInGait
              }">
              <h5 class="card-title">{{ $t('Anterior view in gait') }}
                <span v-if="anteriorViewInGait" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="AnteriorViewinGait" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="anteriorViewInGaitDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="anteriorViewInGaitDate"
                      v-model="formData.anteriorViewInGaitDate">
                  </div>
                  <div class="form-group">
                    <label for="anteriorViewInGaitImageRight">{{ $t('Image Right') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="AnteriorViewinGait-imageRight"
                        @change="handleImagesUpload('AnteriorViewinGait', 'imageRight')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="AnteriorViewinGait.imageRightUrl"
                           width="200px" alt="Uploaded Image" v-if="AnteriorViewinGait.imageRightUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!AnteriorViewinGait.imageRightUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!AnteriorViewinGait.imageRightUrl && this.formData.anteriorViewInGaitImageRight">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.anteriorViewInGaitImageRight)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.anteriorViewInGaitImageRight)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.anteriorViewInGaitImageRight)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('AnteriorViewinGait', formData.anteriorViewInGaitDataId, 'imageRight')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="anteriorViewInGaitImageLeft">{{ $t('Image Left') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="AnteriorViewinGait-imageLeft"
                        @change="handleImagesUpload('AnteriorViewinGait', 'imageLeft')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="AnteriorViewinGait.imageLeftUrl"
                           width="200px" alt="Uploaded Image" v-if="AnteriorViewinGait.imageLeftUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!AnteriorViewinGait.imageLeftUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!AnteriorViewinGait.imageLeftUrl && this.formData.anteriorViewInGaitImageLeft">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px"
                          :src="urlGenerator(this.formData.anteriorViewInGaitImageLeft)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.anteriorViewInGaitImageLeft)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.anteriorViewInGaitImageLeft)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('AnteriorViewinGait', formData.anteriorViewInGaitDataId, 'imageLeft')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="anteriorViewInGaitComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="anteriorViewInGaitComments" rows="4"
                      v-model="formData.anteriorViewInGaitComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="anteriorViewInGaitAddToReport"
                        v-model="formData.anteriorViewInGaitAddToReport">
                      <label class="form-check-label" for="anteriorViewInGaitAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveAnteriorViewInGait">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Posture -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#Posture" aria-expanded="false" aria-controls="permission" :class="{
              'custom-checkbox-default': true,
              'd-block': true,
              'position-relative': true,
              'text-capitalize': true,
              'collapsible-link': true,
              'py-2': true,
              'cursor-pointer': true,
              'collapsed': !posture
            }">
              <h5 class="card-title">{{ $t('Posture') }}
                <span v-if="posture" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="Posture" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="postureDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="postureDate"
                      v-model="formData.postureDate">
                  </div>
                  <div class="form-group">
                    <label for="postureFrontImage">{{ $t('Front Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="Posture-frontImage" @change="handleImagesUpload('Posture', 'frontImage')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="Posture.frontImageUrl"  width="200px" alt="Uploaded Image"
                          v-if="Posture.frontImageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!Posture.frontImageUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!Posture.frontImageUrl && this.formData.postureFrontImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px" :src="urlGenerator(this.formData.postureFrontImage)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureFrontImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureFrontImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('Posture', formData.postureDataId, 'frontImage')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="postureSideImage">{{ $t('Side Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="Posture-sideImage" @change="handleImagesUpload('Posture', 'sideImage')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="Posture.sideImageUrl"  width="200px" alt="Uploaded Image"
                          v-if="Posture.sideImageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!Posture.sideImageUrl && Posture.sideImage">{{
                      Posture.sideImage }}</div>
                    <div v-if="!Posture.sideImageUrl && this.formData.postureSideImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px" :src="urlGenerator(this.formData.postureSideImage)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureSideImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureSideImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('Posture', formData.postureDataId, 'sideImage')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="postureTopImage">{{ $t('Top Image') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="Posture-topImage" @change="handleImagesUpload('Posture', 'topImage')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="Posture.topImageUrl"  width="200px" alt="Uploaded Image"
                          v-if="Posture.topImageUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!Posture.topImageUrl && Posture.topImage">{{
                      Posture.topImage }}</div>
                    <div v-if="!Posture.topImageUrl && this.formData.postureTopImage">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px" :src="urlGenerator(this.formData.postureTopImage)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureTopImage)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.postureTopImage)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('Posture', formData.postureDataId, 'topImage')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="postureComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="postureComments" rows="4"
                      v-model="formData.postureComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="postureAddToReport"
                        v-model="formData.postureAddToReport">
                      <label class="form-check-label" for="postureAddToReport">{{ $t('Add to Report') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="savePosture">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Correction evidence -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#ImageWithcCorrection" aria-expanded="false"
              aria-controls="permission" :class="{
                'custom-checkbox-default': true,
                'd-block': true,
                'position-relative': true,
                'text-capitalize': true,
                'collapsible-link': true,
                'py-2': true,
                'cursor-pointer': true,
                'collapsed': !imageWithCorrection
              }">
              <h5 class="card-title">{{ $t('Correction evidence') }}
                <span v-if="imageWithCorrection" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="ImageWithcCorrection" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="imageWithCorrectionDate">{{ $t('Date') }}:</label>
                    <input type="date" class="form-control" placeholder="Date" id="imageWithCorrectionDate"
                      v-model="formData.imageWithCorrectionDate">
                  </div>
                  <div class="form-group">
                    <label for="image1">{{ $t('Image 1') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="ImageWithcCorrection-imageOne"
                        @change="handleImagesUpload('ImageWithcCorrection', 'imageOne')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="ImageWithcCorrection.imageOneUrl"
                           width="200px" alt="Uploaded Image" v-if="ImageWithcCorrection.imageOneUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!ImageWithcCorrection.imageOneUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!ImageWithcCorrection.imageOneUrl && this.formData.image1">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail" width="200px" :src="urlGenerator(this.formData.image1)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.image1)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.image1)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('ImageWithcCorrection', formData.postureDataId, 'imageOne')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="image2">{{ $t('Image 2') }}:</label>
                    <div>
                      <!-- Input for Image Upload -->
                      <input type="file" class="form-control" accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                        ref="ImageWithcCorrection-imageTwo"
                        @change="handleImagesUpload('ImageWithcCorrection', 'imageTwo')" />
                      <div class="row col-md-6">
                        <!-- Display Uploaded Image -->
                        <img class="rounded img-fluid img-thumbnail" :src="ImageWithcCorrection.imageTwoUrl"
                           width="200px" alt="Uploaded Image" v-if="ImageWithcCorrection.imageTwoUrl" />
                      </div>
                    </div>
                    <div class="text-danger" v-if="!ImageWithcCorrection.imageTwoUrl && fileValidationError">{{ fileValidationError }}</div>
                    <div v-if="!ImageWithcCorrection.imageTwoUrl && this.formData.image2">
                      <div class="row col-md-6">
                        <img class="rounded img-fluid img-thumbnail"  width="200px":src="urlGenerator(this.formData.image2)"
                          alt="Medial View Weight Bearing Right" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.image2)" target="_blank"><app-icon name="eye"/></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(this.formData.image2)" download target="_blank"><app-icon name="download"/></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm"
                          v-if="!isDisable" @click="() => deleteExaminatonImage('ImageWithcCorrection', formData.postureDataId, 'imageTwo')"><app-icon name="trash-2"/></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="imageWithCorrectionComments">{{ $t('Comments') }}:</label>
                    <textarea class="form-control" placeholder="Enter comments" id="imageWithCorrectionComments" rows="4"
                      v-model="formData.imageWithCorrectionComments"></textarea>
                  </div>
                  <div class="col-md-4">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="imageWithCorrectionAddToReport"
                        v-model="formData.imageWithCorrectionAddToReport">
                      <label class="form-check-label" for="imageWithCorrectionAddToReport">{{ $t('Add to Report')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveImageWithCorrection">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-confirmation-modal v-if="confirmationModalActive" icon="trash-2"
        modal-id="app-status-change-confirmation-modal" :message="confirmationMessage" @confirmed="lotStatusChangeConfirm"
        @cancelled="lotStatusChangeCancel" />
    </form>
  </fieldset>
</template>
<script>
import {
  FIELDS,
  EXAMINATIONS,
  DROPDOWN_OPTIONS,
  FOOTPRESSURE,
  FOOTPRESSURELATEST,
  FOOTPRESSUREORTHOTICS,
  DELETE_IMAGE,
  DYNAMIC_ANALYSIS,
  DYNAMIC_ANALYSIS_LATEST,
  DYNAMIC_ANALYSIS_ORTHOTICS,
  DIABETIC_FOOT,
  POSTERIOR_VIEW,
  TOP_VIEW_WEIGHT_BEARING,
  MEDIAL_VIEW_WEIGHT_BEARING,
  PLANTAR_VIEW_WEIGHT_BEARING,
  PLANTAR_VIEW_NON_WEIGHT_BEARING,
  POSTERIOR_VIEW_IN_GAIT,
  ANTERIOR_VIEW_IN_GAIT,
  POSTURE,
  IMAGE_WITH_CORRECTION,
  GET_ALL,
} from "../../../../Config/ApiUrl-CPB";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "../../../../../common/Helper/AxiosHelper";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";

export default {
  mixins: [HelperMixin, FormHelperMixins],
  props: {
    props: {
      customerId: {},
    },
  },
  data() {
    return {
      examination: null,
      footPressureAnalysisData: null,
      footPressureAnalysisDataLatest: null,
      footPressureAnalysisDataOrthotics: null,
      dynamicAnalysisData: null,
      dynamicAnalysisDataLatest: null,
      dynamicAnalysisDataOrthotics: null,
      diabeticFootRiskRatio: null,
      posteriorViewStatic: null,
      topViewWeightBearing: null,
      medialViewWeightBearing: null,
      plantarViewWeightBearing: null,
      plantarViewNonWeightBearing: null,
      posteriorViewInGait: null,
      anteriorViewInGait: null,
      posture: null,
      imageWithCorrection: null,
      fields: [],
      dropdownOptions: {},
      imageFile: null,
      imageUrl: null,
      MedialViewWeightBearing: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
      },
      PlantarViewWeightBearing: {
        imageWithLights: null,
        imageWithLightsUrl: null,
        imageWithoutLights: null,
        imageWithoutLightsUrl: null,
      },
      PlantarViewNonWeightBearing: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
      },
      PosteriorViewinGait: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
      },
      AnteriorViewinGait: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
      },
      Posture: {
        frontImage: null,
        frontImageUrl: null,
        sideImage: null,
        sideImageUrl: null,
        topImage: null,
        topImageUrl: null,
      },
      ImageWithcCorrection: {
        imageOne: null,
        imageOneUrl: null,
        imageTwo: null,
        imageTwoUrl: null,
      },
      formData: {
        title: '',
        date: '',
        addToReport: false,
        exAddToReport: false,
        entireLeft: 0,
        entireRight: 0,
        leftTop: 0,
        leftBottom: 0,
        rightTop: 0,
        rightBottom: 0,
        comments: '',
        dynamicImage: '',
        maximumPressureRightKPa: 0,
        maximumPressureLeftKPa: 0,
        dynamicComments: '',
        dynamicAddToReport: false,
        examination_fields_comments: '',
      },
      fileValidationError: null,
      isDisable: (this.$can('edit_patient_measurements') == true)?false:true,
      confirmationMessage: '',
      confirmationModalActive: false,
      examinationPrintUrl: '',
    };
  },
  async created() {
    if(!this.isDisable) {
        this.isDisable = this.props[1];
    }
    this.examinationPrintUrl = this.urlGenerator('app/examination/print/'+this.props[0])
    try {
      axiosGet(`${FIELDS}`).then((response) => {
        this.fields = response.data;
        this.initializeFormData();
      }).catch((error) => {
        this.$toastr.e(error);
      }).finally(() => {
        this.loading = false;
      });

      axiosGet(`${DROPDOWN_OPTIONS}`).then(response => {
        // Group the dropdown options by 'field_id'
        this.dropdownOptions = response.data.reduce((acc, option) => {
          if (!acc[option.field_id]) {
            acc[option.field_id] = [];
          }
          acc[option.field_id].push({ id: option.id, value: option.value });
          return acc;
        }, {});
      }).catch(error => {
        console.error(error);
      });

      const customerId = this.props[0];
      await this.fetchAllExaminationData(customerId);
    } catch (error) {
      console.error(error);
      this.$toastr.e('Error fetching data');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    deleteExaminatonImage(tableName, imageId, imageType) {
      // Set confirmation message and image details
      this.confirmationMessage = "Are you sure you want to delete this image?";
      this.imageToDelete = {
        tableName,
        imageId,
        imageType,
      };

      // Show the confirmation modal
      this.confirmationModalActive = true;
    },
    lotStatusChangeConfirm() {
      // Perform the image deletion
      const { tableName, imageId, imageType } = this.imageToDelete;
      axiosDelete(DELETE_IMAGE + `${tableName}/${imageId}/${imageType}`, {
        params: { imageType: imageType },
      })
        .then((response) => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          this.toastAndReload(response.data.message);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error(error);
        });
    },
    lotStatusChangeCancel() {
      this.confirmationMessage = '';
      this.confirmationModalActive = false;
    },
    handleImageUpload(event) {
      this.imageFile = event.target.files[0];
      // File type validation (example: allow only image files)
      const allowedFileTypes = ['image/jpeg', 'image/gif', 'application/cdr', 'application/dxf', 'image/svg+xml', 'application/pdf', 'application/stl', 'application/cnc', 'application/imed', 'image/png', 'image/vnd.adobe.photoshop', 'text/plain'];
      if (!allowedFileTypes.includes(this.imageFile.type)) {
        this.fileValidationError = 'Invalid file type. Please select an image (jpg, jpeg, gif, cdr, dxf, pdf, stl, cnc, imed, png, psd, txt).';
        return;
      }
      this.fileValidationError = null;
      // this.formData.posturalImage = this.imageFile;
      this.imageUrl = URL.createObjectURL(this.imageFile);
    },
    handleImagesUpload(section, imageProperty) {
      const fileInput = this.$refs[`${section}-${imageProperty}`];
      const allowedFileTypes = ['image/jpeg', 'image/gif', 'application/cdr', 'application/dxf', 'image/svg+xml', 'application/pdf', 'application/stl', 'application/cnc', 'application/imed', 'image/png', 'image/vnd.adobe.photoshop', 'text/plain'];

      // Check if fileInput exists and has files
      if (fileInput && fileInput.files.length > 0) {
        const imageFile = fileInput.files[0];

        // Check if the selected file type is allowed
        if (!allowedFileTypes.includes(imageFile.type)) {
          this[section][imageProperty] = null; // Clear the invalid image property
          this[section][imageProperty] = `The selected file for "${imageProperty}" is not an image (jpg, jpeg, gif, cdr, dxf, pdf, stl, cnc, imed, png, psd, txt).`;
          return; // Stop further processing
        }

        this.fileValidationError = null;

        // Set the image property and its URL dynamically
        this[section][imageProperty] = imageFile;

        // Generate the image URL for display
        this[section][`${imageProperty}Url`] = URL.createObjectURL(imageFile);
      } else {
        // Clear the image property and URL if no file is selected
        this[section][imageProperty] = null;
        this[section][`${imageProperty}Url`] = null;
        this.fileValidationError = null;
      }
    },
    fetchAllExaminationData(customerId) {
      axiosGet(`${GET_ALL}/${customerId}`)
        .then(response => {
          // Assuming the response.data contains the examination data for the customer
          this.examination = response.data.examination_data.examinations;
          this.formData.exAddToReport = response.data.examination_data.examinations_add_to_report;
          this.formData.examination_fields_comments = response.data.examination_data.examination_fields_comments;
          const relatedData = response.data.related_data;
          this.footPressureAnalysisData = relatedData.foot_pressure_analysis;
          this.footPressureAnalysisDataLatest = relatedData.foot_pressure_analysis_latest;
          this.footPressureAnalysisDataOrthotics = relatedData.foot_pressure_analysis_orthotics;
          this.dynamicAnalysisData = relatedData.dynamic_analysis;
          this.dynamicAnalysisDataLatest = relatedData.dynamic_analysis_latest;
          this.dynamicAnalysisDataOrthotics = relatedData.dynamic_analysis_orthotics;
          this.diabeticFootRiskRatio = relatedData.diabetic_foot_risk_ratio;
          this.posteriorViewStatic = relatedData.posterior_view_static;
          this.topViewWeightBearing = relatedData.top_view_weight_bearing;
          this.medialViewWeightBearing = relatedData.medial_view_weight_bearing;
          this.plantarViewWeightBearing = relatedData.plantar_view_weight_bearing;
          this.plantarViewNonWeightBearing = relatedData.plantar_view_non_weight_bearing;
          this.posteriorViewInGait = relatedData.posterior_view_in_gait;
          this.anteriorViewInGait = relatedData.anterior_view_in_gait;
          this.posture = relatedData.posture;
          this.imageWithCorrection = relatedData.image_with_correction;

          if (this.examination) {
            // this.examination
            for (const data of this.examination) {
              const fieldId = data.field_id;
              // Set the values for other fields
              this.formData[fieldId].left = data.left_option_id;
              this.formData[fieldId].right = data.right_option_id;
              this.formData[fieldId].leg_length_discrepancy_left = data.leg_length_discrepancy_left;
              this.formData[fieldId].leg_length_discrepancy_right = data.leg_length_discrepancy_right;
            }
          }

          if (this.footPressureAnalysisData) {
            // this.footPressureAnalysisData
            // this.formData.title = this.footPressureAnalysisData.title;
            this.formData.date = this.footPressureAnalysisData.date;
            this.formData.posturalImage = this.footPressureAnalysisData.posturalImage;
            this.formData.entireLeft = this.footPressureAnalysisData.entireLeft;
            this.formData.entireRight = this.footPressureAnalysisData.entireRight;
            this.formData.leftTop = this.footPressureAnalysisData.leftTop;
            this.formData.leftBottom = this.footPressureAnalysisData.leftBottom;
            this.formData.rightTop = this.footPressureAnalysisData.rightTop;
            this.formData.rightBottom = this.footPressureAnalysisData.rightBottom;
            this.formData.comments = this.footPressureAnalysisData.comments;
            this.formData.addToReport = this.footPressureAnalysisData.addToReport;
            this.formData.footPressureDataId = this.footPressureAnalysisData.id;
          }
          if (this.footPressureAnalysisDataLatest) {
            // this.footPressureAnalysisData
            // this.formData.title = this.footPressureAnalysisData.title;
            this.formData.footLatestdate = this.footPressureAnalysisDataLatest.date;
            this.formData.footLatestposturalImage = this.footPressureAnalysisDataLatest.posturalImage;
            this.formData.footLatestentireLeft = this.footPressureAnalysisDataLatest.entireLeft;
            this.formData.footLatestentireRight = this.footPressureAnalysisDataLatest.entireRight;
            this.formData.footLatestleftTop = this.footPressureAnalysisDataLatest.leftTop;
            this.formData.footLatestleftBottom = this.footPressureAnalysisDataLatest.leftBottom;
            this.formData.footLatestrightTop = this.footPressureAnalysisDataLatest.rightTop;
            this.formData.footLatestrightBottom = this.footPressureAnalysisDataLatest.rightBottom;
            this.formData.footLatestcomments = this.footPressureAnalysisDataLatest.comments;
            this.formData.footLatestaddToReport = this.footPressureAnalysisDataLatest.addToReport;
            this.formData.footLatestfootPressureDataId = this.footPressureAnalysisDataLatest.id;
          }
          if (this.footPressureAnalysisDataOrthotics) {
            // this.footPressureAnalysisData
            // this.formData.title = this.footPressureAnalysisData.title;
            this.formData.footOrthoticsdate = this.footPressureAnalysisDataOrthotics.date;
            this.formData.footOrthoticsposturalImage = this.footPressureAnalysisDataOrthotics.posturalImage;
            this.formData.footOrthoticsentireLeft = this.footPressureAnalysisDataOrthotics.entireLeft;
            this.formData.footOrthoticsentireRight = this.footPressureAnalysisDataOrthotics.entireRight;
            this.formData.footOrthoticsleftTop = this.footPressureAnalysisDataOrthotics.leftTop;
            this.formData.footOrthoticsleftBottom = this.footPressureAnalysisDataOrthotics.leftBottom;
            this.formData.footOrthoticsrightTop = this.footPressureAnalysisDataOrthotics.rightTop;
            this.formData.footOrthoticsrightBottom = this.footPressureAnalysisDataOrthotics.rightBottom;
            this.formData.footOrthoticscomments = this.footPressureAnalysisDataOrthotics.comments;
            this.formData.footOrthoticsaddToReport = this.footPressureAnalysisDataOrthotics.addToReport;
            this.formData.footOrthoticsfootPressureDataId = this.footPressureAnalysisDataOrthotics.id;
          }

          if (this.dynamicAnalysisData) {
            // this.dynamicAnalysisData
            this.formData.dynamicImage = this.dynamicAnalysisData.dynamic_image;
            this.formData.maximumPressureRightKPa = this.dynamicAnalysisData.maximum_pressure_right_kpa;
            this.formData.maximumPressureLeftKPa = this.dynamicAnalysisData.maximum_pressure_left_kpa;
            this.formData.dynamicComments = this.dynamicAnalysisData.dynamic_comments;
            this.formData.dynamicAddToReport = this.dynamicAnalysisData.dynamic_add_to_report;
            this.formData.dynamicDataId = this.dynamicAnalysisData.id;
          }
          if (this.dynamicAnalysisDataLatest) {
            // this.dynamicAnalysisData
            this.formData.dynamicImageLatest = this.dynamicAnalysisDataLatest.dynamic_image;
            this.formData.maximumPressureRightKPaLatest = this.dynamicAnalysisDataLatest.maximum_pressure_right_kpa;
            this.formData.maximumPressureLeftKPaLatest = this.dynamicAnalysisDataLatest.maximum_pressure_left_kpa;
            this.formData.dynamicCommentsLatest = this.dynamicAnalysisDataLatest.dynamic_comments;
            this.formData.dynamicAddToReportLatest = this.dynamicAnalysisDataLatest.dynamic_add_to_report;
            this.formData.dynamicDataLatestId = this.dynamicAnalysisDataLatest.id;
          }
          if (this.dynamicAnalysisDataOrthotics) {
            // this.dynamicAnalysisData
            this.formData.dynamicImageOrthotics = this.dynamicAnalysisDataOrthotics.dynamic_image;
            this.formData.maximumPressureRightKPaOrthotics = this.dynamicAnalysisDataOrthotics.maximum_pressure_right_kpa;
            this.formData.maximumPressureLeftKPaOrthotics = this.dynamicAnalysisDataOrthotics.maximum_pressure_left_kpa;
            this.formData.dynamicCommentsOrthotics = this.dynamicAnalysisDataOrthotics.dynamic_comments;
            this.formData.dynamicAddToReportOrthotics = this.dynamicAnalysisDataOrthotics.dynamic_add_to_report;
            this.formData.dynamicDataOrthoticsId = this.dynamicAnalysisDataOrthotics.id;
          }

          if (this.diabeticFootRiskRatio) {
            // this.diabeticFootRiskRatio
            this.formData.diabeticFootDate = this.diabeticFootRiskRatio.diabetic_foot_risk_ratio_date;
            this.formData.diabeticFootImage = this.diabeticFootRiskRatio.diabetic_foot_risk_ratio_report_image;
            this.formData.diabeticFootComments = this.diabeticFootRiskRatio.diabetic_foot_risk_ratio_comments;
            this.formData.diabeticFootAddToReport = this.diabeticFootRiskRatio.diabetic_foot_risk_ratio_add_to_report;
            this.formData.diabeticFootRiskDataId = this.diabeticFootRiskRatio.id;
          }

          if (this.posteriorViewStatic) {
            // this.posteriorViewStatic
            this.formData.posteriorViewStaticDate = this.posteriorViewStatic.posterior_view_static_date;
            this.formData.posteriorViewStaticImage = this.posteriorViewStatic.posterior_view_static_image;
            this.formData.posteriorViewStaticComments = this.posteriorViewStatic.posterior_view_static_comments;
            this.formData.posteriorViewStaticAddToReport = this.posteriorViewStatic.posterior_view_static_add_to_report;
            this.formData.posteriorViewStaticDataId = this.posteriorViewStatic.id;
          }

          if (this.topViewWeightBearing) {
            // topViewWeightBearing
            this.formData.topViewWeightBearingDate = this.topViewWeightBearing.top_view_weight_bearing_date;
            this.formData.topViewWeightBearingImage = this.topViewWeightBearing.top_view_weight_bearing_image;
            this.formData.topViewWeightBearingComments = this.topViewWeightBearing.top_view_weight_bearing_comments;
            this.formData.topViewWeightBearingAddToReport = this.topViewWeightBearing.top_view_weight_bearing_add_to_report;
            this.formData.topViewWeightBearingDataId = this.topViewWeightBearing.id;
          }

          if (this.medialViewWeightBearing) {
            // medialViewWeightBearing
            this.formData.medialViewWeightBearingDate = this.medialViewWeightBearing.medial_view_weight_bearing_date;
            this.formData.medialViewWeightBearingImageRight = this.medialViewWeightBearing.medial_view_weight_bearing_image_right;
            this.formData.medialViewWeightBearingImageLeft = this.medialViewWeightBearing.medial_view_weight_bearing_image_left;
            this.formData.medialViewWeightBearingComments = this.medialViewWeightBearing.medial_view_weight_bearing_comments;
            this.formData.medialViewWeightBearingAddToReport = this.medialViewWeightBearing.medial_view_weight_bearing_add_to_report;
            this.formData.medialViewWeightBearingDataId = this.medialViewWeightBearing.id;
          }

          if (this.plantarViewWeightBearing) {
            // plantarViewWeightBearing
            this.formData.plantarViewWeightBearingDate = this.plantarViewWeightBearing.plantar_view_weight_bearing_date;
            this.formData.plantarViewWeightBearingImageWithLights = this.plantarViewWeightBearing.plantar_view_weight_bearing_image_with_lights;
            this.formData.plantarViewWeightBearingImageWithoutLights = this.plantarViewWeightBearing.plantar_view_weight_bearing_image_without_lights;
            this.formData.plantarViewWeightBearingComments = this.plantarViewWeightBearing.plantar_view_weight_bearing_comments;
            this.formData.plantarViewWeightBearingAddToReport = this.plantarViewWeightBearing.plantar_view_weight_bearing_add_to_report;
            this.formData.plantarViewWeightBearingDataId = this.plantarViewWeightBearing.id;
          }

          if (this.plantarViewNonWeightBearing) {
            // plantarViewNonWeightBearing
            this.formData.plantarViewNonWeightBearingDate = this.plantarViewNonWeightBearing.plantar_view_non_weight_bearing_date;
            this.formData.plantarViewNonWeightBearingImageRight = this.plantarViewNonWeightBearing.plantar_view_non_weight_bearing_image_right;
            this.formData.plantarViewNonWeightBearingImageLeft = this.plantarViewNonWeightBearing.plantar_view_non_weight_bearing_image_left;
            this.formData.plantarViewNonWeightBearingComments = this.plantarViewNonWeightBearing.plantar_view_non_weight_bearing_comments;
            this.formData.plantarViewNonWeightBearingAddToReport = this.plantarViewNonWeightBearing.plantar_view_non_weight_bearing_add_to_report;
            this.formData.plantarViewNonWeightBearingDataId = this.plantarViewNonWeightBearing.id;
          }

          if (this.posteriorViewInGait) {
            // posteriorViewInGait
            this.formData.posteriorViewInGaitDate = this.posteriorViewInGait.posterior_view_in_gait_date;
            this.formData.posteriorViewInGaitImageRight = this.posteriorViewInGait.posterior_view_in_gait_image_right;
            this.formData.posteriorViewInGaitImageLeft = this.posteriorViewInGait.posterior_view_in_gait_image_left;
            this.formData.posteriorViewInGaitComments = this.posteriorViewInGait.posterior_view_in_gait_comments;
            this.formData.posteriorViewInGaitAddToReport = this.posteriorViewInGait.posterior_view_in_gait_add_to_report;
            this.formData.posteriorViewInGaitDataId = this.posteriorViewInGait.id;
          }

          if (this.anteriorViewInGait) {
            // anteriorViewInGait
            this.formData.anteriorViewInGaitDate = this.anteriorViewInGait.anterior_view_in_gait_date;
            this.formData.anteriorViewInGaitImageRight = this.anteriorViewInGait.anterior_view_in_gait_image_right;
            this.formData.anteriorViewInGaitImageLeft = this.anteriorViewInGait.anterior_view_in_gait_image_left;
            this.formData.anteriorViewInGaitComments = this.anteriorViewInGait.anterior_view_in_gait_comments;
            this.formData.anteriorViewInGaitAddToReport = this.anteriorViewInGait.anterior_view_in_gait_add_to_report;
            this.formData.anteriorViewInGaitDataId = this.anteriorViewInGait.id;
          }

          if (this.posture) {
            // posture
            this.formData.postureDate = this.posture.posture_date;
            this.formData.postureFrontImage = this.posture.posture_front_image;
            this.formData.postureSideImage = this.posture.posture_side_image;
            this.formData.postureTopImage = this.posture.posture_top_image;
            this.formData.postureComments = this.posture.posture_comments;
            this.formData.postureAddToReport = this.posture.posture_add_to_report;
            this.formData.postureDataId = this.posture.id;
          }

          if (this.imageWithCorrection) {
            // imageWithCorrection
            this.formData.imageWithCorrectionDate = this.imageWithCorrection.image_with_correction_date;
            this.formData.image1 = this.imageWithCorrection.image1;
            this.formData.image2 = this.imageWithCorrection.image2;
            this.formData.imageWithCorrectionComments = this.imageWithCorrection.image_with_correction_comments;
            this.formData.imageWithCorrectionAddToReport = this.imageWithCorrection.image_with_correction_add_to_report;
            this.formData.imageWithCorrectionDataId = this.imageWithCorrection.id;
          }
        })
        .catch(error => {
          console.error(error);
          // Handle the error if needed
        });
    },
    initializeFormData() {
      for (const field of this.fields) {
        this.$set(this.formData, field.id, { left: null, right: null });
      }
    },
    saveExaminaton() {
      // If in edit mode, call updateExaminationData with fieldId
      if (this.fieldId) {
        this.updateExaminationData(this.fieldId);
      } else {
        // If in insert mode, call insertExaminationData
        this.insertExaminationData();
      }
    },
    saveFootPressureAnalysis(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      // console.log("title", this.formData.title);
      // formData.append("title", this.formData.title);
      formData.append("date", this.formData.date);
      formData.append("entireLeft", this.formData.entireLeft);
      formData.append("entireRight", this.formData.entireRight);
      formData.append("leftTop", this.formData.leftTop);
      formData.append("leftBottom", this.formData.leftBottom);
      formData.append("rightTop", this.formData.rightTop);
      formData.append("rightBottom", this.formData.rightBottom);
      formData.append("comments", this.formData.comments);
      formData.append("addToReport", this.formData.addToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("posturalImage", this.imageFile);
      }

      // Perform insert or update based on whether footPressureData exists
      if (this.formData.footPressureDataId) {
        this.updateFootPressureData(this.formData.footPressureDataId, formData);
      } else {
        this.insertFootPressureData(formData);
      }
    },
    saveFootPressureAnalysisLatest(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      // console.log("title", this.formData.title);
      // formData.append("title", this.formData.title);
      formData.append("date", this.formData.footLatestdate);
      formData.append("entireLeft", this.formData.footLatestentireLeft);
      formData.append("entireRight", this.formData.footLatestentireRight);
      formData.append("leftTop", this.formData.footLatestleftTop);
      formData.append("leftBottom", this.formData.footLatestleftBottom);
      formData.append("rightTop", this.formData.footLatestrightTop);
      formData.append("rightBottom", this.formData.footLatestrightBottom);
      formData.append("comments", this.formData.footLatestcomments);
      formData.append("addToReport", this.formData.footLatestaddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("posturalImage", this.imageFile);
      }

      // Perform insert or update based on whether footPressureData exists
      if (this.formData.footLatestfootPressureDataId) {
        this.updateFootPressureDataLatest(this.formData.footLatestfootPressureDataId, formData);
      } else {
        this.insertFootPressureDataLatest(formData);
      }
    },
    saveFootPressureAnalysisOrthotics(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      // console.log("title", this.formData.title);
      // formData.append("title", this.formData.title);
      formData.append("date", this.formData.footOrthoticsdate);
      formData.append("entireLeft", this.formData.footOrthoticsentireLeft);
      formData.append("entireRight", this.formData.footOrthoticsentireRight);
      formData.append("leftTop", this.formData.footOrthoticsleftTop);
      formData.append("leftBottom", this.formData.footOrthoticsleftBottom);
      formData.append("rightTop", this.formData.footOrthoticsrightTop);
      formData.append("rightBottom", this.formData.footOrthoticsrightBottom);
      formData.append("comments", this.formData.footOrthoticscomments);
      formData.append("addToReport", this.formData.footOrthoticsaddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("posturalImage", this.imageFile);
      }

      // Perform insert or update based on whether footPressureData exists
      if (this.formData.footOrthoticsfootPressureDataId) {
        this.updateFootPressureDataOrthotics(this.formData.footOrthoticsfootPressureDataId, formData);
      } else {
        this.insertFootPressureDataOrthotics(formData);
      }
    },
    saveDynamicAnalysis(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("maximum_pressure_right_kpa", this.formData.maximumPressureRightKPa);
      formData.append("maximum_pressure_left_kpa", this.formData.maximumPressureLeftKPa);
      formData.append("dynamic_comments", this.formData.dynamicComments);
      formData.append("dynamic_add_to_report", this.formData.dynamicAddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("dynamic_image", this.imageFile);
      }

      // Perform insert or update based on whether dynamicAdditionalData exists
      if (this.formData.dynamicDataId) {
        this.updateDynamicAdditionalData(this.formData.dynamicDataId, formData);
      } else {
        this.insertDynamicAdditionalData(formData);
      }
    },
    saveDynamicAnalysisLatest(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("maximum_pressure_right_kpa", this.formData.maximumPressureRightKPaLatest);
      formData.append("maximum_pressure_left_kpa", this.formData.maximumPressureLeftKPaLatest);
      formData.append("dynamic_comments", this.formData.dynamicCommentsLatest);
      formData.append("dynamic_add_to_report", this.formData.dynamicAddToReportLatest);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("dynamic_image", this.imageFile);
      }

      // Perform insert or update based on whether dynamicAdditionalData exists
      if (this.formData.dynamicDataLatestId) {
        this.updateDynamicAdditionalDataLatest(this.formData.dynamicDataLatestId, formData);
      } else {
        this.insertDynamicAdditionalDataLatest(formData);
      }
    },
    saveDynamicAnalysisOrthotics(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("maximum_pressure_right_kpa", this.formData.maximumPressureRightKPaOrthotics);
      formData.append("maximum_pressure_left_kpa", this.formData.maximumPressureLeftKPaOrthotics);
      formData.append("dynamic_comments", this.formData.dynamicCommentsOrthotics);
      formData.append("dynamic_add_to_report", this.formData.dynamicAddToReportOrthotics);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("dynamic_image", this.imageFile);
      }

      // Perform insert or update based on whether dynamicAdditionalData exists
      if (this.formData.dynamicDataOrthoticsId) {
        this.updateDynamicAdditionalDataOrthotics(this.formData.dynamicDataOrthoticsId, formData);
      } else {
        this.insertDynamicAdditionalDataOrthotics(formData);
      }
    },
    saveDiabeticFootRiskRatio(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("diabetic_foot_risk_ratio_date", this.formData.diabeticFootDate);
      formData.append("diabetic_foot_risk_ratio_comments", this.formData.diabeticFootComments);
      formData.append("diabetic_foot_risk_ratio_add_to_report", this.formData.diabeticFootAddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("diabetic_foot_risk_ratio_report_image", this.imageFile);
      }

      // Perform insert or update based on whether diabeticFootRiskData exists
      if (this.formData.diabeticFootRiskDataId) {
        this.updateDiabeticFootRiskRatio(this.formData.diabeticFootRiskDataId, formData);
      } else {
        this.insertDiabeticFootRiskRatio(formData);
      }
    },
    savePosteriorViewStatic(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("posterior_view_static_date", this.formData.posteriorViewStaticDate);
      formData.append("posterior_view_static_comments", this.formData.posteriorViewStaticComments);
      formData.append("posterior_view_static_add_to_report", this.formData.posteriorViewStaticAddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("posterior_view_static_image", this.imageFile);
      }

      // Perform insert or update based on whether diabeticFootRiskData exists
      if (this.formData.posteriorViewStaticDataId) {
        this.updatePosteriorViewStatic(this.formData.posteriorViewStaticDataId, formData);
      } else {
        this.insertPosteriorViewStatic(formData);
      }

    },
    saveTopViewWeightBearing(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("top_view_weight_bearing_date", this.formData.topViewWeightBearingDate);
      formData.append("top_view_weight_bearing_comments", this.formData.topViewWeightBearingComments);
      formData.append("top_view_weight_bearing_add_to_report", this.formData.topViewWeightBearingAddToReport);
      formData.append("customer_id", this.props[0]);
      if (this.imageFile) {
        formData.append("top_view_weight_bearing_image", this.imageFile);
      }

      // Perform insert or update based on whether topViewWeightBearingDataId exists
      if (this.formData.topViewWeightBearingDataId) {
        this.updateTopViewWeightBearing(this.formData.topViewWeightBearingDataId, formData);
      } else {
        this.insertTopViewWeightBearing(formData);
      }

    },
    saveMedialViewWeightBearing(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("medial_view_weight_bearing_date", this.formData.medialViewWeightBearingDate);
      formData.append("medial_view_weight_bearing_comments", this.formData.medialViewWeightBearingComments);
      formData.append("medial_view_weight_bearing_add_to_report", this.formData.medialViewWeightBearingAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.MedialViewWeightBearing.imageRight) {
        formData.append("medial_view_weight_bearing_image_right", this.MedialViewWeightBearing.imageRight);
      }

      // Check if the left image file is selected
      if (this.MedialViewWeightBearing.imageLeft) {
        formData.append("medial_view_weight_bearing_image_left", this.MedialViewWeightBearing.imageLeft);
      }

      // Perform insert or update based on whether medialViewWeightBearingDataId exists
      if (this.formData.medialViewWeightBearingDataId) {
        this.updateMedialViewWeightBearing(this.formData.medialViewWeightBearingDataId, formData);
      } else {
        this.insertMedialViewWeightBearing(formData);
      }
    },
    savePlantarViewWeightBearing(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("plantar_view_weight_bearing_date", this.formData.plantarViewWeightBearingDate);
      formData.append("plantar_view_weight_bearing_comments", this.formData.plantarViewWeightBearingComments);
      formData.append("plantar_view_weight_bearing_add_to_report", this.formData.plantarViewWeightBearingAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.PlantarViewWeightBearing.imageWithLights) {
        formData.append("plantar_view_weight_bearing_image_with_lights", this.PlantarViewWeightBearing.imageWithLights);
      }

      // Check if the left image file is selected
      if (this.PlantarViewWeightBearing.imageWithoutLights) {
        formData.append("plantar_view_weight_bearing_image_without_lights", this.PlantarViewWeightBearing.imageWithoutLights);
      }

      // Perform insert or update based on whether plantarViewWeightBearingDataId exists
      if (this.formData.plantarViewWeightBearingDataId) {
        this.updatePlantarViewWeightBearing(this.formData.plantarViewWeightBearingDataId, formData);
      } else {
        this.insertPlantarViewWeightBearing(formData);
      }
    },
    savePlantarViewNonWeightBearing(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("plantar_view_non_weight_bearing_date", this.formData.plantarViewNonWeightBearingDate);
      formData.append("plantar_view_non_weight_bearing_comments", this.formData.plantarViewNonWeightBearingComments);
      formData.append("plantar_view_non_weight_bearing_add_to_report", this.formData.plantarViewNonWeightBearingAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.PlantarViewNonWeightBearing.imageRight) {
        formData.append("plantar_view_non_weight_bearing_image_right", this.PlantarViewNonWeightBearing.imageRight);
      }

      // Check if the left image file is selected
      if (this.PlantarViewNonWeightBearing.imageLeft) {
        formData.append("plantar_view_non_weight_bearing_image_left", this.PlantarViewNonWeightBearing.imageLeft);
      }

      // Perform insert or update based on whether plantarViewNonWeightBearingDataId exists
      if (this.formData.plantarViewNonWeightBearingDataId) {
        this.updatePlantarViewNonWeightBearing(this.formData.plantarViewNonWeightBearingDataId, formData);
      } else {
        this.insertPlantarViewNonWeightBearing(formData);
      }
    },
    savePosteriorViewInGait(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("posterior_view_in_gait_date", this.formData.posteriorViewInGaitDate);
      formData.append("posterior_view_in_gait_comments", this.formData.posteriorViewInGaitComments);
      formData.append("posterior_view_in_gait_add_to_report", this.formData.posteriorViewInGaitAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.PosteriorViewinGait.imageRight) {
        formData.append("posterior_view_in_gait_image_right", this.PosteriorViewinGait.imageRight);
      }

      // Check if the left image file is selected
      if (this.PosteriorViewinGait.imageLeft) {
        formData.append("posterior_view_in_gait_image_left", this.PosteriorViewinGait.imageLeft);
      }

      // Perform insert or update based on whether posteriorViewInGaitDataId exists
      if (this.formData.posteriorViewInGaitDataId) {
        this.updatePosteriorViewInGait(this.formData.posteriorViewInGaitDataId, formData);
      } else {
        this.insertPosteriorViewInGait(formData);
      }
    },
    saveAnteriorViewInGait(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("anterior_view_in_gait_date", this.formData.anteriorViewInGaitDate);
      formData.append("anterior_view_in_gait_comments", this.formData.anteriorViewInGaitComments);
      formData.append("anterior_view_in_gait_add_to_report", this.formData.anteriorViewInGaitAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.AnteriorViewinGait.imageRight) {
        formData.append("anterior_view_in_gait_image_right", this.AnteriorViewinGait.imageRight);
      }

      // Check if the left image file is selected
      if (this.AnteriorViewinGait.imageLeft) {
        formData.append("anterior_view_in_gait_image_left", this.AnteriorViewinGait.imageLeft);
      }

      // Perform insert or update based on whether anteriorViewInGaitDataId exists
      if (this.formData.anteriorViewInGaitDataId) {
        this.updateAnteriorViewInGait(this.formData.anteriorViewInGaitDataId, formData);
      } else {
        this.insertAnteriorViewInGait(formData);
      }
    },
    savePosture(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("posture_date", this.formData.postureDate);
      formData.append("posture_comments", this.formData.postureComments);
      formData.append("posture_add_to_report", this.formData.postureAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.Posture.frontImage) {
        formData.append("posture_front_image", this.Posture.frontImage);
      }

      // Check if the right image file is selected
      if (this.Posture.sideImage) {
        formData.append("posture_side_image", this.Posture.sideImage);
      }

      // Check if the left image file is selected
      if (this.Posture.topImage) {
        formData.append("posture_top_image", this.Posture.topImage);
      }

      // Perform insert or update based on whether postureDataId exists
      if (this.formData.postureDataId) {
        this.updatePosture(this.formData.postureDataId, formData);
      } else {
        this.insertPosture(formData);
      }
    },
    saveImageWithCorrection(event) {
      event.preventDefault();

      // Get the data for the Static Foot Pressure Distribution - First section
      const formData = new FormData();
      formData.append("image_with_correction_date", this.formData.imageWithCorrectionDate);
      formData.append("image_with_correction_comments", this.formData.imageWithCorrectionComments);
      formData.append("image_with_correction_add_to_report", this.formData.imageWithCorrectionAddToReport);
      formData.append("customer_id", this.props[0]);

      // Check if the right image file is selected
      if (this.ImageWithcCorrection.imageOne) {
        formData.append("image1", this.ImageWithcCorrection.imageOne);
      }

      // Check if the left image file is selected
      if (this.ImageWithcCorrection.imageTwo) {
        formData.append("image2", this.ImageWithcCorrection.imageTwo);
      }

      // Perform insert or update based on whether imageWithCorrectionDataId exists
      if (this.formData.imageWithCorrectionDataId) {
        this.updateImageWithCorrection(this.formData.imageWithCorrectionDataId, formData);
      } else {
        this.insertImageWithCorrection(formData);
      }
    },

    insertExaminationData() {
      try {
        const relatedData = [];
        for (const fieldId in this.formData) {
          if (this.formData[fieldId]) {
            // console.log(this.formData[fieldId]);
            const formData = this.formData[fieldId];
            relatedData.push({
              customer_id: this.props[0],
              field_id: fieldId,
              left_option_id: formData.left,
              right_option_id: formData.right,
              leg_length_discrepancy_left: formData.leg_length_discrepancy_left,
              leg_length_discrepancy_right: formData.leg_length_discrepancy_right,
            });
          }
        }

        let data = {
          'relatedData':relatedData,
          'exAddToReport':this.formData.exAddToReport,
          'examination_fields_comments':this.formData.examination_fields_comments,
        }

        axiosPost(`${EXAMINATIONS}/${this.props[0]}`, data)
          .then(response => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            this.toastAndReload(response.data.message);
            console.log(response.data.message);
            // this.toastAndReload(response.data.message);
            // Do something after successful data insertion, if needed
          })
          .catch(error => {
            console.error(error);
            // Handle any errors, if needed
          });
        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }
    },

    updateExaminationData(fieldId) {
      try {
        const relatedData = [];
        for (const fieldId in this.formData) {
          if (this.formData[fieldId]) {
            const formData = this.formData[fieldId];
            relatedData.push({
              customer_id: this.props[0],
              field_id: fieldId,
              left_option_id: formData.left,
              right_option_id: formData.right,
              leg_length_discrepancy_left: formData.leg_length_discrepancy_left,
              leg_length_discrepancy_right: formData.leg_length_discrepancy_right,
            });
          }
        }

        let data = {
          'relatedData':relatedData,
          'exAddToReport':this.formData.exAddToReport,
        }

        axiosPatch(`${EXAMINATIONS}/${this.props[0]}/${fieldId}`, data)
          .then(response => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            this.toastAndReload(response.data.message);
            console.log(response.data.message);
            // this.toastAndReload(response.data.message);
            // Do something after successful data update, if needed
          })
          .catch(error => {
            console.error(error);
            // Handle any errors, if needed
          });
        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }
    },

    insertFootPressureData(formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSURE}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(false);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },
    showLoader(hide=false){
      $("div#app").addClass('apploader');
      if(hide) $("div#app").removeClass('apploader');
    },
    updateFootPressureData(footPressureDataId, formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSURE}/${footPressureDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },
    insertFootPressureDataLatest(formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSURELATEST}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;

          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },

    updateFootPressureDataLatest(footPressureDataId, formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSURELATEST}/${footPressureDataId}`, formData)
        .then(response => {
         this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },
    insertFootPressureDataOrthotics(formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSUREORTHOTICS}`, formData)
        .then(response => {
         this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },

    updateFootPressureDataOrthotics(footPressureDataId, formData) {
      this.showLoader();
      axiosPost(`${FOOTPRESSUREORTHOTICS}/${footPressureDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posturalImage);
        });
    },

    // Method to insert dynamic additional data
    insertDynamicAdditionalData(formData) {
      // Assuming you have an API endpoint to handle dynamic additional data insertion
      this.showLoader();
      axiosPost(`${DYNAMIC_ANALYSIS}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },

    // Method to update dynamic additional data
    updateDynamicAdditionalData(dynamicDataId, formData) {
      this.showLoader();
      // Assuming you have an API endpoint to handle dynamic additional data update
      axiosPost(`${DYNAMIC_ANALYSIS}/${dynamicDataId}`, formData)
        .then(response => {
         this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },

    // Method to insert dynamic additional data
    insertDynamicAdditionalDataLatest(formData) {
      this.showLoader();
      // Assuming you have an API endpoint to handle dynamic additional data insertion
      axiosPost(`${DYNAMIC_ANALYSIS_LATEST}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },

    // Method to update dynamic additional data
    updateDynamicAdditionalDataLatest(dynamicDataId, formData) {
      // Assuming you have an API endpoint to handle dynamic additional data update
      this.showLoader();
      axiosPost(`${DYNAMIC_ANALYSIS_LATEST}/${dynamicDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },
     // Method to insert dynamic additional data
    insertDynamicAdditionalDataOrthotics(formData) {
      // Assuming you have an API endpoint to handle dynamic additional data insertion
      this.showLoader();
      axiosPost(`${DYNAMIC_ANALYSIS_ORTHOTICS}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },

    // Method to update dynamic additional data
    updateDynamicAdditionalDataOrthotics(dynamicDataId, formData) {
      // Assuming you have an API endpoint to handle dynamic additional data update
      this.showLoader();
      axiosPost(`${DYNAMIC_ANALYSIS_ORTHOTICS}/${dynamicDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // console.log(response.data.message);
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.dynamic_image);
        });
    },

    // Method to insert Diabetic Foot Risk Ratio data
    insertDiabeticFootRiskRatio(formData) {
      // Assuming you have an API endpoint to handle Diabetic Foot Risk Ratio data insertion
      this.showLoader();
      axiosPost(`${DIABETIC_FOOT}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.diabetic_foot_risk_ratio_report_image);
        });
    },

    // Method to update Diabetic Foot Risk Ratio data
    updateDiabeticFootRiskRatio(diabeticFootRiskDataId, formData) {
      // Assuming you have an API endpoint to handle Diabetic Foot Risk Ratio data update
      this.showLoader();
      axiosPost(`${DIABETIC_FOOT}/${diabeticFootRiskDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.diabetic_foot_risk_ratio_report_image);
        });
    },

    // Method to insert Posterior View Static data
    insertPosteriorViewStatic(formData) {
      // Assuming you have an API endpoint to handle Diabetic Foot Risk Ratio data insertion
      this.showLoader();
      axiosPost(`${POSTERIOR_VIEW}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data insertion, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posterior_view_static_image);
        });
    },

    // Method to update Posterior View Static data
    updatePosteriorViewStatic(posteriorViewStaticDataId, formData) {
      // Assuming you have an API endpoint to handle Diabetic Foot Risk Ratio data update
      this.showLoader();
      axiosPost(`${POSTERIOR_VIEW}/${posteriorViewStaticDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
          // Do something after successful data update, if needed
        })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.posterior_view_static_image);
        });
    },

    // Method to insert Top View Weight Bearing data
    insertTopViewWeightBearing(formData) {
      this.showLoader();
      axiosPost(`${TOP_VIEW_WEIGHT_BEARING}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
        }).catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.top_view_weight_bearing_image);
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Top View Weight Bearing data
    updateTopViewWeightBearing(topViewWeightBearingDataId, formData) {
      this.showLoader();
      axiosPost(`${TOP_VIEW_WEIGHT_BEARING}/${topViewWeightBearingDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.imageUrl = null;
        }).catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.errors.top_view_weight_bearing_image);
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Medial View Weight Bearing data
    insertMedialViewWeightBearing(formData) {
      this.showLoader();
      axiosPost(`${MEDIAL_VIEW_WEIGHT_BEARING}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.MedialViewWeightBearing.imageRightUrl = null;
          this.MedialViewWeightBearing.imageleftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.medial_view_weight_bearing_image_right) {
            this.$toastr.e(error.response.data.errors.medial_view_weight_bearing_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.medial_view_weight_bearing_image_left);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Medial View Weight Bearing data
    updateMedialViewWeightBearing(medialViewWeightBearingDataId, formData) {
      this.showLoader();
      axiosPost(`${MEDIAL_VIEW_WEIGHT_BEARING}/${medialViewWeightBearingDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          console.log(this.MedialViewWeightBearing.imageRightUrl);
          this.MedialViewWeightBearing.imageRightUrl = null;
          this.MedialViewWeightBearing.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.medial_view_weight_bearing_image_right) {
            this.$toastr.e(error.response.data.errors.medial_view_weight_bearing_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.medial_view_weight_bearing_image_left);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Plantar View Weight Bearing data
    insertPlantarViewWeightBearing(formData) {
      this.showLoader();
      axiosPost(`${PLANTAR_VIEW_WEIGHT_BEARING}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          
          this.PlantarViewWeightBearing.imageWithLightsUrl = null;
          this.PlantarViewWeightBearing.imageWithoutLightsUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.plantar_view_weight_bearing_image_with_lights) {
            this.$toastr.e(error.response.data.errors.plantar_view_weight_bearing_image_with_lights);
          } else {
            this.$toastr.e(error.response.data.errors.plantar_view_weight_bearing_image_without_lights);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Plantar View Weight Bearing data
    updatePlantarViewWeightBearing(plantarViewWeightBearingDataId, formData) {
      this.showLoader();
      axiosPost(`${PLANTAR_VIEW_WEIGHT_BEARING}/${plantarViewWeightBearingDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.PlantarViewWeightBearing.imageWithLightsUrl = null;
          this.PlantarViewWeightBearing.imageWithoutLightsUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.plantar_view_weight_bearing_image_with_lights) {
            this.$toastr.e(error.response.data.errors.plantar_view_weight_bearing_image_with_lights);
          } else {
            this.$toastr.e(error.response.data.errors.plantar_view_weight_bearing_image_without_lights);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Plantar View Non Weight Bearing data
    insertPlantarViewNonWeightBearing(formData) {
      this.showLoader();
      axiosPost(`${PLANTAR_VIEW_NON_WEIGHT_BEARING}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.PlantarViewNonWeightBearing.imageRightUrl = null;
          this.PlantarViewNonWeightBearing.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.plantar_view_non_weight_bearing_image_right) {
            this.$toastr.e(error.response.data.errors.plantar_view_non_weight_bearing_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.plantar_view_non_weight_bearing_image_left);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Plantar View Non Weight Bearing data
    updatePlantarViewNonWeightBearing(plantarViewNonWeightBearingDataId, formData) {
      this.showLoader();
      axiosPost(`${PLANTAR_VIEW_NON_WEIGHT_BEARING}/${plantarViewNonWeightBearingDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.PlantarViewNonWeightBearing.imageRightUrl = null;
          this.PlantarViewNonWeightBearing.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.plantar_view_non_weight_bearing_image_right) {
            this.$toastr.e(error.response.data.errors.plantar_view_non_weight_bearing_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.plantar_view_non_weight_bearing_image_left);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Posterior View in Gait data
    insertPosteriorViewInGait(formData) {
      this.showLoader();
      axiosPost(`${POSTERIOR_VIEW_IN_GAIT}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.PosteriorViewinGait.imageRightUrl = null;
          this.PosteriorViewinGait.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.posterior_view_in_gait_image_right) {
            this.$toastr.e(error.response.data.errors.posterior_view_in_gait_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.posterior_view_in_gait_image_left);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Posterior View in Gait data
    updatePosteriorViewInGait(posteriorViewInGaitDataId, formData) {
      this.showLoader();
      axiosPost(`${POSTERIOR_VIEW_IN_GAIT}/${posteriorViewInGaitDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.PosteriorViewinGait.imageRightUrl = null;
          this.PosteriorViewinGait.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.posterior_view_in_gait_image_right) {
            this.$toastr.e(error.response.data.errors.posterior_view_in_gait_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.posterior_view_in_gait_image_left);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Anterior View in Gait data
    insertAnteriorViewInGait(formData) {
      this.showLoader();
      axiosPost(`${ANTERIOR_VIEW_IN_GAIT}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.AnteriorViewinGait.imageRightUrl = null;
          this.AnteriorViewinGait.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.anterior_view_in_gait_image_right) {
            this.$toastr.e(error.response.data.errors.anterior_view_in_gait_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.anterior_view_in_gait_image_left);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Anterior View in Gait data
    updateAnteriorViewInGait(anteriorViewInGaitDataId, formData) {
      this.showLoader();
      axiosPost(`${ANTERIOR_VIEW_IN_GAIT}/${anteriorViewInGaitDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.AnteriorViewinGait.imageRightUrl = null;
          this.AnteriorViewinGait.imageLeftUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.anterior_view_in_gait_image_right) {
            this.$toastr.e(error.response.data.errors.anterior_view_in_gait_image_right);
          } else {
            this.$toastr.e(error.response.data.errors.anterior_view_in_gait_image_left);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Posture data
    insertPosture(formData) {
      this.showLoader();
      axiosPost(`${POSTURE}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.Posture.frontImageUrl = null;
          this.Posture.sideImageUrl = null;
          this.Posture.topImageUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.posture_front_image) {
            this.$toastr.e(error.response.data.errors.posture_front_image);
          } else if (error.response.data.errors.posture_side_image) {
            this.$toastr.e(error.response.data.errors.posture_side_image);
          } else {
            this.$toastr.e(error.response.data.errors.posture_top_image);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Posture data
    updatePosture(postureDataId, formData) {
      this.showLoader();
      axiosPost(`${POSTURE}/${postureDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.Posture.frontImageUrl = null;
          this.Posture.sideImageUrl = null;
          this.Posture.topImageUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.posture_front_image) {
            this.$toastr.e(error.response.data.errors.posture_front_image);
          } else if (error.response.data.errors.posture_side_image) {
            this.$toastr.e(error.response.data.errors.posture_side_image);
          } else {
            this.$toastr.e(error.response.data.errors.posture_top_image);
          }
        });
      // Do something after successful data update, if needed
    },

    // Method to insert Correction evidence data
    insertImageWithCorrection(formData) {
      this.showLoader();
      axiosPost(`${IMAGE_WITH_CORRECTION}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.ImageWithcCorrection.imageOneUrl = null;
          this.ImageWithcCorrection.imageTwoUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.image1) {
            this.$toastr.e(error.response.data.errors.image1);
          } else {
            this.$toastr.e(error.response.data.errors.image2);
          }
        });
      // Do something after successful data insertion, if needed
    },

    // Method to update Correction evidence data
    updateImageWithCorrection(imageWithCorrectionDataId, formData) {
      this.showLoader();
      axiosPost(`${IMAGE_WITH_CORRECTION}/${imageWithCorrectionDataId}`, formData)
        .then(response => {
          this.showLoader(true);
          this.toastAndReload(response.data.message);
          this.fetchAllExaminationData(this.props[0]);
          this.ImageWithcCorrection.imageOneUrl = null;
          this.ImageWithcCorrection.imageTwoUrl = null;
        }).catch(error => {
          this.showLoader(true);
          // Check if the error response contains a message
          if (error.response.data.errors.image1) {
            this.$toastr.e(error.response.data.errors.image1);
          } else {
            this.$toastr.e(error.response.data.errors.image2);
          }
        });
      // Do something after successful data update, if needed
    },

    getDropdownOptions(fieldId) {
      return this.dropdownOptions[fieldId] || [];
    },
  },
};
</script>
<style lang="scss" scoped>
fieldset:disabled a.btn {
  pointer-events: all;
}
</style>