<template>
    <modal id="custom-prosthetics-modal" size="large" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('Custom Prosthetics Customization')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOM_PROSTHETICS_CUSTOMIZATION'
            @submit.prevent="submitData">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="row font-weight-bold">
                            <div class="col-md-2 mb-4">Materials</div>
                            <div class="col-md-8 mb-4">Selection</div>
                            <div class="col-md-2 mb-4">Quantity</div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 1">Material 1 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material1.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material1.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 2">Material 2 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material2.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material2.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 3">Material 3 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material3.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material3.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 4">Material 4 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material4.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material4.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 5">Material 5 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material5.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material5.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 6">Material 6 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material6.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material6.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 7">Material 7 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material7.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material7.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 8">Material 8 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material8.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material8.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 9">Material 9 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material9.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material9.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Material 10">Material 10 :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-11" v-model="formData.material10.id">
                                    <option value="">Select</option>
                                    <option v-for="option in dropdownOptionsList" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material10.qty" placeholder="Quantity" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-2 mb-3 d-flex align-items-center">
                                <label for="Mould type">Mould type :</label>
                            </div>
                            <div class="col-md-10 mb-3">
                                <select class="col-md-12" v-model="formData.mould_type">
                                    <option v-for="option in Mould" :key="option.id" :value="option.id">{{
                                        option.value }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="Modification" class="form-label">Modification Notes:</label>
                        <textarea rows="4" id="modification_notes" class="form-control"
                            v-model="formData.modification_notes" placeholder="Modification Notes"></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="Other Notes" class="form-label">Other Notes (500 char) :</label>
                        <textarea rows="4" id="other_notes" class="form-control" v-model="formData.other_notes"
                            placeholder="Other Notes"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import { CUSTOM_PROSTHETICS_CUSTOMIZATION } from "../../../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete, urlGenerator } from "../../../../../../common/Helper/AxiosHelper";
import store from "../../../../../../store/Index";

export default {
    name: 'CustomProstheticsCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        branchOrWarehouseId: {
            type: Number, // Adjust the type according to your data type
            required: true,
        },
        orderProductId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        orderId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
    },
    data() {
        return {
            CUSTOM_PROSTHETICS_CUSTOMIZATION,
            modification_notes: "",
            other_notes: "",
            formData: {
                id: '',
                mould_type: '3D scan',
                material1:{
                    id:"",
                    qty:"",
                },
                material2:{
                    id:"",
                    qty:"",
                },
                material3:{
                    id:"",
                    qty:"",
                },
                material4:{
                    id:"",
                    qty:"",
                },
                material5:{
                    id:"",
                    qty:"",
                },
                material6:{
                    id:"",
                    qty:"",
                },
                material7:{
                    id:"",
                    qty:"",
                },
                material8:{
                    id:"",
                    qty:"",
                },
                material9:{
                    id:"",
                    qty:"",
                },
                material10:{
                    id:"",
                    qty:"",
                },
            },
            dropdownOptionsList: [],
            Mould: [
                { id: '3D scan', value: '3D scan' },
                { id: 'POP cast', value: 'POP cast' },
            ],
        };
    },
    created() {
        this.dropdownOptions();
        this.formData.order_id = this.orderId;
        this.formData.order_product_id = this.orderProductId;
        this.fetchProstheticsData();
    },
    methods: {
        dropdownOptions() {
            axiosGet(`app/selectable/custom-prosthetics-products?branch_or_warehouse_id=${this.branchOrWarehouseId}`).then(response => {

                const data = response.data.data;

                for (const items of data) {
                    this.dropdownOptionsList.push({
                        id: items.variant.id,
                        value: items.variant.name,
                        quantity: items.available_qty,
                        stockId: items.id,
                        branchId: items.branch_or_warehouse_id,
                        variantId: items.variant_id,
                    });
                }

            }).catch(error => {
                console.error(error);
            });
        },
        fetchProstheticsData() {
            try {
                axiosGet(`${CUSTOM_PROSTHETICS_CUSTOMIZATION}/show?order_product_id=${this.orderProductId}`).then(response => {
                    let resp = response.data[0];
                    if(resp.id > 0) {
                        this.formData.id = resp.id;
                        this.formData.mould_type = resp.mould_type;
                        this.formData.modification_notes = resp.modification_notes;
                        this.formData.other_notes = resp.other_notes;
                        
                        let materialJson = JSON.parse(resp.material_json);
                        let materialKeys = Object.keys(materialJson);
                        if(materialKeys.length > 0) {
                            for (const keys of materialKeys) {
                                this.formData[keys] = materialJson[keys];
                            }
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            this.formData.orderid = this.orderId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#custom-prosthetics-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'custom-prosthetics-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>