<template>
    <modal id="custom-garments-modal" size="large" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('Custom Garments Customization')" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOM_GARMENTS_CUSTOMIZATION' @submit.prevent="submitData">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="row font-weight-bold">
                            <div class="col-md-4 mb-4">Materials</div>
                            <div class="col-md-4 mb-4">Selection</div>
                            <div class="col-md-4 mb-4">Quantity</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="Fabric 1">Fabric 1 :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.fabric1.id">
                                    <option v-for="option in fabricProducts" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.fabric1.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Fabric 2">Fabric 2 :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.fabric2.id">
                                    <option v-for="option in fabricProducts" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.fabric2.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Material 3">Material 3 :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.material3.id">
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material3.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Material 4">Material 4 :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.material4.id">
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material4.qty" placeholder="Quantity" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="Material 5">Material 5 :</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <select class="col-md-11" v-model="formData.material5.id">
                                    <option v-for="option in otherProducts" :key="option.id" :value="option.id">
                                        {{
                                            option.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <input type="number" id="quantity"
                                    class="form-control" v-model="formData.material5.qty" placeholder="Quantity" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="Mould type">Mould type :</label>
                            </div>
                            <div class="col-md-8 mb-3">
                                <select class="col-md-12" v-model="formData.mould_type">
                                    <option v-for="option in Mould" :key="option.id" :value="option.id">{{
                                        option.value }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="Measurements" class="form-label">Measurements:</label>
                        <textarea rows="4" id="measurements" class="form-control" v-model="formData.measurements_notes"
                            placeholder="Measurements"></textarea>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="Other Notes" class="form-label">Other Notes (500 char) :</label>
                        <textarea rows="4" id="other_notes" class="form-control" v-model="formData.other_notes"
                            placeholder="Other Notes"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import { CUSTOM_GARMENTS_CUSTOMIZATION } from "../../../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { axiosGet, axiosPost, axiosPatch, axiosDelete, urlGenerator } from "../../../../../../common/Helper/AxiosHelper";
import store from "../../../../../../store/Index";

export default {
    name: 'CustomGarmentsCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        branchOrWarehouseId: {
            type: Number, // Adjust the type according to your data type
            required: true,
        },
        orderProductId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
        orderId: {
            type: Number, // Adjust the type according to your data type
            required: false, // You can set this to true if it's required
        },
    },
    data() {
        return {
            CUSTOM_GARMENTS_CUSTOMIZATION,
            measurements_notes: "",
            other_notes: "",
            formData: {
                id: '',
                mould_type: '3D scan',
                fabric1:{
                    id:"",
                    qty:"",
                },
                fabric2:{
                    id:"",
                    qty:"",
                },
                material3:{
                    id:"",
                    qty:"",
                },
                material4:{
                    id:"",
                    qty:"",
                },
                material5:{
                    id:"",
                    qty:"",
                },
            },
            dropdownOptionsList: [],
            fabricProducts: [],
            otherProducts: [],
            Mould: [
                { id: '3D scan', value: '3D scan' },
                { id: 'POP cast', value: 'POP cast' },
            ],
        };
    },
    created() {
        this.dropdownOptions();
        this.formData.order_id = this.orderId;
        this.formData.order_product_id = this.orderProductId;
        this.fetchGarmentsData();
    },
    methods: {
        dropdownOptions() {
            axiosGet(`app/selectable/custom-garments-products?branch_or_warehouse_id=${this.branchOrWarehouseId}`).then(response => {

                const data = response.data.data;

                for (const items of data) {
                    const option = {
                        id: items.variant.id,
                        value: items.variant.name,
                        quantity: items.available_qty,
                        stockId: items.id,
                        branchId: items.branch_or_warehouse_id,
                        variantId: items.variant_id,
                    };

                    if (items.variant.product.sub_category_id === 19) {
                        // Product has subcategory 19 (Fabric)
                        this.fabricProducts.push(option);
                        this.formData.fabric1.id = this.fabricProducts[0].id;
                        this.formData.fabric2.id = this.fabricProducts[0].id;
                    } else if (items.variant.product.sub_category_id === 22) {
                        // Product has subcategory 22 (Other)
                        this.otherProducts.push(option);
                        this.formData.material3.id = this.otherProducts[0].id;
                        this.formData.material4.id = this.otherProducts[0].id;
                        this.formData.material5.id = this.otherProducts[0].id;
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchGarmentsData() {
            try {
                axiosGet(`${CUSTOM_GARMENTS_CUSTOMIZATION}/show?order_product_id=${this.orderProductId}`).then(response => {
                    let resp = response.data[0];
                    if(resp.id > 0) {
                        this.formData.id = resp.id;
                        this.formData.mould_type = resp.mould_type;
                        this.formData.measurements_notes = resp.measurements_notes;
                        this.formData.other_notes = resp.other_notes;
                        
                        let materialJson = JSON.parse(resp.material_json);
                        let materialKeys = Object.keys(materialJson);
                        if(materialKeys.length > 0) {
                            for (const keys of materialKeys) {
                                this.formData[keys] = materialJson[keys];
                            }
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        validateQuantity(quantity, availableQty) {
            return quantity <= availableQty;
        },
        handleQuantityInput(fieldName) {
            const enteredQuantity = this.formData[fieldName + '_quantity'];
            let selectedOption;

            if (fieldName === 'fabric1' || fieldName === 'fabric2') {
                selectedOption = this.fabricProducts.find(item => item.id === this.formData[fieldName + '_id']);
            } else {
                selectedOption = this.otherProducts.find(item => item.id === this.formData[fieldName + '_id']);
            }

            // Use the respective error message variable based on the field name
            const errorVariableName = 'exceedsQuantity' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

            if (!selectedOption) {
                // The selected option is null, which means the product is not available
                this[errorVariableName] = `No product available for ${fieldName.replace('_', ' ')}.`;
            } else if (!this.validateQuantity(enteredQuantity, selectedOption.quantity)) {
                this[errorVariableName] = `The entered quantity (${enteredQuantity}) exceeds the available quantity (${selectedOption.quantity}) for ${fieldName.replace('_', ' ')}.`;
            } else {
                this[errorVariableName] = null; // Reset the error message if the quantity is valid
            }

            // Update the stockId, branchId, and variantId in formData
            if (selectedOption) {
                this.formData[fieldName + '_stock_id'] = selectedOption.stockId;
                this.formData[fieldName + '_branch_id'] = selectedOption.branchId;
                this.formData[fieldName + '_variant_id'] = selectedOption.variantId;
            }
        },

        resetQuantity(fieldName) {
            // Set the quantity to 0 for the specified field
            this.formData[fieldName + '_quantity'] = 1;
            this.handleQuantityInput(fieldName);
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            // Include the selectedBranchId in the formData
            this.formData.branch_id = this.selectedBranchId;
            this.formData.orderid = this.orderId;
            // console.log(this.formData.branch_id);
            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.preloader = false;
            $('#custom-garments-modal').modal('hide');
            this.$emit('update-values');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'custom-garments-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}
</style>