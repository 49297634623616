<template>
    <section id="app-inventory-stock">
        <!-- top page section -->
        <!-- <app-page-top-section :title="$t('orders')" /> -->

        <app-table :id="table_id" v-if="options.url" :options="options" @action="triggerAction" />

        <app-invoice-modal v-if="isInvoiceModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />
        
        <app-custom-insoles-customization-modal v-if="isOrdersModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />

        <app-due-payment-modal v-if="isModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />

    </section>
</template>

<script>

import DatatableHelperMixin from "../../../../../../common/Mixin/Global/DatatableHelperMixin";
import SelectableStatusMixin from "../../../../../Helper/SelectableOptions/SelectableStatusMixin";
import invoiceList from "./ordersList";

export default {
    name: 'OrdersView',
    mixins: [DatatableHelperMixin, SelectableStatusMixin, invoiceList],
    data() {
        return {
            table_id: 'invoice-list-table',
            orderId: '',
            isModalActive: false,
            isInvoiceModalActive: false,
            isOrdersModalActive: false,
        };
    },
    methods: {
        triggerAction(row, action, active) {
            this.orderId = row.id;
            if (action.type === 'view') {
                this.isInvoiceModalActive = true;
            } else if (action.type === 'due_receive') {
                this.isModalActive = true;
            } else if (action.type === 'custom_insoles_customization') {
                this.isOrdersModalActive = true;
            }
        },
        closeModal() {
            $('#invoice-view-modal').modal('hide')
            $('#custom-insoles-customization-modal').modal('hide')
            this.isInvoiceModalActive = false;
            this.isOrdersModalActive = false;
            this.isModalActive = false
            this.$emit('modal-close');
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl = ''
        }
    }
};
</script>
